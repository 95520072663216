import React, { useState, useContext, useRef, useEffect } from 'react';
import { ERROR_TYPES, getVideoStatus, identity, pollForVideoReady, submitPost, uploadImage, uploadVideo } from "deso-protocol";
import { DeSoIdentityContext } from "react-deso-protocol";
import { deso_api } from '../utils/graphql';
import markdownToText from 'markdown-to-text';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import Turndown from 'turndown';
import { useUserPreferences } from '../contexts/UserPreferences';
import { localFees } from '../utils/transactions';
import { Avatar } from '../utils/layouts';
import { MediaAttachments, MediaLibrary, PostButtons, PreviewPost, addMediaToPost, handleButtonClick, handleChange, handleDeleteEmbed, handleDeleteImage, handleDeleteVideo, handleEmbedInputChange, handleFileChange, handleInsertEmbed, handleInsertImage, handleInsertVideo, handlePaste, handleUpload, processVideo, quillUploadHandler, removeMediaFromPost, toggleEmbedInput, toggleMediaLibrary } from '../utils/compose';

export const ReplyForm = ({ post, toggleReplyForm, refreshPostComments }) => {
    const { preferences } = useUserPreferences(); 
    const { currentUser, alternateUsers } = useContext(DeSoIdentityContext);
    const [quill, setQuill] = useState(null);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const containerRef = useRef(null);
    const textAreaRef = useRef(null); // Add ref for text area
    const [formData, setFormData] = useState({ body: '' });
    const userLocale = navigator.language || navigator.userLanguage;
    const [modalVisible, setModalVisible] = useState(false);
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);
    const [ImageURLs, setImageURLs] = useState([]); 
    const [VideoURLs, setVideoURLs] = useState([]);
    const [mediaInfo, setMediaInfo] = useState({});
    const [queuedFiles, setQueuedFiles] = useState([]);
    const [uploadingFiles, setUploadingFiles] = useState([]);
    const [imageUploading, setImageUploading] = useState(false);
    const [videoUploading, setVideoUploading] = useState(false);
    const [videoProcessing, setVideoProcessing] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const inputFileRef = useRef(null);
    const [embedUrl, setEmbedUrl] = useState('');
    const [showEmbedInput, setShowEmbedInput] = useState(false);
    const [showMediaLibrary, setShowMediaLibrary] = useState(false);

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.focus(); // Set focus to text area
        }
    }, []); // Empty dependency array to run only once on mount

    useEffect(() => {
        // Check if queuedFiles is not empty
        if (queuedFiles.length > 0) {
            // Process each file in the queue
            queuedFiles.forEach(async (queuedFile) => {
                if(queuedFile.status.processing===true || queuedFile.status.error!==null) { return; }
                try {
                    // Call handleUpload for each queued file
                    await handleUpload(queuedFile, setQueuedFiles, uploadImage, uploadVideo, processVideo, setImageURLs, currentUser, quill, setVideoURLs, mediaInfo, setMediaInfo);
                } catch (error) {
                    console.error('Error handling file upload:', error);
                }
            });
        }
    }, [queuedFiles]);

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = 'auto'; // Reset height to auto
            textAreaRef.current.style.height = `${Math.min(textAreaRef.current.scrollHeight+10, 200)}px`; // Adjust height
        }
      }, [formData.body]); 

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (!formData.body) { return; }

        const payload = {
            UpdaterPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
            BodyObj: {
                Body: formData.body,
                ImageURLs: ImageURLs,
                VideoURLs: VideoURLs,
            },
            ParentStakeID: post.PostHashHex,
            PostExtraData: {
                Node: preferences.nodeId.toString(),
                tool: 'Testing',
                Language: userLocale,
            },
            TransactionFees: [localFees()],
        };

        if (embedUrl) {
            payload.PostExtraData.EmbedVideoURL = embedUrl;
        }

        try {
            const resp = await submitPost(payload);
            if (resp) {
                setFormData({ body: '' });
                setSubmitSuccess(true);
                setIsSubmitting(false);
                setImageURLs([]);
                setVideoURLs([]);
                setEmbedUrl('');
                setSubmitError(false);
                toggleReplyForm();
                refreshPostComments();

                setTimeout(() => {
                    setSubmitSuccess(false);
                }, 5000);
            } else {
                setSubmitSuccess(false);
                setSubmitError(true);
            }
        } catch (error) {
            setSubmitError(true);
        }
    };

    const handleClose = () => {
        toggleReplyForm();
    };

    const handleOutsideClick = (e) => {
        if (e.target.id === `reply_${post.PostHashHex}`) {
            handleClose();
        }
    };

    useEffect(() => {
        const setContainerHeight = () => {
            if (containerRef.current) {
                const viewportHeight = window.innerHeight;
                let used = 100;
                if (window.innerWidth < 767) {
                    used += 55;
                }
                used += 55 + 10 + 42 + 70 + 70 + 70 + 150;
                containerRef.current.style.height = `${viewportHeight - used}px`;
            }
        };

        setContainerHeight();
        window.addEventListener('resize', setContainerHeight);

        return () => {
            window.removeEventListener('resize', setContainerHeight);
        };
    }, []);

    return (
        <>
            <form id={`reply_${post.PostHashHex}`} className='m-0 pe-0 py-2'>
                <div className="row my-0 py-0">
                    <div className="col-12 mb-1 text-muted d-flex flex-column">
                        <div>
                            <span className='text-muted'>Replying to&nbsp;</span>
                            <Avatar type="avatar" publicKey={post.PosterPublicKeyBase58Check} />
                            <Avatar publicKey={post.PosterPublicKeyBase58Check} />
                        </div>
                        {alternateUsers && alternateUsers.length > 0 && (
                            <div className='fw-light d-flex flex-row flex-nowrap'>
                                <span className='me-2'>as</span>
                                <a className="nav-link" role="button" data-bs-toggle="dropdown">
                                    <Avatar type='avatar' publicKey={currentUser.PublicKeyBase58Check} link={false} />
                                    <Avatar publicKey={currentUser.PublicKeyBase58Check} link={false} />
                                    <i className="bi bi-caret-down-fill"></i>
                                </a>
                                <ul className="dropdown-menu">
                                    {alternateUsers.map((user, index) => (
                                        <li className='ps-2' key={index} onClick={() => identity.setActiveUser(user.PublicKeyBase58Check)}>
                                            <Avatar type='avatar' publicKey={user.PublicKeyBase58Check} link={false} />
                                            <Avatar publicKey={user.PublicKeyBase58Check} link={false} />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className="col-12 m-0 px-2" style={{ marginBottom: "-0.9em !important;" }}>
                        <textarea
                            name='body'
                            className='w-100 card-effect my-0 inputBody p-2'
                            value={formData.body}
                            onChange={(event) => setFormData({ ...formData, body: event.target.value })}
                            ref={textAreaRef}
                        />
                    </div>
                    <MediaAttachments
                        ImageURLs={ImageURLs}
                        VideoURLs={VideoURLs}
                        quill={quill}
                        handleInsertImage={handleInsertImage}
                        handleDeleteImage={handleDeleteImage}
                        handleInsertVideo={handleInsertVideo}
                        handleDeleteVideo={handleDeleteVideo}
                        queuedFiles={queuedFiles}
                        showEmbedInput={showEmbedInput}
                        embedUrl={embedUrl}
                        setEmbedUrl={setEmbedUrl}
                        handleInsertEmbed={handleInsertEmbed}
                        handleDeleteEmbed={handleDeleteEmbed}
                        handleEmbedInputChange={(event) => handleEmbedInputChange(event, setEmbedUrl)}
                        toggleMediaLibrary={() => setShowMediaLibrary(!showMediaLibrary)}
                        handleButtonClick={handleButtonClick}
                        inputFileRef={inputFileRef}
                        handleFileChange={(event) => handleFileChange(event, setQueuedFiles, ImageURLs, setImageURLs, VideoURLs, setVideoURLs)}
                        submitError={submitError}
                        submitSuccess={submitSuccess}
                        isSubmitting={isSubmitting}
                        handleSubmit={handleSubmit}
                        setImageURLs={setImageURLs}
                        setVideoURLs={setVideoURLs}
                        setQueuedFiles={setQueuedFiles}
                        mode="plaintext"
                    />
                    <div className='row m-0 px-2 mb-3'>
                        <PostButtons
                            toggleMediaLibrary={() => setShowMediaLibrary(!showMediaLibrary)}
                            handleButtonClick={handleButtonClick}
                            inputFileRef={inputFileRef}
                            handleFileChange={(event) => handleFileChange(event, setQueuedFiles, ImageURLs, setImageURLs, VideoURLs, setVideoURLs)}
                            toggleEmbedInput={() => setShowEmbedInput(!showEmbedInput)}
                            submitError={submitError}
                            submitSuccess={submitSuccess}
                            isSubmitting={isSubmitting}
                            handleSubmit={handleSubmit}
                        />
                    </div>
                </div>
            </form>
            {showMediaLibrary && (
                <MediaLibrary
                    currentUser={currentUser}
                    preferences={preferences}
                    toggleMediaLibrary={() => setShowMediaLibrary(!showMediaLibrary)}
                    addMediaToPost={(url, type) => { // Add your handlers here
                        if (type === 'image') {
                            setImageURLs([...ImageURLs, url]);
                        } else if (type === 'video') {
                            setVideoURLs([...VideoURLs, url]);
                        }
                    }}
                    removeMediaFromPost={(url, type) => { // Add your handlers here
                        if (type === 'image') {
                            setImageURLs(ImageURLs.filter(image => image !== url));
                        } else if (type === 'video') {
                            setVideoURLs(VideoURLs.filter(video => video !== url));
                        }
                    }}
                    VideoURLs={VideoURLs}
                    ImageURLs={ImageURLs}
                />        
            )}
        </>
    );
};
