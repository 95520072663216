import React from 'react';
import { Link } from 'react-router-dom';

/*******************************************************
 * Helper Functions
export const nodes = [
  { Active: true, 'Node': 2, 'Name': 'BitClout', 'URL': 'https://bitclout.com', 'Profile': 'Bitclout', 'Owner': 'diamondhands', 'PublicKeyBase58Check': 'BC1YLhZFE1pB6pVhdhhw4jhb9MyygRjNbXT8QPmoQ6ZRgJN7X5jgRWZ' },
  { Active: true, 'Node': 1, 'Name': 'deso', 'URL': 'https://node.deso.org', 'Profile': 'deso', 'Owner': 'diamondhands', 'PublicKeyBase58Check': 'BC1YLgk64us61PUyJ7iTEkV4y2GqpHSi8ejWJRnZwsX6XRTZSfUKsop' },
  { Active: true, 'Node': 3, 'Name': 'DiamondApp', 'URL': 'https://diamondapp.com', 'Profile': 'diamond', 'Owner': 'Zordon', 'PublicKeyBase58Check': 'BC1YLgTKfwSeHuNWtuqQmwduJM2QZ7ZQ9C7HFuLpyXuunUN7zTEr5WL' },
  { Active: true, 'Node': 11, 'Name': 'DeSocialWorld', 'URL': 'https://desocialworld.com', 'Profile': 'DeSocialWorld', 'Owner': 'edokoevoet', 'PublicKeyBase58Check': 'BC1YLjSGY3DETtVTsiDVkobtvfDDtMuTjFoG1rmSagtWPzHyEZ3BKuB' },

  { 'Name': 'NFTz', 'URL': 'https://nodeapi.nftz.me', 'Profile': 'NFTz', 'Owner': 'mvanhalen', 'PublicKeyBase58Check': 'BC1YLhjjhom1dQXdW52ZoXUxTZQJrLaUH4mRfJBkNTiJYCMu7oCZC4d' },
  { 'Name': 'DeSoLabs', 'URL': 'https://node.desolabs.org', 'Profile': 'DeSoLabs', 'Owner': 'kanshi', 'PublicKeyBase58Check': 'BC1YLjBvzHjemzgY4va55AzZ7VhRBLDmjxsfxRHQ9PybPARMQvtDH5N' },
];
*/
export const nodes = [
  { Active: true, 'Node': 1, 'Name': 'DeSo', 'URL': 'https://node.deso.org', 'Profile': 'deso', 'Owner': 'nader', 'PublicKeyBase58Check': 'BC1YLgk64us61PUyJ7iTEkV4y2GqpHSi8ejWJRnZwsX6XRTZSfUKsop' },
  { Active: true, 'Node': 2, 'Name': 'BitClout', 'URL': 'https://bitclout.com', 'Profile': 'Bitclout', 'Owner': 'diamondhands', 'PublicKeyBase58Check': 'BC1YLhZFE1pB6pVhdhhw4jhb9MyygRjNbXT8QPmoQ6ZRgJN7X5jgRWZ' },
  { Active: true, 'Node': 3, 'Name': 'Diamond', 'URL': 'https://diamondapp.com', 'Profile': 'diamond', 'Owner': 'Zordon', 'PublicKeyBase58Check': 'BC1YLgTKfwSeHuNWtuqQmwduJM2QZ7ZQ9C7HFuLpyXuunUN7zTEr5WL' },
  { Active: null, 'Node': 4, 'Name': 'Desofy', 'URL': 'https://desofy.app', 'Profile': 'desofy', 'Owner': 'Ribal', 'PublicKeyBase58Check': 'BC1YLh5pKXs8NqaUtN8Gzi3rfoAgG2VWio2NER7baDkG8T2x7wRnSwa' },
  { Active: null, 'Node': 5, 'Name': 'Flick', 'URL': 'https://flickapp.com', 'Profile': null, 'Owner': 'nigeleccles', 'PublicKeyBase58Check': 'BC1YLhMhapdhZQPytVRPDGTDtzcW79AfZXK1sqYXX1JdP6Y2tyBdXqu' },
  { Active: null, 'Node': 6, 'Name': "tijn's club", 'URL': 'https://tijn.club', 'Profile': null, 'Owner': 'tijn', 'PublicKeyBase58Check': 'BC1YLgLQ2igM9uMAWoQQVkwaQogaNRdm5114tuJHYCXoghnmiaY7vmN' },
  { Active: null, 'Node': 7, 'Name': 'Nacho Average', 'URL': 'https://nachoaverage.com/', 'Profile': null, 'Owner': 'ClayPerryMusic', 'PublicKeyBase58Check': 'BC1YLhebN4AuM9yycvDyMcDmuRwKuyZUiigMrGv7zVosUK2mcwjw2NJ' },
  { Active: null, 'Node': 8, 'Name': 'DeSoLabs', 'URL': 'https://node.desolabs.org', 'Profile': 'DeSoLabs', 'Owner': 'kanshi', 'PublicKeyBase58Check': 'BC1YLjBvzHjemzgY4va55AzZ7VhRBLDmjxsfxRHQ9PybPARMQvtDH5N' },
  { Active: null, 'Node': 9, 'Name': 'Supernovas', 'URL': 'https://supernovas.app', 'Profile': null, 'Owner': 'fransarthur', 'PublicKeyBase58Check': 'BC1YLi2Xrz9CAxwUuJuvvptNRkEXycCLMyePmPmhF33Q5t7Gtn1TgBm' },
  { Active: null, 'Node': 10, 'Name': 'GiftClout', 'URL': 'https://members.giftclout.com', 'Profile': null, 'Owner': 'RajLahoti', 'PublicKeyBase58Check': 'BC1YLgcp38dQfgRvPvZkbpZKxCtNYgm89V6xNDqo5QnucWQSPM8nNXy' },
  { Active: true, 'Node': 11, 'Name': 'DeSocialWorld', 'URL': 'https://desocialworld.com', 'Profile': 'DeSocialWorld', 'Owner': 'edokoevoet', 'PublicKeyBase58Check': 'BC1YLjSGY3DETtVTsiDVkobtvfDDtMuTjFoG1rmSagtWPzHyEZ3BKuB' },
  { Active: true, 'Node': 12, 'Name': 'NFTz', 'URL': 'https://nodeapi.nftz.me', 'Profile': 'NFTz', 'Owner': 'mvanhalen', 'PublicKeyBase58Check': 'BC1YLhjjhom1dQXdW52ZoXUxTZQJrLaUH4mRfJBkNTiJYCMu7oCZC4d' },
  { Active: null, 'Node': 13, 'Name': 'Cloutible', 'URL': 'https://cloutible.club', 'Profile': null, 'Owner': 'DawaynePerza', 'PublicKeyBase58Check': 'BC1YLfkULpmqpbUtu5CCDfkGttP1dZFmTt23udEvBZ8bei5Sakk89cj' },
  { Active: null, 'Node': 14, 'Name': 'Agbegbe', 'URL': 'https://agbegbe.org', 'Profile': null, 'Owner': 'TheParkerazzi', 'PublicKeyBase58Check': 'BC1YLhYx7GbSdoETTV3zhf7wkJsUUXZo6voQpQEZkYHKuovnENzDXRh' },
  { Active: null, 'Node': 15, 'Name': 'CloutingAround', 'URL': 'https://cloutingaround.dev', 'Profile': null, 'Owner': 'TheParkerazzi', 'PublicKeyBase58Check': 'BC1YLhmn94rDKdke9mFtJqJd1ZaV9hC1pUghEQCfChPxk9GzjA9Qxm8' },
  { Active: null, 'Node': 16, 'Name': 'MediaTech', 'URL': 'https://deso.mediatech.ventures', 'Profile': null, 'Owner': 'paulobrien', 'PublicKeyBase58Check': 'BC1YLhvkWv4x2fVKYKDSENVDYh9CCDBCgV51a8SJekYX3yZVqcDcpwj' },
  { Active: null, 'Node': 17, 'Name': 'Mousai', 'URL': 'https://deso.mousai.stream', 'Profile': null, 'Owner': 'marlonjm2k', 'PublicKeyBase58Check': 'BC1YLgTyYM1fFZZigu58Befs9MhnWugUBJ8VYXwYhNdPQLZvzETivM6' },
  { Active: null, 'Node': 18, 'Name': 'KoalaTBooks', 'URL': 'https://koalatbooks.com', 'Profile': 'KoalaTBooks', 'Owner': 'chriscelaya', 'PublicKeyBase58Check': 'BC1YLj7GDD7ANS2F2fiwUuUFbaGDvd8LDDMx6n1jxrfVZmHf5T3H3yb' },
  { Active: null, 'Node': 19, 'Name': 'Beyond', 'URL': 'https://beyond.restartu.org', 'Profile': 'BeyondApp', 'Owner': 'RestartU', 'PublicKeyBase58Check': 'BC1YLjXyjGGxqURJEjjz727RKqFuzDrQLYi5enGtWXLn3GQWGc8ra7c' },
  { Active: null, 'Node': 20, 'Name': 'DeverSo', 'URL': 'https://deverso.io/', 'Profile': 'DeverSo', 'Owner': 'Nordian', 'PublicKeyBase58Check': 'BC1YLhzWG65PaGKy5csJT5m9zSviJqEkMWNeSMf3J5tAVA8tWto6CCg' },
  { Active: null, 'Node': 21, 'Name': 'VoSocial', 'URL': 'https://vosoapp.com', 'Profile': null, 'Owner': 'voso', 'PublicKeyBase58Check': 'BC1YLhU5jX8jCsoFXw4416zSmjyiGSxxbiEJmwmcaeKMKirSj4jveYZ' },
  { Active: null, 'Node': 22, 'Name': 'Tunel', 'URL': 'https://tunel.app', 'Profile': 'tunel', 'Owner': 'hazrodriguez', 'PublicKeyBase58Check': 'BC1YLgLduMMHVefNjtpvZjTpX9QxSxW8wn6WcfHwyziGxsHuPWBBXc3' },
  { Active: true, 'Node': 23, 'Name': 'Entre', 'URL': 'https://joinentre.com', 'Profile': 'entre', 'Owner': 'entre', 'PublicKeyBase58Check': 'BC1YLgy34m4qrufdBTBjXbdUqVPSdL6pCeoXHU3qquaUahsGPvAmzw5' },
  { Active: null, 'Node': 24, 'Name': 'Elmas', 'URL': 'https://elmas.app', 'Profile': 'elmas', 'Owner': 'elmas', 'PublicKeyBase58Check': 'BC1YLjVfcEmFtVb9KwZU9nzG1v4vGTufsMzR4YNPUxDCEoWpcLRNuwx' },
  { Active: null, 'Node': 25, 'Name': 'İnci', 'URL': 'https://inci.app', 'Profile': 'inci', 'Owner': 'inci', 'PublicKeyBase58Check': 'BC1YLgVZs84qRgbds4DWdpbhWUACFarp1QCCjo5y1AcXAcpv5p8w4U6' },
  { Active: null, 'Node': 26, 'Name': 'Overclout', 'URL': 'https://overclout.com', 'Profile': 'Overclout', 'Owner': 'Overclout', 'PublicKeyBase58Check': 'BC1YLhyHhMeL8bnHHxrbFjp6wvYWADu5mtzXZ1fbdpMKcHWUEd4s4pg' },
  { Active: true, 'Node': 27, 'Name': 'DesoNoCode', 'URL': 'https://node.desonocode.com', 'Profile': 'BC1YLgVazVu1yqb9ZdtyMezJ42ugZQzy9MCJUUtEuvKthMLqop3YGxp', 'Owner': 'DeSoNoCode', 'PublicKeyBase58Check': 'BC1YLgVazVu1yqb9ZdtyMezJ42ugZQzy9MCJUUtEuvKthMLqop3YGxp' },
  { Active: true, 'Node': 28, 'Name': 'Post2EarnDao', 'URL': 'https://node.post2earndao.com', 'Profile': 'Post2EarnDAO', 'Owner': 'edokoevoet', 'PublicKeyBase58Check': 'BC1YLihgD73DorR8WcV4HCTxpUePaL8ABnF43KwE3H5SWvK7bTuLCuL' },
  { Active: null, 'Node': 29, 'Name': 'Pearl', 'URL': 'https://pearl.app', 'Profile': 'pearl', 'Owner': 'pearl', 'PublicKeyBase58Check': 'BC1YLjFYcyrfzZBxaQAAtuKnHTE9t8ozbX6VqvN3Ryza8z2cnUAPR7J' },
  { Active: true, 'Node': 30, 'Name': 'CooperativaLocal', 'URL': 'https://node.cooperativalocal.org', 'Profile': 'CooperativaLocal', 'Owner': 'CooperativaLocal', 'PublicKeyBase58Check': 'BC1YLfr8yKePcRzppyseC5HhTQVjkCnahDqQg1fjAXaLcCuAA9nViHh' },
  { Active: true, 'Node': 31, 'Name': 'SafetyNet', 'URL': 'https://safetynet.social', 'Profile': 'SafetyNet', 'Owner': 'SafetyNet', 'PublicKeyBase58Check': 'BC1YLh99esxXJi1DnVKd7GUMnm9GXCzeLTLq4zu6g5JEvWgDLQpoS9v' },
];

/*
export const nodes = {
  "1": {
      "Name": "DeSo",
      "URL": "https://node.deso.org",
      "Owner": "diamondhands"
  },
  "2": {
      "Name": "BitClout",
      "URL": "https://bitclout.com",
      "Owner": "diamondhands"
  },
  "3": {
      "Name": "Diamond",
      "URL": "https://diamondapp.com",
      "Owner": "Zordon"
  },
  "4": {
      "Name": "Desofy",
      "URL": "https://desofy.app",
      "Owner": "Ribal"
  },
  "5": {
      "Name": "Flick",
      "URL": "https://flickapp.com",
      "Owner": "nigeleccles"
  },
  "6": {
      "Name": "tijn's club",
      "URL": "https://tijn.club",
      "Owner": "tijn"
  },
  "7": {
      "Name": "Nacho Average",
      "URL": "https://nachoaverage.com/",
      "Owner": "ClayPerryMusic"
  },
  "8": {
      "Name": "love4src",
      "URL": "https://love4src.com",
      "Owner": "kanshi"
  },
  "9": {
      "Name": "Supernovas",
      "URL": "https://supernovas.app",
      "Owner": "fransarthur"
  },
  "10": {
      "Name": "GiftClout",
      "URL": "https://members.giftclout.com",
      "Owner": "RajLahoti"
  },
  "11": {
      "Name": "DeSocialWorld",
      "URL": "https://desocialworld.com",
      "Owner": "edokoevoet"
  },
  "12": {
      "Name": "NFTz",
      "URL": "https://nftz.me",
      "Owner": "mvanhalen"
  },
  "13": {
      "Name": "Cloutible",
      "URL": "https://cloutible.club",
      "Owner": "DawaynePerza"
  },
  "14": {
      "Name": "Agbegbe",
      "URL": "https://agbegbe.org",
      "Owner": "TheParkerazzi"
  },
  "15": {
      "Name": "CloutingAround",
      "URL": "https://cloutingaround.dev",
      "Owner": "TheParkerazzi"
  },
  "16": {
      "Name": "MediaTech",
      "URL": "https://deso.mediatech.ventures",
      "Owner": "paulobrien"
  },
  "17": {
      "Name": "Mousai",
      "URL": "https://deso.mousai.stream",
      "Owner": "marlonjm2k"
  },
  "18": {
      "Name": "KoalaTBooks",
      "URL": "https://koalatbooks.com",
      "Owner": "chriscelaya"
  },
  "19": {
      "Name": "Beyond",
      "URL": "https://beyond.restartu.org",
      "Owner": "RestartU"
  },
  "20": {
      "Name": "DeverSo",
      "URL": "https://deverso.io/",
      "Owner": "Nordian"
  },
  "21": {
      "Name": "VoSocial",
      "URL": "https://vosoapp.com",
      "Owner": "voso"
  },
  "22": {
      "Name": "Tunel",
      "URL": "https://tunel.app",
      "Owner": "hazrodriguez"
  },
  "23": {
      "Name": "Entre",
      "URL": "https://joinentre.com",
      "Owner": "entre"
  },
  "24": {
      "Name": "Elmas",
      "URL": "https://elmas.app",
      "Owner": "elmas"
  },
  "25": {
      "Name": "İnci",
      "URL": "https://inci.app",
      "Owner": "inci"
  },
  "26": {
      "Name": "Overclout",
      "URL": "https://overclout.com",
      "Owner": "Overclout"
  },
  "27": {
      "Name": "DesoNoCode",
      "URL": "https://node.desonocode.com",
      "Owner": "DeSoNoCode"
  },
  "28": {
      "Name": "Post2EarnDao",
      "URL": "https://node.post2earndao.com",
      "Owner": "edokoevoet"
  },
  "29": {
      "Name": "Pearl",
      "URL": "https://pearl.app",
      "Owner": "pearl"
  }
};
*/
export const defaultNodeUrl = "https://node.deso.org"; 

export const errorMessages = {
    "SendDiamonds: Problem creating transaction: Blockchain.CreateBasicTransferTxnWithDiamonds: Problem getting deso nanos: : RuleErrorCreatorCoinTransferPostAlreadyHasSufficientDiamonds": {
        "title": "Error sending diamonds",
        "description": "You have already awarded this post with the specified diamond level.",
    }
}

export const defaultDesoProtocolConfig = {
  spendingLimitOptions: {
    GlobalDESOLimit: 10000000, // 0.01 DESO
    TransactionCountLimitMap: {
      SUBMIT_POST: "UNLIMITED",
    },
  },
  appName: 'SG_Testing',
};

// Transaction Types
export const transactionTypes = [
  { "TYPE": '', "ID": 0, "ICON": "question-circle", "COLOR": { "r": 0, "g": 0, "b": 0 }, "GROUP": "Other", "NAME": "#0", "DESCRIPTION": "", "MIN_TXS": "" },
  { "TYPE": 'BLOCK_REWARD', "ID": 1, "ICON": "question-circle", "COLOR": { "r": 165, "g": 110, "b": 255 }, "GROUP": "Other", "NAME": "#1", "DESCRIPTION": "", "MIN_TXS": "" },
  { "TYPE": 'BASIC_TRANSFER', "ID": 2, "ICON": "cash-stack", "COLOR": { "r": 17, "g": 146, "b": 232 }, "GROUP": "Financial", "NAME": "Transfer", "DESCRIPTION": "", "MIN_TXS": 2 },
  { "TYPE": '', "ID": 3, "ICON": "credit-card", "COLOR": { "r": 167, "g": 20, "b": 20 }, "GROUP": "Other", "NAME": "3", "DESCRIPTION": "", "MIN_TXS": "" },
  { "TYPE": 'PRIVATE_MESSAGE', "ID": 4, "ICON": "envelope-fill", "COLOR": { "r": 87, "g": 4, "b": 8 }, "GROUP": "Messaging", "NAME": "Private Message", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": 'SUBMIT_POST', "ID": 5, "ICON": "chat-square-text-fill", "COLOR": { /*"r": 105, "g": 41, "b": 196*/ }, "GROUP": "Content", "NAME": "Post", "DESCRIPTION": "", "MIN_TXS": 0 },
  { "TYPE": 'UPDATE_PROFILE', "ID": 6, "ICON": "file-earmark-person", "COLOR": { "r": 238, "g": 83, "b": 139 }, "GROUP": "Manage Account", "NAME": "Profile update", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": '', "ID": 7, "ICON": "currency-exchange", "COLOR": { /* Color values */ }, "GROUP": "Other", "NAME": "Update exchange rates (8)", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": '', "ID": 8, "ICON": "coin", "COLOR": { "r": 178, "g": 134, "b": 0 }, "GROUP": "Financial", "NAME": "Coin transaction", "DESCRIPTION": "", "MIN_TXS": 1 },  
  { "TYPE": 'FOLLOW', "ID": 9, "ICON": "person-fill-add", "COLOR": { "r": 25, "g": 128, "b": 56 }, "GROUP": "Engagement", "NAME": "Follow", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": 'LIKE', "ID": 10, "ICON": "heart-fill", "COLOR": { "r": 159, "g": 24, "b": 83 }, "GROUP": "Engagement", "NAME": "Like", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": 'CREATOR_COIN', "ID": 11, "ICON": "coin", "COLOR": {"r": 255, "g": 20, "b": 45 }, "GROUP": "Financial", "NAME": "Creator Coin", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": '', "ID": 12, "ICON": "arrow-down-up", "COLOR": { "r": 110, "g": 240, "b": 70 }, "GROUP": "Other", "NAME": "Swap identity (12)", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": 'CREATOR_COIN', "ID": 13, "ICON": "coin", "COLOR": { "r": 138, "g": 56, "b": 0 }, "GROUP": "Financial", "NAME": "Creator Coin", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": 'CREATOR_COIN_TRANSFER', "ID": 14, "ICON": "coin", "COLOR": { "r": 0, "g": 93, "b": 93 }, "GROUP": "Financial", "NAME": "CC Transfer", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": 'CREATE_NFT', "ID": 15, "ICON": "pallet-fill", "COLOR": { "r": 0, "g": 45, "b": 156 }, "GROUP": "NFT", "NAME": "Create NFT", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": 'UPDATE_NFT', "ID": 16, "ICON": "pallet", "COLOR": { "r": 1, "g": 39, "b": 73 }, "GROUP": "NFT", "NAME": "Update NFT", "DESCRIPTION": "", "MIN_TXS": 3 },
  { "TYPE": 'ACCEPT_NFT_BID', "ID": 17, "ICON": "cart-check-fill", "COLOR": { "r": 0, "g": 157, "b": 154 }, "GROUP": "NFT", "NAME": "Accept NFT Bid", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": 'NFT_BID', "ID": 18, "ICON": "cart-plus-fill", "COLOR": { "r": 250, "g": 77, "b": 86 }, "GROUP": "NFT", "NAME": "NFT Bid", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": 'NFT_TRANSFER', "ID": 19, "ICON": "cart-fill", "COLOR": { "r": 30, "g": 30, "b": 30 }, "GROUP": "NFT", "NAME": "NFT Transfer", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": 'ACCEPT_NFT_TRANSFER', "ID": 20, "ICON": "cart-check-fill", "COLOR": { "r": 60, "g": 100, "b": 100 }, "GROUP": "NFT", "NAME": "Accept NFT Transfer", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": 'BURN_NFT', "ID": 21, "ICON": "cart-x-fill", "COLOR": { /* Color values */ }, "GROUP": "NFT", "NAME": "Burn NFT", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": 'AUTHORIZE_DERIVED_KEY', "ID": 22, "ICON": "fingerprint", "COLOR": { /* Color values */ }, "GROUP": "Manage Account", "NAME": "Authorise Derived Key", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": 'MESSAGING_GROUP', "ID": 23, "ICON": "envelope-fill", "COLOR": { /* Color values */ }, "GROUP": "Messaging", "NAME": "Group Message", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": 'DAO_COIN', "ID": 24, "ICON": "question-circle", "COLOR": { /* Color values */ }, "GROUP": "DAO", "NAME": "DAO Coin", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": 'DAO_COIN_TRANSFER', "ID": 25, "ICON": "question-circle", "COLOR": { /* Color values */ }, "GROUP": "DAO", "NAME": "DAO Coin Transfer", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": 'DAO_COIN_LIMIT_ORDER', "ID": 26, "ICON": "question-circle", "COLOR": { /* Color values */ }, "GROUP": "DAO", "NAME": "DAO Coin Limit Order", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": 'CREATE_USER_ASSOCIATION', "ID": 27, "ICON": "tag-fill", "COLOR": { /* Color values */ }, "GROUP": "User Association", "NAME": "Create User Association", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": 'DELETE_USER_ASSOCIATION', "ID": 28, "ICON": "tag", "COLOR": { /* Color values */ }, "GROUP": "User Association", "NAME": "Delete User Association", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": 'CREATE_POST_ASSOCIATION', "ID": 29, "ICON": "tag-fill", "COLOR": { /* Color values */ }, "GROUP": "Post Association", "NAME": "Post Association", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": 'DELETE_POST_ASSOCIATION', "ID": 30, "ICON": "tag", "COLOR": { /* Color values */ }, "GROUP": "Post Association", "NAME": "Delete Post Association", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": 'ACCESS_GROUP', "ID": 31, "ICON": "key-fill", "COLOR": { /* Color values */ }, "GROUP": "Manage Account", "NAME": "Access Group", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": 'ACCESS_GROUP_MEMBERS', "ID": 32, "ICON": "key-fill", "COLOR": { /* Color values */ }, "GROUP": "Manage Account", "NAME": "Accesss Group Members", "DESCRIPTION": "", "MIN_TXS": 1 },
  { "TYPE": 'NEW_MESSAGE', "ID": 33, "ICON": "envelope-fill", "COLOR": { /* Color values */ }, "GROUP": "Messaging", "NAME": "Direct Message", "DESCRIPTION": "", "MIN_TXS": 1 }
];

export const notificationIcons = {
  "Tagged": "at",
  "Transfer": "coin",
  "NFT Transfer": "question-circle",
  "Creator Coin": "coin",
  "CC Transfer": "coin",
  "Diamond": "gem",
  "Diamond Shower": "gem",
  "Like": "heart-fill",
  "Reaction": "heartbreak-fill",
  "Reposted": "repeat",
  "Token Transfer": "question-circle",
  "Direct Message": "envelope-fill",
  "Follow": "person-plus-fill",
  "Delete Post Association": "bookmark-dash-fill",
};

export const reactions = {
    "ANGRY": { emoji: "😡" },
    "LIKE": { emoji: "💖" },
    "LOVE": { emoji: "💖" },
}

export const getReactionEmoji = (reactionValue) => {
    // Convert the reaction value to uppercase for case-insensitive matching
    const reaction = reactionValue.toUpperCase();
    // Check if the reaction exists in the reactions object
    if (reactions.hasOwnProperty(reaction)) {
        // Return the emoji corresponding to the reaction
        return reactions[reaction].emoji;
    } else {
        // If the reaction does not exist, return a default emoji or handle the case as needed
        return "❓"; // Default emoji for unknown reactions
    }
};

export function friendlyFormatDate(dateString) {
    const date = new Date(dateString);
    const oneMinute = 60 * 1000; // in ms
    const oneHour = oneMinute * 60;
    const oneDay = oneHour * 24;
    const oneWeek = oneDay * 7;
    const now = new Date();
    if (isNaN(date.getTime())) {
        return "Invalid Date";
    }
    
    const elapsed = Math.abs(now.getTime() - date.getTime());
    
    if ((now.getTime() - date.getTime()) < 0) {
        return "In the future";
    }
  
    if (elapsed < oneMinute) {
      return 'Just now';
    } else if (elapsed < oneHour) {
      const minutes = Math.floor(elapsed / oneMinute);
      return `${minutes} min${minutes > 1 ? 's' : ''} ago`;
    } else if (elapsed < oneDay) {
      if (date.toDateString() === now.toDateString()) {
        const hours = Math.floor(elapsed / oneHour);
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
      } else {
        return 'Yesterday';
      }
    } else if (elapsed < oneWeek) {
      const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const day = dayNames[date.getDay()];
      return day;
    } else {
      const options = { year: '2-digit', month: 'short', day: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    }
  }
  
  export function parseLinks(string) {
    if (!string) {
        return ''; // Return an empty string if input is falsy
    }
    const domain = window.location.origin;
    
    // Step 1: Split the string by new line characters
    const lineGroups = string.split('\n');
    
    // Step 2-4: Process each line group individually
    const parsedString = lineGroups.map((lineGroup, lineGroupIndex) => {
        const splitRegex = /(\b(?:https?:\/\/[^\s]+)|(?:[@$&][\w]+[^\s]*)|\[{[BR]}])/g; // Updated regex pattern
        const specificUrlRegex = /(https?:\/\/)([^\/]+)\/(u|posts|nft)\/([a-f0-9]{64})/;

        // Split the line group into parts based on the split regex and process each part
        let parsedParts = lineGroup.split(splitRegex).map((part, index) => {
            if (index % 2 === 0) {
                return part; // Return regular text parts as is
            } else if (part.startsWith('@') || part.startsWith('$') || part.startsWith('&')) { // Check for $mention and &mention as well
                const username = part.slice(1); // Remove mention symbol
                return <Link key={`${lineGroupIndex}-${index}`} to={`/u/${username}`}>{part}</Link>; // Return the username string
            } else if (part.startsWith('https://') || part.startsWith('http://')) {
                const match = part.match(specificUrlRegex);
                if (match) {
                    const [, protocol, urlDomain, type, identifier] = match;
                    switch (type) {
                        case 'u':
                            return <Link key={`${lineGroupIndex}-${index}`} to={`${domain}/u/${identifier}`}>{domain}/u/{identifier}</Link>; // Return the URL as a link
                        case 'posts':
                        case 'nft':
                            return <Link key={`${lineGroupIndex}-${index}`} to={`${domain}/${type}/${identifier}`}>{domain}/{type}/{identifier}</Link>; // Return the URL as a link
                        default:
                            return <a key={`${lineGroupIndex}-${index}`} href={part}>{part}</a>;
                    }
                } else {
                    return <a key={`${lineGroupIndex}-${index}`} href={part}>{part}</a>; // Return the URL string if it doesn't match specificUrlRegex
                }
            } else {
                return part; // Return part as is if it's not a mention or URL
            }
        });
        
        // Recombine the processed parts of the line group
        return <React.Fragment key={lineGroupIndex}>{parsedParts}</React.Fragment>;
    });

    // Combine line groups with line breaks
    return parsedString.reduce((result, lineGroup, index) => {
        if (index === 0) {
            return [lineGroup];
        } else {
            return [...result, <br key={`br-${index}`} />, lineGroup];
        }
    }, []);
}


export function nl2br(str) {
    if (!str) {
        return null; // Return null if string is falsy
    }

    // If the input is an array of strings and React elements
    if (Array.isArray(str)) {
        return str.map((part, index) => {
            if (typeof part === 'string') {
                // If it's a string, split by line breaks and add <br/> tags
                return part.split(/(?:\r\n|\r|\n)/g).map((line, lineIndex, lines) => (
                    <React.Fragment key={`${index}-${lineIndex}`}>
                        {line}
                        {lineIndex !== lines.length - 1 && <br />} {/* Add <br> except for the last line */}
                    </React.Fragment>
                ));
            } else {
                // If it's a React element, return it directly
                return part;
            }
        });
    }

    // If the input is a plain string, split by line breaks and add <br/> tags
    return str.split(/(?:\r\n|\r|\n)/g).map((line, index, lines) => (
        <React.Fragment key={index}>
            {line}
            {index !== lines.length - 1 && <br />} {/* Add <br> except for the last line */}
        </React.Fragment>
    ));
}


export const Loader = ({ text = '...loading...', className = 'bg-info' }) => (
    <div className="text-center py-2">
        <div className={`loader ${className}`} style={{ width: '3rem', height: '3rem' }} role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
        <div className="fw-light pt-2 fs-5">{text}</div>
    </div>
);
export const formatNumber = (num) => {
    // Detect user locale
    const locale = navigator.language || 'en-US'; // Fallback to 'en-US' if detection fails

    let formattedNumber;
    let abbreviation;
    let fullWord = '';

    // Check if the input is a number
    if (typeof num !== 'number') {
        num = parseInt(num, 10);  // Convert to integer
        if (isNaN(num)) {          // Check if conversion failed
            console.error('Invalid input, must be a number');
            return null;
        }
    }

    // Format numbers greater than or equal to 100,000 with abbreviations
    if (num >= 1e15) {
        formattedNumber = (num / 1e15).toLocaleString(locale, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
        abbreviation = 'qn';
        fullWord = ' quadrillion';
    } else if (num >= 1e12) {
        formattedNumber = (num / 1e12).toLocaleString(locale, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
        abbreviation = 'tn';
        fullWord = ' trillion';
    } else if (num >= 1e9) {
        formattedNumber = (num / 1e9).toLocaleString(locale, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
        abbreviation = 'bn';
        fullWord = ' billion';
    } else if (num >= 1e6) {
        formattedNumber = (num / 1e6).toLocaleString(locale, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
        abbreviation = 'm';
        fullWord = ' million';
    } else if (num >= 1e5) {
        // Numbers over 100,000 should be abbreviated to thousands
        formattedNumber = (num / 1e3).toLocaleString(locale, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
        abbreviation = 'k';
        fullWord = ' thousand';
    } else {
        // For numbers less than 100,000, format with local thousand separators
        formattedNumber = num.toLocaleString(locale, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    }

    return (
        <>
            {formattedNumber}
            <span className='small'>
                <span className="d-inline d-md-none">{abbreviation}</span>
                <span className="d-none d-md-inline"> {fullWord}</span>
            </span>
        </>
    );
};
