import React, { useState, useEffect, useContext } from 'react';
import { CarouselFeedOutput, GridOutput, PostPlaceholder, getPosts, remapCommentTree } from '../utils/posts';
import { formatPost } from '../utils/posts';
import luceneQueryParser from 'lucene-query-parser';

const processSearchTerm = (searchTerm) => {
  try {
    // Parse the search term using Lucene Query Parser
    var results = luceneQueryParser.parse(searchTerm);
    console.log("[SearchResult] lucene return:", results);
    
    // Function to recursively build filter conditions
    const buildFilter = (query) => {
      if (!query) return null;
      
      if (query.operator === "AND" || query.operator === "OR" || query.operator === "<implicit>") {
        // Handle AND and OR operators
        const leftFilter = buildFilter(query.left);
        const rightFilter = buildFilter(query.right);
        if (leftFilter && rightFilter) {
          if(query.operator === "<implicit>") { query.operator = "AND"; }
          return { [query.operator.toLowerCase()]: [leftFilter, rightFilter] };
        } else if (leftFilter) {
          return leftFilter;
        } else if (rightFilter) {
          return rightFilter;
        }
      } else if (query.term) {
        // Handle individual terms
        return { body: { includesInsensitive: query.term } };
      } else if (query.left && !query.right) {
        return { body: { includesInsensitive: query.left.term } };
      }
      
      return null;
    };
    
    // Build filter conditions recursively
    const filterConditions = buildFilter(results);
    // console.log("[SearchResult] Filter conditions:", filterConditions);
    
    return filterConditions;
  } catch (error) {
    // console.error('Error processing search term:', error);
    return null;
  }
};


export const ContentResults = ({ showSearch, search, view, setView, formData, searchTriggered, setSearchTriggered, currentUser, alternateUsers, preferences, accessGroups, feedType, profile, setActiveTab, activeTab }) => {
  const [isLoading, setIsLoading] = useState(null);
  const [posts, setPosts] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [startCursor, setStartCursor] = useState(null);  
  const [endCursor, setEndCursor] = useState(null);  
  const [searchRequested, setSearchRequested] = useState(false);
  const [newerPosts, setNewerPosts] = useState([]);
  const [formattedPosts, setFormattedPosts] = useState([]);
  const [mediaURLs, setMediaURLs] = useState([]);
  const [reformatPosts, setReformatPosts] = useState(false);
  const [prependPosts, setPrependPosts] = useState(false);
  const [seenPostHashHexes, setSeenPostHashHexes] = useState([]);
  const [awaitingResults, setAwaitingResults] = useState(false);
  const [error, setError] = useState(null);

  const [limit, setLimit] = useState(25);
  const [nonMediaPosts, setNonMediaPosts] = useState(0);

  useEffect(() => {
    if (!searchTriggered && !showSearch) {
      setPosts([]);
      setFormattedPosts([]);
      setMediaURLs([]);
      setIsLoading(true);
      fetchData(formData);
    }
  }, []);

  useEffect(() => {
    console.log("[SearchResults.jsx] SEARCHTRIGGERED Changed:", searchTriggered);
    if (searchTriggered) {
      setNewerPosts([]);
      setPosts([]);
      setFormattedPosts([]);
      setMediaURLs([]);
      setIsLoading(true);
      console.log("[SearchResults.jsx] SEARCHTRIGGERED , isLoading:", isLoading);
      fetchData(formData);
      setSearchTriggered(false);
    }
  }, [searchTriggered]);

  /*
  useEffect(() => {
    if (!searchTriggered && !showSearch) {
      setPosts([]);
      setFormattedPosts([]);
      setIsLoading(true);
      fetchData(formData);
    }
  }, [showSearch]);
  */

  /*
  useEffect(() => {
    console.log("[SearchResults.jsx] Feedtype Changed:",feedType);
    setPosts([]);
    setFormattedPosts([]);
    if(!showSearch) { 
      setSearchTriggered(true);
      console.log("[SearchResults.jsx] Feedtype Changed - Search Options Closed, Triggering search...");
    } else { setSearchTriggered(false); }
  }, [feedType]);
  */

  useEffect(() => {
    console.log("[SearchResults.jsx] formData changed: ",formData);
    setPosts([]);
    setNewerPosts([]);
    setFormattedPosts([]);
    setMediaURLs([]);
    setHasNextPage(null);
    setHasPreviousPage(null);
    setStartCursor(null);
    setEndCursor(null);
    if(!showSearch && (!profile && (feedType !== 'home' && feedType !== 'stats'))) { 
      console.log("[SearchResults.jsx] search options NOT visible - so trigger search... (current state) ",searchTriggered);
      setIsLoading(true);
      fetchData(formData)
      //setSearchTriggered(true); 
    }
  }, [formData]);

  useEffect(() => {
    setFormattedPosts([]);
    setReformatPosts(true);
  }, [view]);

  useEffect(() => {
    console.log("[ContentResults.jsx] mediaURLs:",mediaURLs);
  }, [mediaURLs]);

  useEffect(() => {
      const formatPosts = async (appendtype) => {
          let existingFormattedPosts = [];
          let updatedSeenPostHashHexes = [];
          let existingMediaURLs = [];
          if(appendtype) {
            existingFormattedPosts = formattedPosts;
            updatedSeenPostHashHexes = seenPostHashHexes;
            existingMediaURLs = mediaURLs;
          } else {
            setSeenPostHashHexes([]);
          }
          //const type = feedType === "video" ? "video" : feedType === "images" ? "media" : feedType === "embed" ? "embed" : "post";
          const type = 'post';
          console.log("[ProfileFeed.jsx] feed type view", feedType, type, view);
          const newMediaURLs = [];
          
          let outputView = 'list';
          if(view!=='list' && view !=='slideshow') { outputView = view; }

          if (outputView === "list") {
              console.log("[ProfileFeed.jsx] feed type thread");

              const thread = true;
              const newFormattedPosts = [];
              for (const post of posts) {
                  if (post.PostFound && post.PostFound.PostHashHex) {
                      const postHashHex = post.PostFound.PostHashHex;
                      console.log("[ProfileFeed.jsx] Checking postHashHex:", postHashHex);
                      console.log("[ProfileFeed.jsx] updatedSeenPostHashHexes:", updatedSeenPostHashHexes);
                      if (!updatedSeenPostHashHexes.includes(postHashHex)) {
                          console.log("[ProfileFeed.jsx] PostHashHex not seen before. Formatting post...", post);
                          let finalPost;
                          let threaded = false;
                          if(post.PostFound.ParentPosts && post.PostFound.ParentPosts.length > 0) {
                            finalPost = remapCommentTree(post);
                            console.log("[ProfileFeed.jsx] Detected as a discussion thread... finalPost reformatted:",finalPost);
                            threaded = true;
                          } else {
                            finalPost = post;
                          }
                          console.log("[ProfileFeed.jsx] remapCommentTree - finalPost:",finalPost);
                          const formattedPost = await formatPost(finalPost.PostFound, currentUser, 0, 'post', outputView, accessGroups, preferences, threaded);
                          newFormattedPosts.push(formattedPost);
                          updatedSeenPostHashHexes.push(postHashHex);

                          if (post?.PostFound?.ImageURLs && post?.PostFound?.ImageURLs.length > 0) {
                              for (const url of post.PostFound.ImageURLs) {
                                newMediaURLs[url] = post.PostFound;
                              }
                          }
                          if (post?.PostFound?.VideoURLs && post?.PostFound?.VideoURLs.length > 0) {
                              for (const url of post.PostFound.VideoURLs) {
                                newMediaURLs[url] = post.PostFound;
                              }
                          }
                          
                          if (post.PostFound.ParentPosts && post.PostFound.ParentPosts.length > 0) {
                              /*
                              const parentHashHexes = post.PostFound.ParentPosts.map(parentPost => parentPost.PostHashHex);
                              // Exclude the last parent (main post) to prevent duplicates
                              parentHashHexes.pop();
                              updatedSeenPostHashHexes.push(...parentHashHexes);
                              */

                              const parentPosts = [...post.PostFound.ParentPosts];
                              parentPosts.pop();  // Exclude the last parent (main post)
                              
                              for (const parentPost of parentPosts) {
                                  // Extract and store media URLs for parent posts
                                  if (parentPost?.ImageURLs && parentPost?.ImageURLs.length > 0) {
                                      for (const url of parentPost.ImageURLs) {
                                        newMediaURLs[url] = parentPost;  // Store the parent post with the URL as the key
                                      }
                                  }

                                  if (parentPost?.VideoURLs && parentPost?.VideoURLs.length > 0) {
                                      for (const url of parentPost.VideoURLs) {
                                        newMediaURLs[url] = parentPost;  // Store the parent post with the URL as the key
                                      }
                                  }

                                  updatedSeenPostHashHexes.push(parentPost.PostHashHex); // Add parentPost HashHex to seen list
                              }
                          }
                      } else {
                          console.log("[ProfileFeed.jsx] PostHashHex already seen. Skipping...");
                      }
                  }
              }

              console.log("[ProfileFeed.jsx] New formatted posts:", newFormattedPosts);

              // Merge new formatted posts with existing ones
              //const mergedFormattedPosts = [...existingFormattedPosts, ...newFormattedPosts];
              let mergedFormattedPosts = [];
              let mergedMediaURLs = [];
              if(appendtype === 'prepend') {
                mergedFormattedPosts = [...newFormattedPosts, ...existingFormattedPosts];
                mergedMediaURLs = [...newMediaURLs, ...existingMediaURLs];
              } else {
                mergedFormattedPosts = [...existingFormattedPosts, ...newFormattedPosts];
                mergedMediaURLs = [...existingMediaURLs, ...newMediaURLs];
              }
              console.log("[SearchResults.jsx] final formatted posts:",mergedFormattedPosts);
              setMediaURLs(mergedMediaURLs);
              setFormattedPosts(mergedFormattedPosts);
              setSeenPostHashHexes(updatedSeenPostHashHexes);
          } else {
            const newFormattedPosts = [];
            for (const post of posts) {
                if (post.PostFound && post.PostFound.PostHashHex) {
                    const postHashHex = post.PostFound.PostHashHex;
                    console.log("[ProfileFeed.jsx] Checking postHashHex:", postHashHex);
                    console.log("[ProfileFeed.jsx] updatedSeenPostHashHexes:", updatedSeenPostHashHexes);
                    if (!updatedSeenPostHashHexes.includes(postHashHex)) {
                      //const thread = post.PostFound.ParentPosts && post.PostFound.ParentPosts.length > 0;
                      const thread = false;
                      console.log("[ProfileFeed.jsx] Non-discussion post with parent posts, thread", post, thread);
                      //const finalPost = thread ? remapCommentTree(post) : post;
                      if (post?.PostFound?.ImageURLs && post?.PostFound?.ImageURLs.length > 0) {
                          for (const url of post.PostFound.ImageURLs) {
                            newMediaURLs[url] = post.PostFound;
                          }
                      }
                      if (post?.PostFound?.VideoURLs && post?.PostFound?.VideoURLs.length > 0) {
                          for (const url of post.PostFound.VideoURLs) {
                            newMediaURLs[url] = post.PostFound;
                          }
                      }
                      if((view === `grid` || view === `media` || view === `slideshow`) && post.PostFound.ParentPosts && post.PostFound.ParentPosts.length > 0) {
                        for (const parentPost of post.PostFound.ParentPosts) {
                          if (!updatedSeenPostHashHexes.includes(parentPost.PostHashHex)) {
                            const formattedPost = await formatPost(parentPost, currentUser, 0, type, outputView, accessGroups, preferences, thread);
                            console.log("[SearchResults.jsx] formatted post:",formattedPost);
                            newFormattedPosts.push(formattedPost);
                            if (parentPost?.PostFound?.ImageURLs && parentPost?.PostFound?.ImageURLs.length > 0) {
                                for (const url of parentPost.PostFound.ImageURLs) {
                                  newMediaURLs[url] = parentPost.PostFound;
                                }
                            }
                            if (parentPost?.PostFound?.VideoURLs && parentPost?.PostFound?.VideoURLs.length > 0) {
                                for (const url of parentPost.PostFound.VideoURLs) {
                                  newMediaURLs[url] = parentPost.PostFound;
                                }
                            }
                            updatedSeenPostHashHexes.push(parentPost.PostHashHex);
                          }
                        }
                      }
                      const formattedPost = await formatPost(post.PostFound, currentUser, 0, type, view, accessGroups, preferences, thread);
                      console.log("[SearchResults.jsx] formatted post:",formattedPost);
                      newFormattedPosts.push(formattedPost);
                      updatedSeenPostHashHexes.push(post.PostFound.PostHashHex);
                    } else {
                      console.log("[ProfileFeed.jsx] PostHashHex already seen. Skipping...");
                    }
                }
            }
            console.log("[SearchResults.jsx] new formatted posts:",newFormattedPosts);
            let mergedFormattedPosts = [];
            let mergedMediaURLs = [];
            if(appendtype === 'prepend') {
              mergedFormattedPosts = [...newFormattedPosts, ...existingFormattedPosts];
              mergedMediaURLs = { ...newMediaURLs, ...existingMediaURLs };
            } else {
              mergedFormattedPosts = [...existingFormattedPosts, ...newFormattedPosts];
              mergedMediaURLs = { ...existingMediaURLs, ...newMediaURLs };
            }
            console.log("[ContentResults.jsx] final formatted posts:",mergedFormattedPosts,newMediaURLs,mergedMediaURLs);
            setMediaURLs(mergedMediaURLs);
            setFormattedPosts(mergedFormattedPosts);
            setSeenPostHashHexes(updatedSeenPostHashHexes);
          }
          if(reformatPosts) { setReformatPosts(false); }
          if(prependPosts)  { setPrependPosts(false); }
          setIsLoading(false);
      };
      console.log("[ContentResults.jsx] posts, isLoading:", isLoading,posts);
      let type = 'append';
      if(reformatPosts) { setFormattedPosts([]); setMediaURLs([]); type = null; }
      if(prependPosts) { type = 'prepend'; }
      formatPosts(type);
      console.log("[ContentResults.jsx] posts reformattted, isLoading:", isLoading);
  }, [posts, reformatPosts, prependPosts]);


  const fetchData = async (formData, cursor = null, isFetchingNewer = false) => {
    console.log("[ContentResults.jsx] fetchData()....isLoading:", isLoading, formData, cursor);
    setSearchRequested(false);
    try {
      setError(null);
      setAwaitingResults(true);
      let data = { formData };

      // Adjustments to set the cursor properly
      if (cursor) {
        data.after = isFetchingNewer ? null : cursor; // Set 'after' for older posts and 'before' for newer posts
        data.before = isFetchingNewer ? cursor : null;
        data.first = isFetchingNewer ? null : 25;
      } else {
        setPosts([]); // Clear posts only when cursor is null
        data.after = null; // Set initial value for after cursor
        data.before = null; // Set initial value for before cursor
      }

      // Collect form data if not manually provided
      if (!formData.orderBy) {
        const contentFormValue = document.getElementById('contentFormValue');
        if (contentFormValue) {
          contentFormValue.value = formData.searchTerm;
        }

        const form = document.getElementById('contentFormFilter');
        if (form) {
          const formValues = new FormData(form);
          formValues.forEach((value, key) => {
            data[key] = value;
          });
        }
      }

      const variables = processFormData(data, cursor, isFetchingNewer);
      const postData = await getPosts(variables);
      //console.log("[ContentBar.jsx] variables, returnedData", variables, postData);

      if (postData && postData.nodes) {
        setAwaitingResults(false);

        //console.log("[ContentResults.jsx] SeenHashes:",seenPostHashHexes);
        const filteredPosts = postData.nodes.filter(
          post => !seenPostHashHexes.includes(post.PostFound.PostHashHex)
        );

        // If fetching newer posts, prepend them; otherwise, append them
        if (isFetchingNewer) {
          setNewerPosts(prevPosts => [...filteredPosts, ...prevPosts]);
        } else {
          setPosts(prevPosts => [...prevPosts, ...filteredPosts]);
        }

        if (postData.pageInfo) {
          setHasNextPage(postData.pageInfo.hasNextPage);
          setHasPreviousPage(postData.pageInfo.hasPreviousPage);
          setStartCursor(postData.pageInfo.startCursor);
          if (postData.pageInfo.endCursor) {
            setEndCursor(postData.pageInfo.endCursor);
          }
        }
      }
    } catch (error) {
      console.error('[ContentResults.jsx] SearchQuery: Error fetching data:', error);
      setIsLoading(false);
      setError(error);
      setAwaitingResults(false);
    } finally {
      setSearchRequested(true);
    }
  };


  const loadMore = () => {
    setIsLoading(true);
    fetchData(formData, endCursor, false);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (newerPosts === null || newerPosts.length === 0) { // Only call if newerPosts is null
        checkForNewMessages();
      }
    }, 10000); // Check every 10 seconds
    
    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [startCursor, newerPosts]); // Add newerPosts to dependencies
  

  useEffect(() => {
    console.log("[ContentResults.jsx] New messages: ",newerPosts);
  }, [newerPosts]);
  
  const checkForNewMessages = async () => {
    if (startCursor) {
      const result = await fetchData(formData, startCursor, true); // Fetch newer posts using startCursor
    }
  };

  const showNewPosts = () => {
    console.log("[ContentResults.jsx] SHOW NEW POSTS");
    setPrependPosts(true);
    setPosts(prevPosts => [...newerPosts, ...prevPosts]);
    setNewerPosts([]);
  }

  const processFormData = (payload, cursor = null, isFetchingNewer = null) => {
    console.log("[Search] form data at processFormData:", payload);
    const formData = payload.formData;
    const variables = {
      condition: {},
      filter: { and: [] },
      first: limit,
      orderBy: [formData.orderBy],
    }; 

    if(isFetchingNewer) {
      variables.before = cursor;
    } else {
      variables.after = cursor;
    }

    console.log("[Search] formData:",formData);
    console.log("[Search] formData search:",formData["search"]);
    console.log("[Search] formData blog:",formData["blog"]);
    if(profile) {
      //formData.poster = profile.Username
      formData.poster = null;
      formData.posterPublicKey = [profile.PublicKeyBase58Check];
    }
    Object.keys(formData).forEach(field => {
      console.log("[Search] formData field, value:",field,formData[field]); //,formData[field]);

      if (field === 'search' && formData[field]) {
        /* original simple
        const searchFilter = {
          body: {
            includesInsensitive: formData[field]
          }
        };
        variables.filter.and.push(searchFilter);*/
        console.log("[Search] Search term:",formData[field]);
        const searchFilter = processSearchTerm(formData[field]);
        console.log("[Search] Search filter:",searchFilter);
        if (searchFilter) {
          variables.filter.and.push(searchFilter);
        }
      } else if (field === 'tabSearch' && formData[field]) {
        /* original simple
        const searchFilter = {
          body: {
            includesInsensitive: formData[field]
          }
        };
        variables.filter.and.push(searchFilter);*/
        console.log("[Search] Search term:",formData[field]);
        const searchFilter = processSearchTerm(formData[field]);
        console.log("[Search] Search filter:",searchFilter);
        if (searchFilter) {
          variables.filter.and.push(searchFilter);
        }
      } else if (field === 'blog') {
        console.log("[Search] Processing blog field:", formData[field]);
        if (formData[field] === true) {
            // Construct blog filter
            const blogFilter = [
                { "extraData": { "containsKey": "CoverImage" } },
                { "extraData": { "containsKey": "BlogTitleSlug" } },
                { "extraData": { "containsKey": "BlogDeltaRtfFormat" } }
            ];
            // Clear certain conditions if necessary
            variables.condition.parentPostHash = null;
            variables.condition.repostedPostHash = null;
            // Push blog filter to variables.filter.and
            variables.filter.and.push({ "or": blogFilter });
        }
      /*
      } else if (field === 'mentions' && formData[field].length > 0) {
        formData[field].forEach((mention) => {
          const mentionValue = Object.keys(mention)[0]; // Get the mention value from the object
          console.log("[Search] mentions:", mentionValue); // Log the mention value
          const searchFilter = {
            'body': {
              'includesInsensitive': mentionValue // Use the mention value in the filter
            }
          };
          variables.filter.and.push(searchFilter);
        });*/
      } else if (field === 'mentions' && Array.isArray(formData[field]) && formData[field].length > 0) {
        const mentionFilters = formData[field].map(mention => {
            const mentionValue = Object.keys(mention)[0]; // Get the mention value from the object
            console.log("[Search] mentions:", mentionValue); // Log the mention value
            return {
                'body': {
                    'includesInsensitive': mentionValue // Use the mention value in the filter
                }
            };
        });
    
        if (mentionFilters.length > 0) {
            const mentionsFilter = {
                "or": mentionFilters
            };
            variables.filter.and.push(mentionsFilter);
        }    
      } else if (field === 'parentPostHash' && formData[field]) {
        const fieldValue = formData[field];
        if (fieldValue !== null) {
          const parentPostHashFilter = {
            parentPostHash: {
              isNull: !fieldValue,
            }
          };
          variables.filter.and.push(parentPostHashFilter);
        }
      } else if ((field === 'startDate' || field === 'endDate') && formData[field] !== null) {
        const fieldValue = formData[field].trim();
        if (fieldValue) {
          const timestampFilter = {
            timestamp: {
              [field === 'startDate' ? 'greaterThanOrEqualTo' : 'lessThanOrEqualTo']: fieldValue
            }
          };
          variables.filter.and.push(timestampFilter);
        }
      } else if (field === 'parentPoster' && typeof formData[field] === 'string' && formData[field]) {
        const fieldValue = formData[field] ? formData[field].trim() : null;
        if (fieldValue && fieldValue !== '') {
          const parentPosterFilter = {
            'parentPost': {
              'poster': {
                'username': {
                  'equalToInsensitive': fieldValue
                }
              }
            }
          };
          variables.filter.and.push(parentPosterFilter);
        }
      } else if (field === 'poster' && formData[field]) {
        const fieldValue = formData[field].trim();
        if (fieldValue !== '') {
          const userFilter = {};
          userFilter['poster'] = {
            'username': {
              'equalToInsensitive': fieldValue || null
            }
          };
          variables.filter.and.push(userFilter);
        }
      /*
      } else if ((field === 'posterPublicKey' || field === 'publicKey') && formData[field]) {
        const fieldValue = formData[field].trim();
        if (fieldValue !== '') {
          const userFilter = {};
          userFilter['posterPublicKey'] = {
            'equalTo': fieldValue || null
          };
          variables.filter.and.push(userFilter);
        }
      */
      } else if ((field === 'posterPublicKey' || field === 'publicKey') && Array.isArray(formData[field]) && formData[field].length > 0) {
        const fieldValues = formData[field].map(value => value.trim()).filter(value => value !== '');
        if (fieldValues.length > 0) {
            const userFilter = {
                "or": fieldValues.map(value => ({
                    [field]: {
                        'equalTo': value
                    }
                }))
            };
            variables.filter.and.push(userFilter);
        }
      } else if (field === 'filterNumNftCopiesForSale' && formData[field]) {
        const numNftCopiesFilter = {};
        numNftCopiesFilter['numNftCopiesForSale'] = {
          'greaterThanOrEqualTo': 1
        };
        variables.filter.and.push(numNftCopiesFilter);
      } else if (field === 'repliesExist' && formData[field]) {
        const repliesExistFilter = {};
        repliesExistFilter['replies'] = {
          'every': {
            "repliesExist": true
          }
        };
        variables.filter.and.push(repliesExistFilter);
      } else if (field === 'repostedPostExists' && formData[field]) {
        const repostedPostExistsFilter = {
          "repostedPostExists": true
        };
        variables.filter.and.push(repostedPostExistsFilter);
      } else if (field === 'imageUrls' || field === 'videoUrls' || field === 'parentPostHash') {

        console.log("[SearchResults.jsx] condition: ",field);
        if (formData[field] !== null) {
          if(formData[field] === false) {
            variables.filter.and.push({
                "or": [
                    { [field]: { "isNull": true } },
                    { [field]: { "equalTo": "" } }
                ]
            });
          } else {
            variables.filter.and.push({ [field]: { "isNull": false } });
            variables.filter.and.push({ [field]: { "notEqualTo": "" } });
          }
        }
      } else if (field === 'repostedPostHash' && formData[field] === false && formData["isQuotedRepost"] !== true) {
        variables.filter.and.push({ [field]: { isNull: true } });
        if(formData["isQuotedRepost"]) {
          // Must be Quote Repost
          // Therefore repostedPostHash must also be true
        } else if(formData["isQuotedRepost"]===false) {
          // Must not be a Quote Repost
          // Therefore repostedPostHash can be true or false
        } else { 
          // Can be a quote repost
          // Therefore repostedPostHash must also be true
        }

      } else if (field === "embedUrlFilter" && formData[field] !== null) {
        
      } else if (field === "embedVideoUrl" && formData[field] !== null) {
        if(formData[field]===true) {
          const embedVideoUrlFilter = {
            "and": [
              {
                "extraData": {
                  "containsAnyKeys": "EmbedVideoURL",
                },
                "not": {
                  "extraData": {
                      "contains": { "EmbedVideoURL": "", }
                  },
                }
              }
            ]
          }; 
          variables.filter.and.push(embedVideoUrlFilter);
        } else {
          const embedVideoUrlFilter = {
            "or": [
              {
                "not": {
                  "extraData": {
                    "containsAnyKeys": "EmbedVideoURL",
                  },
                },
                "extraData": {
                      "contains": { "EmbedVideoURL": "", }
                },
              }
            ]
          }; 
          variables.filter.and.push(embedVideoUrlFilter);
        }
      } else if (field === "node" && formData[field].length > 0) {
        // console.log("[SearchResults] We have a node selection: ", formData[field]);
        const orStatements = formData[field].map(nodeId => ({
            "extraData": {
                "contains": {"Node": `${nodeId}`}
            }
        }));
        variables.filter.and.push({ OR: orStatements });
      } else if ((field.startsWith('is') || field.startsWith('has') || field==='numNftCopies') && formData[field] !== null) { //} && formData[field]) {
        if(field==='isPinned') {
          // add extradata workaround
          const isPinnedExtraData = {
            "extraData": {
                      "contains": { "IsPinned": "true", }
                }
            }; 
          variables.filter.and.push(isPinnedExtraData);
        } else {
          variables.condition[field] = formData[field];
        }
      } else {
        console.log("[SearchResults.jsx] ** No Processing ** - formData field:",field,formData[field]);
      }
      console.log("[SearchResults.jsx] finished formData field loop, isLoading:", isLoading);
    });
    console.log("[SearchResults.jsx] finished formData fields, isLoading:", isLoading);
    //document.getElementById('navbarSearchInput').value = formData.search;
    const ariaOwnsValue = 'navbarSearchInput'; // The value of the aria-owns attribute
    const inputElement = document.querySelector(`[aria-owns="${ariaOwnsValue}"]`);
    if (inputElement) {
      inputElement.value = formData.search;
    }
    document.getElementById('contentFormValue').value = formData.search;
    console.log("[SearchResults.jsx] end of build formData, isLoading:", isLoading, variables);
    return variables;
  };
  
  if(profile && profile.Username && (activeTab==='home' || activeTab==='stats')) { 
    return; 
  }

  let classCol;
  
  return (
    <React.Fragment key={`feed_content_${feedType}`}>
      <div className={`p-3 col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xxl-6 offset-xxl-3`} style={{ minHeight: "100vh" }}>
        {formattedPosts.length > 0 && (
            view === 'slideshow' ? (
              <CarouselFeedOutput formattedPosts={formattedPosts} currentUser={currentUser} newerPosts={newerPosts} showNewPosts={showNewPosts} hasNextPage={hasNextPage} loadMore={loadMore} />  
            ) : (
            <>
              {newerPosts && newerPosts.length > 0  && (
                <div key='contentBarNewer' className='text-center'>
                  <button className="col-6 mx-auto btn btn-primary my-3" onClick={() => showNewPosts()}>
                      {formData.orderBy === 'TIMESTAMP_DESC' ? `Newer` : `Older`} messages
                  </button>
                </div>
              )}
              {view === 'grid' ? (
                <GridOutput mediaURLs={mediaURLs} currentUser={currentUser} newerPosts={newerPosts} showNewPosts={showNewPosts} hasNextPage={hasNextPage} loadMore={loadMore} view={view} isLoading={isLoading} searchTriggered={searchTriggered} endCursor={endCursor} />  
              ) : (
                  <div className='row'>
                    {formattedPosts.map((formattedPost, index) =>
                      formattedPost && formattedPost !== '' ? (
                        <React.Fragment key={index}>
                          {formattedPost}
                        </React.Fragment>
                      ) : null
                    )}
                  </div>
              )}
            </>
          )
        )}

        {(view === 'slideshow' || view === 'media' || view === 'grid') && 
          !isLoading && 
          searchTriggered !== null && 
          formattedPosts.filter(post => !post || post === '').length > 0 && (
            <div className='col-12 text-center text-muted'>
              {formattedPosts.filter(post => !post || post === '').length} results have no suitable media for {view} view - switch to list view to see all results.
            </div>
        )}

        {isLoading || reformatPosts ? (
            <div className='row'>
              <PostPlaceholder feedType={feedType} view={view} />
            </div>
        ) : (
          <div className='row'>

          {(!isLoading && !awaitingResults) && (!formattedPosts || formattedPosts.length === 0) && !isLoading && !reformatPosts && searchTriggered && (
            <>
              {search ? (
                <div className="col-12 text-center py-5">
                  <p className="fw-bold">Your search yielded no results</p>
                  <p>Please try again with a different search term or filters.</p>
                </div>
              ) : (
                <p className="col-12 text-center py-5">There are no {feedType} items</p>
              )}
            </>
          )}

          {searchTriggered!== null && formattedPosts.length > 0 && !isLoading && hasNextPage && endCursor && (
            <div className='text-center'>
              <button className="col-6 mx-auto btn btn-primary my-3" onClick={() => loadMore()}>
                  Load More
              </button>
            </div>
          )}
          </div>
        )}
      </div>
    </React.Fragment>
);
};
