import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip } from 'chart.js';
import { deso_graphql } from '../../utils/graphql'; // Adjust path as per your project structure
import { calculatePeriods, normalizeCount } from './sharedFunctions'; // Adjust path as per your project structure
import { Spinner } from 'react-bootstrap';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip);

const categories = {
  "Social": [5], // Posts affecting (reply / tag)
  "Reactions": [10,29], // Likes and Reactions
  "Follows": [9]
  // Add more categories as needed
};

const generateColors = (categories) => {
  return Object.keys(categories).map(() => {
    // Generate a random RGBA color
    return `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.6)`;
  });
};

export const calculateFilters = (periods) => {
  const filters = {};

  periods.forEach((period, index) => {
    const start = period.start ? period.start.toISOString() : null;
    const end = period.end.toISOString();

    Object.entries(categories).forEach(([category, types]) => {
      filters[`${category}_filter${index + 1}`] = {
        timestamp: {
          greaterThanOrEqualTo: start,
          lessThan: end
        },
        "txnType": {
          "in": types
        }
      };
    });
  });

  return filters;
};

const UserTransactionsAffecting = ({ profile, additionalData, setAdditionalData }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  //const periods = calculatePeriods(additionalData.firstTransactionTimestamp);
  //const filters = calculateFilters(periods);
  
  useEffect(() => {
    const fetchData = async () => {
      const periods = calculatePeriods(additionalData.firstTransactionTimestamp);
      const filters = calculateFilters(periods);
      const queryParts = Object.keys(filters).map((key) => `
        ${key}: affectedPublicKeys(filter: $${key}, condition: $condition) {
          totalCount
        }
      `).join('\n');

      const query = `
        query TransactionsAffectingUser(${Object.keys(filters).map((key) => `$${key}: AffectedPublicKeyFilter`).join(', ')}, $condition: AffectedPublicKeyCondition) {
          ${queryParts}
        }
      `;

      const variables = {
        ...filters,
        condition: {
          publicKey: profile.PublicKeyBase58Check
        }
      };

      const request = {
        query,
        variables,
        operationName: "TransactionsAffectingUser"
      };

      try {
        const response = await deso_graphql(request); //,'https://graphql.focus.xyz/graphql');
        const data = response.data;

        if(data) {
          // Prepare normalized data for each category
          const normalizedData = Object.keys(categories).map((category) => {
            return periods.map((period, index) => {
              const key = `${category}_filter${index + 1}`;
              const count = data[key]?.totalCount || 0;
              return normalizeCount(count, period.start, period.end, additionalData.firstTransactionTimestamp);
            });
          });

          const existingColors = additionalData.transactionsAffecting?.colors;
          const colors = existingColors || generateColors(categories);

          // Update additionalData with normalizedData and colors
          const updatedData = {
            ...additionalData,
            transactionsAffecting: {
              normalizedData,
              labels: periods.map(period => period.label),
              categories: Object.keys(categories),
              colors // Persist the colors
            }
          };

          setAdditionalData(updatedData);
        }
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    if(!additionalData) { // wait 
    } else if (additionalData && additionalData.firstTransactionTimestamp && !additionalData.transactionsAffecting) { 
      fetchData(); 
    } else if (additionalData.transactionsAffecting) { setLoading(false); }
  }, [profile.PublicKeyBase58Check, additionalData]);

  if (loading || !additionalData.transactionsAffecting || !additionalData.transactionsAffecting?.labels) return (
    <div className="card shadow-sm h-100 placeholder-glow">
        <div className='card-header pt-3'>
          <h5><i className={`bi bi-repeat me-3`}></i>Engagement Received</h5>
          Average weekly engagement received
        </div>
        <div className="card-body text-center">
            <Spinner animation="border" />
        </div>
    </div>
  );
if (error) return (
    <div className="card shadow-sm h-100 placeholder-glow">
        <div className='card-header pt-3'>
          <h5><i className={`bi bi-repeat me-3`}></i>Engagement Received</h5>
          Average weekly engagement received
        </div>
        <div className="card-body text-center">
            <p>Error fetching data: {error.message}</p>
        </div>
    </div>
  );

  //if (!additionalData.transactionsAffecting?.labels) return <p>No labels</p>;

  // Prepare chart data
  const chartData = {
    labels: additionalData.transactionsAffecting.labels,
    datasets: additionalData.transactionsAffecting.categories.map((category, index) => ({
      label: category,
      data: additionalData.transactionsAffecting.normalizedData[index],
      backgroundColor: additionalData.transactionsAffecting.colors[index], // Use saved colors
      stack: 'stack1'
    }))
  };
  
  // Chart options
  const options = {
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const datasetLabel = tooltipItem.dataset.label || '';
            const value = parseFloat(tooltipItem.raw).toFixed(1);
            return `${datasetLabel}: ${value}`;
          }
        }
      }
    }
  };

  return (
    <div className="card shadow-sm h-100">
        <div className='card-header pt-3'>
            <h5><i className={`bi bi-repeat me-3`}></i>Engagement Received</h5>
            Average weekly engagement received
        </div>
        <div className="card-body text-center">
          <Bar data={chartData} options={options} />
        </div>
    </div>
  );
};

export default UserTransactionsAffecting;
