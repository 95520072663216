import React, { createContext, useContext, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

// Define the error messages
export const errorMessages = {
  // General
  "RuleErrorInsufficientBalance": {
    "title": "Insufficient Balance",
    "description": <><p>You do not have sufficient balance to carry out this transaction.</p><p>Deposit funds into your wallet to continue.</p></>,
    "icon": <i key="1" className="bi bi-currency-exchange"></i>,
  },
  // SubmitTransaction: Problem processing transaction: VerifyAndBroadcastTransaction: Problem broadcasting txn: BroadcastTransaction: : Server._addNewTxn: problem adding txn to pos mempool: PosMempool.AddTransaction: Transaction already in mempool
  "Transaction already in mempool": {
    "title": "Unconfirmed transaction",
    "description": <><p>This transaction already exists in the mempool. Are you trying to re-diamond the same post?</p></>,
    "icon": <i key="1" className="bi bi-exclamation-triangle-fill"></i>,
  },
  // Diamonds
  "RuleErrorCreatorCoinTransferPostAlreadyHasSufficientDiamonds": {
    "title": "Error sending diamonds",
    "description": "You have already awarded this post with the chosen diamond level. You need to issue a higher number of diamonds.",
    "icon": <i key="1" className="bi bi-gem"></i>,
  },
  "Sender and receiver cannot be the same.": {
    "title": "Sorry, you can't send that diamond",
    "description": "Sadly, you can't diamond your own content.",
    "icon": <i key="1" className="bi bi-gem"></i>,
  },
  // Follows
  "RuleErrorFollowEntryAlreadyExists": {
    "title": "Error changing following status",
    "description": "You are trying to follow an account you already follow.",
    "icon": <i key="1" className="bi bi-person-fill"></i>,
  },
  "RuleErrorCannotUnfollowNonexistentFollowEntry": {
    "title": "Can't unfollow",
    "description": "You are trying to unfollow an account you don't follow.",
    "icon": <i key="1" className="bi bi-person-fill"></i>,
  }
};

// Create the error context
export const ErrorContext = createContext();

// Custom hook to access the error context
export const useError = () => useContext(ErrorContext);

// Error provider component
export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);

  // Function to handle errors
  const handleError = (errorMessage) => {
    console.log('Error occurred:', errorMessage); // Log the error message
  
    // Split the error message and get the last part
    const parts = errorMessage.split(':');
    const lastPart = parts[parts.length - 1].trim();
  
    // Check if the last part exists in errorMessages
    const errorMessageData = errorMessages[lastPart] || {
      title: 'An error occurred',
      description: <p>{errorMessage}</p>, // Use the entire error message as the description
      icon: <i key="1" className="bi bi-exclamation-circle-fill"></i>, // Generic error icon
    };
  
    setError(errorMessageData);
  };

  // Function to clear errors
  const clearError = () => {
    console.log('Clearing error'); // Log when clearing the error
    setError(null);
  };

  return (
    <ErrorContext.Provider value={{ error, handleError, clearError }}>
      {error && <ErrorModal error={error} onClose={clearError} onRetry={handleError} />}
      {children}
    </ErrorContext.Provider>
  );
};

// Error modal component using react-bootstrap Modal
export const ErrorModal = ({ error, onClose, onRetry }) => {
  console.log('ErrorModal rendering:', error); // Log when ErrorModal renders

  return (
    <Modal show={!!error} onHide={onClose} centered>
      <Modal.Body className="text-center">
        <div className="d-flex flex-row flex-nowrap mb-3">
          <span className='flex-fill'>"Houston, we have a problem..."</span>
          <span className="nav-link action me-3 d-flex flex-nowrap align-self-start" onClick={onClose}>
              <i className="bi bi-x-circle-fill me-2"></i><span className="d-none d-lg-inline"></span>Close
          </span>
        </div>
        {error.icon && <h1>{error.icon}</h1>}
        <h5>{error.title}</h5>
        <p>{error.description}</p>
        <div className="d-flex flex-row flex-nowrap">
          <Button variant="primary" onClick={onRetry}>Retry</Button>
          <Button variant="secondary" onClick={onClose}>Close</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
