import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { configure, getIsHolding } from "deso-protocol";
import { nodes } from '../utils/helpers';
import { getProfileFromCache } from '../services/profileCache';
import { DeSoIdentityContext } from 'react-deso-protocol';
import { FormCheck } from 'react-bootstrap';

const UserPreferencesContext = createContext();
export const systemKey = process.env.REACT_APP_SYSTEM_KEY;
export const appName = process.env.REACT_APP_APPNAME;


export const useUserPreferences = () => useContext(UserPreferencesContext);

export async function coinGated(currentUser, value) {
  try {
    const coinHolding = await getIsHolding({ PublicKeyBase58Check: currentUser.PublicKeyBase58Check, IsHodlingPublicKeyBase58Check: process.env.REACT_APP_GATING_KEY });
    console.log('systemProfile', systemKey, coinHolding);
    if(coinHolding.IsHodling) {
      console.log('systemProfile value:', coinHolding.BalanceEntry.ProfileEntryResponse.CoinPriceDeSoNanos/1000000000);
      const thisUserHoldsValue = (coinHolding.BalanceEntry.BalanceNanos/1000000000)*(coinHolding.BalanceEntry.ProfileEntryResponse.CoinPriceDeSoNanos/1000000000);
      console.log('systemProfile holder value:',thisUserHoldsValue, value);
      if(thisUserHoldsValue>value) { console.log('systemProfile TRUE'); return true; }
    } else {
      return false;
    }
    // You can return or do something else with systemProfile here
  } catch (error) {
    // Handle errors here
    console.error('systemProfile Error fetching system profile:', error);
  }
}

export const UserPreferencesProvider = ({ children }) => {
  const { currentUser, isLoading } = useContext(DeSoIdentityContext);

  const getDefaultPreferences = () => {
    return {
      nodeURI: 'https://node.deso.org',
      nodeId: 1, // Default node ID
      currency: 'USD',
      theme: 'dark', // Add theme mode setting here
      lastNotificationSeen: null,
      lastMessageSeen: null,
      notificationSettings: defaultNotificationPreferences,
    };
  };

  const initialPreferences = getDefaultPreferences();
  const [preferences, setPreferences] = useState({});
  const [notificationSettings, setNotificationSettings] = useState(null); // State for notificationSettings

  const isInitialMount = useRef(true); // Ref to track initial mount

  const getUserPreferencesFromLocalStorage = () => {
    try {
      const userKey = currentUser?.PublicKeyBase58Check || 'default';
      let storedPreferences = localStorage.getItem('preferences');
      let userPreferences = storedPreferences ? JSON.parse(storedPreferences)[userKey] : null;

      if (!userPreferences) {
        const defaultPreferences = initialPreferences;
        updateLocalStoragePreferences(userKey, defaultPreferences);
        return defaultPreferences;
      }

      const mergedPreferences = { 
        ...initialPreferences, 
        ...userPreferences, 
        theme: userPreferences.theme || 'dark' 
      };
      return mergedPreferences;
    } catch (error) {
      return initialPreferences;
    }
  };

  const fetchPreferencesFromAPI = async () => {
    try {
      const userKey = currentUser?.PublicKeyBase58Check;
      if (!userKey) throw new Error('No user key available');
  
      const response = await fetch('https://beyondsocial.app/api.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          endpoint: 'userPreferences',
          PublicKeyBase58Check: userKey,
        }),
      });
  
      const result = await response.json();
      if (response.ok && result) {
        return result.data; // Assuming API returns the preferences in this field
      }
      throw new Error('No preferences returned from API');
    } catch (error) {
      console.error('API fetch failed', error);
      return null;
    }
  };

  const updateBackendPreferences = async () => {
    try {
      const payload = {
        endpoint: 'notificationSave',
        userData: {
          currentUser: {
            ProfileEntryResponse: {
              PublicKeyBase58Check: currentUser?.ProfileEntryResponse?.PublicKeyBase58Check ? currentUser?.ProfileEntryResponse?.PublicKeyBase58Check : null,
              Username: currentUser?.ProfileEntryResponse?.Username ? currentUser?.ProfileEntryResponse?.Username : null,
            },
          },
          preferences,
        },
      };
      //console.log("[Backend] Send updated preferences",userData);
      await sendDataToBackend(payload);

    } catch(error) {
      //console.log("[Backend] Error sending updated preferences",error);
    }
  }

  const sendDataToBackend = async (data) => {
    //console.log("[PUSH Notifications] trying fetch { }...");
    try {
      const response = await fetch('https://beyondsocial.app/api.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Optionally, include additional headers for authentication or other purposes
        },
        body: JSON.stringify(data),
      });
      //console.log("[PUSH Notifications] BACKEND: ",response);
      if (!response.ok) {
        throw new Error('[PUSH Notifications] Network response was not ok');
      }
      const responseData = await response.json();
      //console.log('[PUSH Notifications] Response from server:', responseData);
    } catch (error) {
      console.error('[PUSH Notifications] Error sending data to backend:', error);
    }
  };

  useEffect(() => {
    const loadPreferences = async () => {
      if (isInitialMount.current && !isLoading && currentUser) {
        let preferences;
  
        // Try to fetch preferences from API
        const apiPreferences = await fetchPreferencesFromAPI();
        if (apiPreferences) {
          preferences = {
            ...initialPreferences,
            ...apiPreferences,
            theme: apiPreferences.theme || 'dark',
          };
          updateLocalStoragePreferences(currentUser.PublicKeyBase58Check, preferences); // Save fetched preferences to localStorage
        } else {
          // If API fails, use localStorage
          const preferencesFromLocalStorage = getUserPreferencesFromLocalStorage();
          preferences = preferencesFromLocalStorage;
        }
  
        setPreferences(preferences);
        isInitialMount.current = false; // Update ref to indicate subsequent renders
      }
    };
  
    const pollPreferences = async () => {
      const apiPreferences = await fetchPreferencesFromAPI();
      if (apiPreferences) {
        const updatedPreferences = {
          ...initialPreferences,
          ...apiPreferences,
          theme: apiPreferences.theme || 'dark',
        };
  
        // Compare current preferences with fetched preferences
        if (JSON.stringify(preferences) !== JSON.stringify(updatedPreferences)) {
          setPreferences(updatedPreferences);
          updateLocalStoragePreferences(currentUser.PublicKeyBase58Check, updatedPreferences);
        }
      }
    };
  
    if (currentUser && !isLoading) {
      loadPreferences();
      // Set an interval for polling
      const pollingInterval = setInterval(() => {
        pollPreferences();
      }, 60000); // Poll every 60 seconds
      return () => clearInterval(pollingInterval); // Clear the interval on component unmount
    }
  }, [currentUser, isLoading, preferences]);
  

  const updateLocalStoragePreferences = (userKey, updatedPreferences) => {
    const storedPreferences = localStorage.getItem('preferences');
    const preferencesObject = storedPreferences ? JSON.parse(storedPreferences) : {};
    preferencesObject[userKey] = updatedPreferences;
    localStorage.setItem('preferences', JSON.stringify(preferencesObject));
  };

  useEffect(() => {
    if (!isInitialMount.current) {
      const userKey = currentUser?.PublicKeyBase58Check || 'default';
      updateLocalStoragePreferences(userKey, preferences);
      updateBackendPreferences();
      
      const elements = document.querySelectorAll('[data-bs-theme]');
      elements.forEach(element => {
        element.setAttribute('data-bs-theme', preferences.theme);
      });

      const themeColorMeta = document.querySelector('meta[name="theme-color"]');
      if (themeColorMeta) {
        themeColorMeta.setAttribute('content', preferences.theme === 'dark' ? '#000000' : '#ffffff');
      }
    }
  }, [preferences, currentUser]);

  // Configuration related to spending limits and Deso actions
  useEffect(() => {
    configure({
      spendingLimitOptions: {
        "GlobalDESOLimit": 1000000000,
        "TransactionCountLimitMap": {
          "UPDATE_PROFILE": 1000000000,
          "CREATE_NFT": 1000000000,
          "UPDATE_NFT": 1000000000,
          "SUBMIT_POST": 1000000000,
          "NEW_MESSAGE": 1000000000,
          "BASIC_TRANSFER": 1000000000,
          "FOLLOW": 1000000000,
          "LIKE": 1000000000,
          "CREATOR_COIN": 1000000000,
          "CREATOR_COIN_TRANSFER": 1000000000,
          "ACCEPT_NFT_BID": 1000000000,
          "BURN_NFT": 1000000000,
          "CREATE_USER_ASSOCIATION": 1000000000,
          "CREATE_POST_ASSOCIATION": 1000000000,
          "ACCESS_GROUP": 1000000000,
          "ACCESS_GROUP_MEMBERS": 1000000000,
          "AUTHORIZE_DERIVED_KEY": 1
        },
        "CreatorCoinOperationLimitMap": {
          "": {
            "any": 1000000000
          }
        },
        "AssociationLimitMap": [
          {
            "AssociationClass": "Post",
            "AssociationType": "",
            "AppScopeType": "Any",
            "AppPublicKeyBase58Check": "",
            "AssociationOperation": "Any",
            "OpCount": 1000000000
          },
          {
            "AssociationClass": "User",
            "AssociationType": "",
            "AppPublicKeyBase58Check": "",
            "AppScopeType": "Any",
            "AssociationOperation": "Any",
            "OpCount": 1000000000
          }
        ],
        "AccessGroupLimitMap": [
          {
            "AccessGroupOwnerPublicKeyBase58Check": "",
            "ScopeType": "Any",
            "AccessGroupKeyName": "",
            "OperationType": "Any",
            "OpCount": 1000000000
          }
        ],
        "AccessGroupMemberLimitMap": [
          {
            "AccessGroupOwnerPublicKeyBase58Check": "",
            "ScopeType": "Any",
            "AccessGroupKeyName": "",
            "OperationType": "Any",
            "OpCount": 1000000000
          }
        ],
        "NFTOperationLimitMap": {
          "": {
            "0": {
              "any": 1000000000
            }
          }
        }
      },
      appName: appName,
      nodeURI: preferences.nodeURI,
    });
  }, [preferences.nodeURI]); // Update configure when nodeURI changes

  const handleCurrencySelect = (selectedCurrency) => {
    setPreferences(prevPreferences => ({
      ...prevPreferences,
      currency: selectedCurrency
    }));
  };

  const handleNodeSelect = (selectedNode) => {
    const { URL, Node } = selectedNode;
    setPreferences(prevPreferences => ({
      ...prevPreferences,
      nodeURI: URL,
      nodeId: Node // Use selectedNode.Node directly
    }));
  };

  const handleLastNotificationSeen = (latestNotification) => {
    setPreferences(prevPreferences => ({
      ...prevPreferences,
      lastNotificationSeen: latestNotification
    }));
  };

  const handleLastMessageSeen = (latestMessage) => {
    setPreferences(prevPreferences => ({
      ...prevPreferences,
      lastMessageSeen: latestMessage
    }));
  };

  const updateNotificationSettings = (newSettings) => {
    setNotificationSettings(newSettings);
    setPreferences(prevPreferences => ({
      ...prevPreferences,
      notificationSettings: newSettings,
    }));
  };

  const toggleTheme = () => {
    setPreferences(prevPreferences => ({
      ...prevPreferences,
      theme: prevPreferences.theme === 'dark' ? 'light' : 'dark'
    }));
  };

  return (
    <UserPreferencesContext.Provider value={{ preferences, handleCurrencySelect, handleNodeSelect, toggleTheme, handleLastNotificationSeen, handleLastMessageSeen, updateNotificationSettings }}>
      {children}
    </UserPreferencesContext.Provider>
  );
};


const defaultNotificationPreferences = {
  users: {
    allUsers: { inApp: true, push: false },
    trustCircle: { inApp: true, push: true },
    following: { inApp: true, push: true },
    followers: { inApp: true, push: false },
    coinHolders: { inApp: true, push: true },
    coinHolding: { inApp: true, push: true },
    coinValue: { value: 0.5, inApp: true, push: true },
  },
  transactionTypes: {
    NEW_MESSAGE: { inApp: false, push: true },

    BASIC_TRANSFER: { inApp: true, push: true },
    DIRECT_TIP: { inApp: true, push: true },
    CREATOR_COIN: { inApp: true, push: true },
    CREATOR_COIN_TRANSFER: { inApp: true, push: true },

    DIAMONDLEVEL1: { inApp: true, push: false },
    DIAMONDLEVEL2: { inApp: true, push: true },
    DIAMONDLEVEL3: { inApp: true, push: true },

    REPLY: { inApp: true, push: true },
    QUOTE: { inApp: true, push: true },
    TAGGED: { inApp: true, push: true },
    REPOST: { inApp: true, push: true },
    reaction: { inApp: true, push: false },
    poll_response: { inApp: true, push: true },
    CREATE_POST_ASSOCIATION: { inApp: true, push: true },
    DELETE_POST_ASSOCIATION: { inApp: true, push: false },

    CREATE_USER_ASSOCIATION: { inApp: true, push: true },
    DELETE_USER_ASSOCIATION: { inApp: true, push: false },

    //PRIVATE_MESSAGE:  { inApp: true, push: true },
    //SUBMIT_POST: { inApp: true, push: true },
    
    FOLLOW: { inApp: true, push: false },
    LIKE: { inApp: true, push: false },
    CREATE_NFT: { inApp: true, push: false },
    UPDATE_NFT: { inApp: true, push: false },
    ACCEPT_NFT_BID: { inApp: true, push: true },
    NFT_BID: { inApp: true, push: true },
    NFT_TRANSFER: { inApp: true, push: true },
    ACCEPT_NFT_TRANSFER: { inApp: true, push: true },
    BURN_NFT: { inApp: true, push: false },
    MESSAGING_GROUP: { inApp: true, push: true },
    DAO_COIN: { inApp: true, push: true },
    DAO_COIN_TRANSFER: { inApp: true, push: true },
    DAO_COIN_LIMIT_ORDER: { inApp: true, push: true },

    //other_post_association: { inApp: true, push: true },

    // Are these needed?
    ACCESS_GROUP: { inApp: true, push: true },
    ACCESS_GROUP_MEMBERS: { inApp: true, push: true },
  }
};

export const transactionTypeLabels = {
  users: {
    allUsers: { label: "All users", tooltip: "Receive notifications from all users, excluding those you've blocked", icon: "people-fill" },
    trustCircle: { label: "Verified by Association", tooltip: "Users in your trust circle", icon: "patch-check-fill" },
    coinHolding: { label: "Coins I hold", tooltip: "Include coins that you hold", icon: "wallet-fill" },
    following: { label: "Users I follow", tooltip: "Include those you are following", icon: "person-check-fill" },
    followers: { label: "Users following me", tooltip: "Include users that  follow you", icon: "person-plus-fill" },
    coinHolders: { label: "Coin holders", tooltip: "Include holders of your creator coin", icon: "coin" },
    coinValue: { label: "CC price", tooltip: "Include other creators with coin price above this value", icon: "coin" },
  },
  transactionGroups: {
    "Messaging": {
      "NEW_MESSAGE": { "name": "Message", "label": "Direct Messages", "icon": "envelope-fill", "tooltip": "Receive notifications for direct messages" }
    },
    "Financial": {
      "BASIC_TRANSFER": { "name": "Transfer", "label": "Transfers", "icon": "cash-stack", "tooltip": "Receive notifications for transfers" },
      "DIRECT_TIP": { "name": "Tip", "label": "Direct Tips", "icon": "gift-fill", "tooltip": "Receive notifications for direct tips" },
      "CREATOR_COIN": { "name": "Coin", "label": "Creator Coin", "icon": "coin", "tooltip": "Receive notifications for creator coin transactions" },
      "CREATOR_COIN_TRANSFER": { "name": "CC Transfer", "label": "Coin Transfers", "icon": "coin", "tooltip": "Receive notifications for coin transfers" }
    },
    "Content": {
      "REPLY": { "name": "Reply", "label": "Replies to my posts", "icon": "chat-left-text-fill", "tooltip": "Receive notifications for replies to your posts" },
      "QUOTE": { "name": "Quote", "label": "Quote reposts", "icon": "quote", "tooltip": "Receive notifications for quoted reposts" },
      "TAGGED": { "name": "Tagged", "label": "Posts I'm tagged in", "icon": "at", "tooltip": "Receive notifications for posts you're tagged in" },
      "REPOST": { "name": "Repost", "label": "Reposts", "icon": "repeat", "tooltip": "Receive notifications for reposts" }
    },
    "Engagement": {
      "DIAMONDLEVEL1": { "name": "Diamonds", "label": "1 or more Diamonds", "icon": "gem", "tooltip": "Receive notifications for interactions with 1 diamond or greater" },
      "DIAMONDLEVEL2": { "name": "Diamonds", "label": "2 or more Diamonds", "icon": "gem", "tooltip": "Receive notifications for interactions with 2 diamonds or greater" },
      "DIAMONDLEVEL3": { "name": "Diamonds", "label": "3 or more Diamonds", "icon": "gem", "tooltip": "Receive notifications for interactions with 3 diamonds or greater" },
      "reaction": { "name": "Reactions", "label": "Reactions", "icon": "hand-thumbs-up", "tooltip": "Receive notifications for reactions" },
      "poll_response": { "name": "Poll", "label": "Poll Responses", "icon": "pie-chart-fill", "tooltip": "Receive notifications for poll responses" },
      "bookmark": { "name": "Bookmark", "label": "Bookmarks", "icon": "bookmark-star-fill", "tooltip": "Receive notifications for bookmarks" },
      "CREATE_POST_ASSOCIATION": { "name": "Association", "label": "Post Associations", "icon": "tags-fill", "tooltip": "Receive notifications for post associations" },
      "DELETE_POST_ASSOCIATION": { "name": "Association", "label": "Post Associations Deleted", "icon": "trash3-fill", "tooltip": "Receive notifications for deleted post associations" },
      "LIKE": { "name": "Likes", "label": "Legacy Likes", "icon": "heart-fill", "tooltip": "Receive notifications for legacy likes" },
      "FOLLOW": { "name": "Follows", "label": "Follows", "icon": "people-fill", "tooltip": "Receive notifications for follows" }
    },
    "Other": {
      "CREATE_NFT": { "name": "NFT", "label": "NFTs Minted", "icon": "image", "tooltip": "Receive notifications for minted NFTs" },
      "UPDATE_NFT": { "name": "NFT Update", "label": "NFTs Updated", "icon": "image", "tooltip": "Receive notifications for updated NFTs" },
      "ACCEPT_NFT_BID": { "name": "NFT Bid", "label": "NFT Bids Accepted", "icon": "handshake", "tooltip": "Receive notifications for accepted NFT bids" },
      "NFT_BID": { "name": "NFT Bid", "label": "NFT Bids Placed", "icon": "cursor", "tooltip": "Receive notifications for placed NFT bids" },
      "NFT_TRANSFER": { "name": "NFT", "label": "NFT Transfers", "icon": "arrows-move", "tooltip": "Receive notifications for NFT transfers" },
      "ACCEPT_NFT_TRANSFER": { "name": "NFT Transfer", "label": "NFT Transfers Accepted", "icon": "arrow-down-up", "tooltip": "Receive notifications for accepted NFT transfers" },
      "BURN_NFT": { "name": "NFT", "label": "NFTs Burnt", "icon": "fire", "tooltip": "Receive notifications for burnt NFTs" },
      "MESSAGING_GROUP": { "name": "Group", "label": "Messaging Group", "icon": "people", "tooltip": "Receive notifications for messaging group activities" },
      "DAO_COIN": { "name": "DAO Tokens", "label": "DAO Coin", "icon": "building", "tooltip": "Receive notifications for DAO coin transactions" },
      "DAO_COIN_TRANSFER": { "name": "DAO Transfer", "label": "DAO Coin Transfer", "icon": "arrow-up-down", "tooltip": "Receive notifications for DAO coin transfers" },
      "DAO_COIN_LIMIT_ORDER": { "name": "DAO Order", "label": "DAO Orders Placed", "icon": "graph-up", "tooltip": "Receive notifications for DAO orders placed" },
      "CREATE_USER_ASSOCIATION": { "name": "Association", "label": "User Associations", "icon": "person-plus", "tooltip": "Receive notifications for user associations" },
      "DELETE_USER_ASSOCIATION": { "name": "Association", "label": "User Associations Deleted", "icon": "trash3-fill", "tooltip": "Receive notifications for deleted user associations" },
      "ACCESS_GROUP": { "name": "Access Group", "label": "Access Group", "icon": "shield-lock", "tooltip": "Receive notifications for access group activities" },
      "ACCESS_GROUP_MEMBERS": { "name": "Group Member", "label": "Access Group Members", "icon": "people", "tooltip": "Receive notifications for access group member activities" }
    }
  }  
};

/*
export const transactionTypeLabels = {
  users: {
    allUsers: "All users",
    trustCircle: "Verified by my trust circle",
    following: "Users I follow",
    followers: "Users following me",
    coinHolders: "Coin holders",
    coinHolding: "Coins I hold",
    coinValue: "Coin value",
  },
  transactionTypes: {
    NEW_MESSAGE: "Direct Messages",

    BASIC_TRANSFER: "Transfers",
    DIRECT_TIP: "Direct Tips",
    CREATOR_COIN: "Creator Coin",
    CREATOR_COIN_TRANSFER: "Coin Transfers",

    DIAMONDLEVEL1: "1 Diamond or greater",
    DIAMONDLEVEL2: "2 Diamonds or greater",
    DIAMONDLEVEL3: "3 Diamonds or greater",

    //PRIVATE_MESSAGE:  "Direct Messages",
    REPLY: "Replies to my posts",
    QUOTE: "Quote reposts",
    TAGGED: "Posts I'm tagged in",
    REPOST: "Reposts",

    reaction: "Reactions",
    poll_response: "Poll Responses",
    other_post_association: "Other Post Associations",
    CREATE_POST_ASSOCIATION: "Post Associations",
    DELETE_POST_ASSOCIATION: "Post Associations Deleted",
    LIKE: "Legacy Likes",

    FOLLOW: "Follows",


    CREATE_NFT: "NFTs Minted",
    UPDATE_NFT: "NFTs Updated",
    ACCEPT_NFT_BID: "NFT Bids Accepted",
    NFT_BID: "NFT Bids Placed",
    NFT_TRANSFER: "NFT Transfers",
    ACCEPT_NFT_TRANSFER: "NFT Transfers Accepted",
    BURN_NFT: "NFTs Burnt",
    MESSAGING_GROUP: "Messaging Group",
    DAO_COIN: "DAO Coin",
    DAO_COIN_TRANSFER: "DAO Coin Transfer",
    DAO_COIN_LIMIT_ORDER: "DAO Orders Placed",
    CREATE_USER_ASSOCIATION: "User Associations",
    DELETE_USER_ASSOCIATION: "User Associations Deleted",
    
    ACCESS_GROUP: "Access Group",
    ACCESS_GROUP_MEMBERS: "Access Group Members",
  }
}*/