import React, { useState, useContext, useRef, useEffect } from 'react';
import { ERROR_TYPES, getVideoStatus, identity, pollForVideoReady, submitPost, uploadImage, uploadVideo } from "deso-protocol";
import { DeSoIdentityContext } from "react-deso-protocol";
import { deso_api } from '../utils/graphql';
import markdownToText from 'markdown-to-text';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import Turndown from 'turndown';
import { useUserPreferences } from '../contexts/UserPreferences';
import { localFees } from '../utils/transactions';
import formatPost, { getPosts } from '../utils/posts';
import { Loader, nl2br } from '../utils/helpers';
import Exif from 'exif-js';
import he from 'he';

export const MediaLibrary = ({ currentUser, preferences, toggleMediaLibrary, addMediaToPost, removeMediaFromPost, VideoURLs, ImageURLs }) => {
    const [endCursor, setEndCursor] = useState(false);
    const [search, setSearch] = useState(null);
    const [initialLoad, setInitialLoad] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [posts, setPosts] = useState([]);
    const [variables, setVariables] = useState({
        first: 12,
        orderBy: 'TIMESTAMP_DESC',
        filter: {
            and: [
                // Condition for posterPublicKey
                { "posterPublicKey": { "equalTo": currentUser.PublicKeyBase58Check } },
                {
                    or: [
                        // Condition for images
                        { "imageUrls": { "notEqualTo": null } },
                        { "imageUrls": { "notEqualTo": "" } },
                        // Condition for videos
                        { "videoUrls": { "notEqualTo": null } },
                        { "videoUrls": { "notEqualTo": "" } }
                    ]
                }
            ]
        }
    });
    
    
    const [hasNextPage, setHasNextPage] = useState(false);
    const ref = useRef(null);

    let typingTimeout;

    const handleChange = (e) => {
        //console.log("[modalCompose.jsx] <MediaLibrary  handleChange e => ", e);
        if (!e.target) { return; }
        const { name, value } = e.target;
        //console.log("[modalCompose.jsx] <MediaLibrary handleChange: ", name, value);

        if (name === "search") {
            // Clear previous timeout if it exists
            clearTimeout(typingTimeout);

            // Set a new timeout to trigger action after 500ms
            typingTimeout = setTimeout(() => {
                if(value) {
                    setSearch(value);    
                } else {
                    setSearch(null);
                }
            }, 500);
        }
    };
    
    useEffect(() => {
        if(initialLoad) { 
            return; 
        } else {
            setInitialLoad(true);
            if(!isLoading) {
                getVariablesForFeed();
                fetchData();
            }
        }
    }, []);

    useEffect(() => {
        if(!isLoading) {
            getVariablesForFeed();
            fetchData();
        }
    }, [search]);
    
    const fetchData = async (after = null) => {
        if(isLoading) { return; }
        try {
            setIsLoading(true);
            // Clone the variables to avoid mutating the original object
            let variablesCopy = { ...variables };
    
            // Adjustments to set the after cursor properly
            if (after) {
                variablesCopy.after = after;
            } else {
                setPosts([]); // Clear posts only when after is null
            }
    
            //console.log("[modalCompose.jsx] <MediaLibrary variables:",variablesCopy);
            const postData = await getPosts(variablesCopy);
            //console.log("[modalCompose.jsx] <MediaLibrary postData:",postData);
            if (postData && postData.nodes) {
                //const formattedData = await formatPosts(postData.nodes, currentUser);
                //console.log("[modalCompose.jsx] <MediaLibrary formattedData:",formattedData);
                setPosts(prevPosts => [...prevPosts, ...postData.nodes]);
    
                if (postData.pageInfo) {
                    setHasNextPage(postData.pageInfo.hasNextPage);
                    // Set the end cursor if it exists
                    if (postData.pageInfo.endCursor) {
                        setEndCursor(postData.pageInfo.endCursor);
                    }
                }
            }
        } catch (error) {
            console.error("Error fetching posts:", error);
        } finally {
            setIsLoading(false);
        }
    };
    
    const loadMore = () => {
        fetchData(endCursor);
    };
    

    const formatPosts = (posts, currentUser) => {
        return posts.map((post, index) => {
            let postContent = [];

            if (post.PostFound.ImageURLs && post.PostFound.ImageURLs.length > 0) {
                post.PostFound.ImageURLs.forEach((url, i) => {
                    postContent.push(
                        <div key={`img-${index}-${i}`} className="col-6 col-lg-4 col-xl-3 mb-3 position-relative">
                            <div className="ratio ratio-4x3 overflow-hidden">
                                <img className="object-fit-cover rounded" src={url} alt={`Image ${index}`} />
                            </div>
                            <span
                                className={`btn btn-sm position-absolute top-0 start-0 mt-3 ms-4 ${ImageURLs.includes(url) ? 'btn-success' : 'btn-secondary'}`}
                                onClick={() => addMediaToPost(url, 'image')}
                            >
                                {ImageURLs && ImageURLs.includes(url) ? <i className="bi bi-check"></i> : <i className="bi bi-plus-square-fill"></i>}
                            </span>
                        </div>
                    );
                    
                });
            }

    
            if (post.PostFound.VideoURLs && post.PostFound.VideoURLs.length > 0) {
                post.PostFound.VideoURLs.forEach((url, i) => {
                    postContent.push(
                        <div key={index} className="col-6 col-lg-4 col-xl-3 mb-3 position-relative">
                            {/* Image thumbnail */}
                            <div className="ratio ratio-4x3 overflow-hidden">
                                <iframe
                                    title={`Video ${index}`}
                                    allow="fullscreen; picture-in-picture;"
                                    frameBorder="0"
                                    className="img-fluid object-fit-cover rounded"
                                    key={`video-${index}-${i}`} src={`${url}&autoplay=false&muted=false&mode=thumbnail`}
                                    style={{ width: "100%" }}
                                />
                            </div>
                            <span
                                className="btn btn-sm btn-secondary position-absolute top-0 start-0 mt-3 ms-4"
                                onClick={() => addMediaToPost(url, 'video')}
                            >
                                <i className="bi bi-plus-square-fill"></i>
                            </span>
                        </div>
                    );
                });
            }
    
            return postContent;
        });
    };
       

    const getVariablesForFeed = () => {
        let updatedVariables = { ...variables };

        // Clear any existing search filter
        updatedVariables.filter.and = updatedVariables.filter.and.filter(condition => {
            return !('body' in condition);
        });
        // Add search filter if search is not empty
        if (search && search !== '') {
            updatedVariables.filter.and.push({ "body": { "includesInsensitive": search } });
        }

        setVariables(updatedVariables);
    };

    return (
        <div key={ref} className={`modal fade modal-fullscreen-sm-down show`} id="modalMediaLibrary" tabIndex="-1" aria-modal="true" style={{ zIndex: "12500" }}>
            <div className="modal-dialog modal-xl cascading-modal mt-5" role="document">
                <div className="modal-content rounded-3 mediaLibrary" id="modalMediaLibraryBody">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <i className="bi bi-collection-fill me-3"></i>
                            Add media from your library...
                        </h5>
                        <span type="button" class="btn-close" onClick={toggleMediaLibrary} aria-label="Close"></span>
                    </div>
                    <div className="modal-body">
                        <div className='row mb-3'>
                            <input type="text" className="form-control bg-background border-secondary" id="searchFormValue" name="search" aria-label="Search value" placeholder="I'm looking for..." value={variables.search} onChange={handleChange}/>
                        </div>
                        <div className="row">
                            {posts.flatMap((post, index) => {
                                let postContent = [];
    
                                if (post.PostFound.ImageURLs && post.PostFound.ImageURLs.length > 0) {
                                    post.PostFound.ImageURLs.forEach((url, i) => {
                                        postContent.push(
                                            <div key={`img-${index}-${i}`} className="col-6 col-lg-4 col-xl-3 mb-3 position-relative">
                                                <div className="ratio ratio-4x3 overflow-hidden">
                                                    <img className="object-fit-cover rounded" src={url} alt={`Image ${index}`} />
                                                </div>
                                                <span
                                                    className={`btn btn-sm position-absolute top-0 start-0 mt-3 ms-4 ${ImageURLs && ImageURLs.includes(url) ? 'btn-danger' : 'btn-secondary'}`}
                                                    onClick={() => {
                                                        if (ImageURLs && ImageURLs.includes(url)) {
                                                            removeMediaFromPost(url, 'image');
                                                        } else {
                                                            addMediaToPost(url, 'image');
                                                        }
                                                    }}
                                                >
                                                    {ImageURLs && ImageURLs.includes(url) ? <i className="bi bi-x-lg"></i> : <i className="bi bi-paperclip"></i>}
                                                </span>
                                            </div>
                                        );
                                    });
                                }
    
                                if (post.PostFound.VideoURLs && post.PostFound.VideoURLs.length > 0) {
                                    post.PostFound.VideoURLs.forEach((url, i) => {
                                        postContent.push(
                                            <div key={`video-${index}-${i}`} className="col-6 col-lg-4 col-xl-3 mb-3 position-relative">
                                                <div className="ratio ratio-4x3 overflow-hidden">
                                                    <iframe
                                                        title={`Video ${index}`}
                                                        allow="fullscreen; picture-in-picture;"
                                                        frameBorder="0"
                                                        className="img-fluid object-fit-cover rounded"
                                                        key={`video-${index}-${i}`}
                                                        src={`${url}&autoplay=false&muted=false&mode=thumbnail`}
                                                        style={{ width: "100%" }}
                                                    />
                                                </div>
                                                <span
                                                    className={`btn btn-sm position-absolute top-0 start-0 mt-3 ms-4 ${VideoURLs && VideoURLs.includes(url) ? 'btn-danger' : 'btn-secondary'}`}
                                                    onClick={() => {
                                                        if (VideoURLs && VideoURLs.includes(url)) {
                                                            removeMediaFromPost(url, 'video');
                                                        } else {
                                                            addMediaToPost(url, 'video');
                                                        }
                                                    }}
                                                >
                                                    {VideoURLs && VideoURLs.includes(url) ? <i className="bi bi-trash"></i> : <i className="bi bi-plus-square-fill"></i>}
                                                </span>
                                            </div>
                                        );
                                    });
                                }
    
                                return postContent;
                            })}
                        </div>
                        {hasNextPage && !isLoading && (
                            <div className="col-12 text-center mt-3 mb-3">
                                <span id="loadMoreButton" className="btn btn-primary" onClick={loadMore} data-id={endCursor}>
                                    Load More
                                </span>
                            </div>
                        )}
                        {isLoading && <Loader /> }
                    </div>
                    <div class="modal-footer">
                        <span type="button" class="btn btn-success" onClick={toggleMediaLibrary}>Finished</span>
                    </div>
                </div>
            </div>
        </div>
    );   
}

export const MediaAttachments = ({
    ImageURLs,
    VideoURLs,
    quill,
    handleInsertImage,
    handleDeleteImage,
    handleInsertVideo,
    handleDeleteVideo,
    queuedFiles,
    showEmbedInput,
    embedUrl,
    setEmbedUrl,
    handleInsertEmbed,
    handleDeleteEmbed,
    handleEmbedInputChange,
    toggleMediaLibrary,
    handleButtonClick,
    inputFileRef,
    handleFileChange,
    submitError,
    submitSuccess,
    isSubmitting,
    handleSubmit,
    setImageURLs,
    setVideoURLs,
    setQueuedFiles,
    mediaInfo,
    setMediaInfo,
    postType,
    tab
}) => {
    const ref = useRef(null);
    const sortedQueuedFiles = queuedFiles.sort((a, b) => {
        if (a.status.processing && !b.status.processing) return -1;
        if (!a.status.processing && b.status.processing) return 1;
        if (a.status.uploading && !b.status.uploading) return -1;
        if (!a.status.uploading && b.status.uploading) return 1;
        if (a.status.error && !b.status.error) return 1;
        if (!a.status.error && b.status.error) return -1;
        return 0;
    });
    return (
        <div key={ref}>
            <div className='row'>
                {postType === "formatted" && tab === "content" && ((ImageURLs && ImageURLs.length > 0) || (VideoURLs && VideoURLs.length > 0)) ? (
                    <div className='col-12 small text-muted'>
                        <p>
                            <i className='bi bi-info-circle me-2'></i>
                            Insert and position attachments within formatted posts - or leave them attached to be displayed as standard attachments. Nodes that do not support formatted posts will display plain text with standard attached media.
                        </p>
                    </div>
                ) : (
                    <>
                    { tab === 'attachments' && ImageURLs.length < 1 && VideoURLs.length < 1 && (
                        <div className='col-12 small text-muted py-5 text-center'>
                            <p>
                                <i className='fs-1 bi bi-image me-2'></i><br/>
                                Upload media or select from your library to make a media-only post
                            </p>
                        </div>
                    )}
                    </>
                )}
            </div>
            <div className='row'>
                {ImageURLs && ImageURLs.length > 0 ? (
                <>
                    {ImageURLs.map((ImageURL, index) => (
                    <div key={index} className={`${tab === 'attachments' ? `col-12 col-sm-6 col-md-4 col-xl-4` : `col-6 col-sm-4 col-lg-3`} mb-3`}>
                        <div className="ratio ratio-4x3 overflow-hidden">
                            <img className="ratio-4x3 object-fit-cover rounded" src={ImageURL} alt={`Image ${index}`} />
                            <div className="position-absolute top-0 start-0 w-100 h-100">
                                <div className='position-absolute top-0 start-0 ms-1'>
                                    {ImageURLs.length > 1 && (
                                        <>
                                            <span
                                                className={`btn btn-sm btn-secondary m-2 mx-1 ${index === 0 ? 'disabled' : ''}`}
                                                disabled={index === 0}
                                                onClick={() => moveAttachmentUp(index, setImageURLs)}
                                            >
                                                <i className="bi bi-arrow-up"></i>
                                            </span>
                                            <span
                                                className={`btn btn-sm btn-secondary m-2 mx-1 ${index === ImageURLs.length - 1 ? 'disabled' : ''}`}
                                                disabled={index === ImageURLs.length - 1}
                                                onClick={() => moveAttachmentDown(index, setImageURLs)}
                                            >
                                                <i className="bi bi-arrow-down"></i>
                                            </span>
                                        </>
                                    )}
                                    {tab === "attachments" && (
                                        <span
                                            className="btn btn-sm btn-secondary m-2 mx-1"
                                        >
                                            <i className="bi bi-file-text-fill"></i> Edit Meta
                                        </span>
                                    )}
                                </div>
                                <span
                                    className="btn btn-sm btn-danger position-absolute top-0 end-0 m-2"
                                    onClick={() => handleDeleteImage(index, setImageURLs)}
                                >
                                    <i className="bi bi-trash-fill"></i>
                                </span>
                                {postType === "formatted" && tab === "content" && (
                                        <span
                                            className="position-absolute bottom-0 end-0 btn btn-sm btn-secondary m-2"
                                            onClick={(event) => handleInsertImage(ImageURL, event, quill)}
                                        >
                                            Insert <i className="ms-1 bi bi-plus-square-fill"></i>
                                        </span>
                                )}
                                {tab === "attachments" ? (
                                    <>
                                        {mediaInfo[ImageURL] && (
                                            <div className="position-absolute bottom-0 start-0 w-100 bg-body bg-gradient bg-opacity-25 overlay imageCaption">
                                                (Metadata in test - currently no effect)
                                                {mediaInfo[ImageURL].Title && <p className="title">{mediaInfo[ImageURL].Title}</p>}
                                                {mediaInfo[ImageURL].Artist && <p>{mediaInfo[ImageURL].Artist}</p>}
                                                {mediaInfo[ImageURL].Copyright && <p>{mediaInfo[ImageURL].Copyright}</p>}
                                                {mediaInfo[ImageURL].Model && (
                                                    <ul className='list-inline camerainfo'>
                                                        <li class="list-inline-item">
                                                            <i className="bi bi-camera2 me-2"></i>
                                                            {mediaInfo[ImageURL].Make && <span className='me-1'>{mediaInfo[ImageURL].Make}</span> } 
                                                            {mediaInfo[ImageURL].Model}
                                                        </li>
                                                        {mediaInfo[ImageURL].ISOSpeedRatings && <li class="list-inline-item">ISO {`${mediaInfo[ImageURL].ISOSpeedRatings}`}</li>}
                                                        {mediaInfo[ImageURL].FocalLength && (
                                                            <li className="list-inline-item">
                                                                {mediaInfo[ImageURL].FocalLength.denominator === 1
                                                                    ? `${mediaInfo[ImageURL].FocalLength.numerator}`
                                                                    : `${(mediaInfo[ImageURL].FocalLength.numerator / mediaInfo[ImageURL].FocalLength.denominator).toFixed(1)}`} mm
                                                            </li>
                                                        )}
                                                        {mediaInfo[ImageURL].FNumber && <li class="list-inline-item">f/{`${mediaInfo[ImageURL].FNumber}`}</li>}
                                                        {mediaInfo[ImageURL].ExposureTime && (
                                                            <li className="list-inline-item">
                                                                <i className="bi bi-stopwatch-fill me-2"></i>
                                                                {mediaInfo[ImageURL].ExposureTime >= 1
                                                                    ? `${mediaInfo[ImageURL].ExposureTime} s`
                                                                    : (mediaInfo[ImageURL].ExposureTime.numerator / mediaInfo[ImageURL].ExposureTime.denominator) < (1 / 10000)
                                                                        ? `1/10000 s`
                                                                        : `1/${Math.round(mediaInfo[ImageURL].ExposureTime.denominator / mediaInfo[ImageURL].ExposureTime.numerator)} s`
                                                                }
                                                            </li>
                                                        )}
                                                    </ul>
                                                )}
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <span
                                        className="btn btn-sm btn-secondary bg-opacity-75 position-absolute bottom-0 start-0 mb-2 ms-2"
                                    >
                                        <i className="bi bi-file-text-fill"></i>
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                ))}

                </>
                ) : null }
                {VideoURLs && VideoURLs.length > 0 ? (
                <>
                    {VideoURLs.map((VideoURL, index) => (
                        <div key={index} className={`${tab === 'attachments' ? `col-12 col-lg-6 col-xl-4` : `col-6 col-sm-4 col-lg-4`} mb-3 position-relative`}>
                            {/* Image thumbnail */}
                            <div className="ratio ratio-16x9 overflow-hidden">
                                <iframe
                                    title={`Video ${index}`}
                                    allow="fullscreen; picture-in-picture;"
                                    frameBorder="0"
                                    className="img-fluid object-fit-cover rounded"
                                    src={`${VideoURL}&autoplay=false&muted=false&mode=thumbnail`}
                                    style={{ width: "100%" }}
                                />
                            </div>
                            <div className='position-absolute top-0 start-0 ms-1'>
                                {VideoURLs.length > 1 && (
                                    <>
                                        <span
                                            className={`btn btn-sm btn-secondary m-2 mx-1 ${index === 0 ? 'disabled' : ''}`}
                                            disabled={index === 0}
                                            onClick={() => moveAttachmentUp(index, setVideoURLs)}
                                        >
                                            <i className="bi bi-arrow-up"></i>
                                        </span>
                                        <span
                                            className={`btn btn-sm btn-secondary m-2 mx-1 ${index === VideoURLs.length - 1 ? 'disabled' : ''}`}
                                            disabled={index === VideoURLs.length - 1}
                                            onClick={() => moveAttachmentDown(index, setVideoURLs)}
                                        >
                                            <i className="bi bi-arrow-down"></i>
                                        </span>
                                    </>
                                )}
                                {tab === "attachments" && (
                                    <span
                                        className="btn btn-sm btn-secondary m-2 mx-1"
                                    >
                                        <i className="bi bi-file-text-fill"></i> Edit Meta
                                    </span>
                                )}
                            </div>
                            <span
                                className="btn btn-sm btn-danger position-absolute top-0 end-0 m-3"
                                onClick={() => handleDeleteImage(index, setVideoURLs)}
                            >
                                <i className="bi bi-trash-fill"></i>
                            </span>
                            {postType === "formatted" && tab === "content" && (
                                    <span
                                        className="position-absolute bottom-0 end-0 btn btn-sm btn-secondary m-2"
                                        onClick={(event) => handleInsertVideo(VideoURL, event, quill)}
                                    >
                                        Insert <i className="ms-1 bi bi-plus-square-fill"></i>
                                    </span>
                            )}
                        </div>
                    ))}
                </>
                ) : null }

                {sortedQueuedFiles.map((queuedFile, index) => {
                //console.log("[compose.js] Outputting Queued File: ", queuedFile);
                const isImage = queuedFile.file.type.startsWith('image');
                
                return (
                    <>
                    {queuedFile.status.completed !== true && (!queuedFile.status.error || queuedFile.status === '') ? (
                    <div key={index} className={`${tab === 'attachments' ? `col-12 col-sm-6 col-md-4 col-xl-4` : `col-6 col-sm-4 col-lg-3`} mb-3 position-relative`}>
                        <div className="border rounded ratio ratio-4x3 overflow-hidden">
                            <div className="position-absolute top-0 start-0 end-0 bottom-0 d-flex align-items-center justify-content-center">
                                <div className='text-center'>
                                    <div className='lh-1'>
                                        {queuedFile.file.type.startsWith('image') ? (
                                            <i className='bi bi-image'></i>
                                        ) : (
                                            <i className='bi bi-camera-video'></i>
                                        )}
                                        <br/>
                                        <small>
                                            {queuedFile.status.uploading === true ? (
                                                ' Uploading'
                                            ) : (
                                                <>
                                                {queuedFile.status.processing === true ? (
                                                    ' Processing'
                                                ) : (
                                                    ' Queued'
                                                )}
                                                </>
                                            )}
                                            {queuedFile.file.type.startsWith('image') ? (
                                                ' Image'
                                            ) : (
                                                ' Video'
                                            )}
                                        </small>
                                        <br/>
                                        {queuedFile.status.uploading === true || queuedFile.status.processing === true ? (
                                        <>
                                            <div className="spinner-border text-muted mt-3" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                            <br/>
                                        </>
                                    ) : ('')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    ) : ( 
                        <>
                        <div key={index} className={`${tab === 'attachments' ? `col-12 col-sm-6 col-md-4 col-xl-4` : `col-6 col-sm-4 col-lg-3`} mb-3 position-relative`}>
                            <div className="border border-danger rounded ratio ratio-4x3 overflow-hidden">
                                <div className="position-absolute top-0 start-0 end-0 bottom-0 d-flex align-items-center justify-content-center">
                                    <div className='text-center small lh-l'>
                                        <div className='lh-1 text-danger'>
                                            {queuedFile.file.type.startsWith('image') ? (
                                                <i className='bi bi-image text-danger'></i>
                                            ) : (
                                                <i className='bi bi-camera-video text-danger'></i>
                                            )}
                                        </div>
                                        <div className='p-1 lh-l small'>
                                            <>
                                            {nl2br(queuedFile.status.error)}
                                            </>
                                        </div>
                                        {tab === 'attachments' && (
                                            <>
                                                <div className='text-muted small mx-4 word-break text-truncate'>
                                                    {queuedFile.file.name}
                                                </div>
                                                <div className='text-muted small p-1'>
                                                {queuedFile.file.type.startsWith('image') ? (
                                                    <p><i className="bi bi-info-circle-fill"></i> Images must be less than 10 MB and be in gif, jpg, png or webp format.</p>
                                                ) : (
                                                    <p><i className="bi bi-info-circle-fill"></i> Videos must be less than 250 MB and 150 seconds in duration, in quicktime, mp4 or wmv format.</p>
                                                )}
                                                </div>
                                            </>
                                        )}
                                        <span
                                            className="btn btn-sm btn-danger position-absolute top-0 end-0 m-2 mx-2"
                                            onClick={() => handleDismissError(index, setQueuedFiles)}
                                        >
                                            <i className="bi bi-x-lg"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </>
                    ) }
                    </>
                )})}
            </div>
            <div className='row'>
                {showEmbedInput && (
                    <>
                        {embedUrl ? (
                            <div className="col-12 mb-3 position-relative">
                                <div className="overflow-hidden">
                                    <iframe 
                                        src={embedUrl} 
                                        style={{ 
                                            height: "152px",
                                            width: "100%",
                                            frameBorder: "0",
                                            backgroundColor: "none !important",
                                            borderRadius: "1em"
                                        }}
                                        allow="encrypted-media" 
                                        allowFullScreen
                                    ></iframe>
                                </div>
                                { postType === "formatted" && (
                                    <span
                                        className="btn btn-sm btn-secondary position-absolute top-0 start-0 mt-3 ms-4"
                                        onClick={(event) => handleInsertEmbed(embedUrl, event, quill)}
                                    >
                                        <i className="bi bi-plus-square-fill"></i>
                                    </span>
                                )}
                                <span
                                    className="btn btn-sm btn-danger position-absolute top-0 end-0 m-3"
                                    onClick={() => handleDeleteEmbed(setEmbedUrl)}
                                >
                                    <i className="bi bi-trash-fill"></i>
                                </span>
                            </div>
                            
                        ) : (
                        <div className='col-12'>
                            <input
                                type="text"
                                className='form-control'
                                value={embedUrl}
                                onChange={handleEmbedInputChange}
                                placeholder="Attach embed from youtube, tiktok, giphy, spotify, mousai, soundcloud etc."
                            />
                        </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export const PostButtons = ({
    toggleMediaLibrary,
    handleButtonClick,
    inputFileRef,
    handleFileChange,
    toggleEmbedInput,
    submitError,
    submitSuccess,
    isSubmitting,
    handleSubmit,
    savePost,
    options = true,
    label
}) => {
    const ref = useRef(null);
    return (
        <>
        <div key={ref} className="d-flex flex-row flex-nowrap justify-content-between m-0 p-0">
            {options && (
                <div className='flex-fill btn-group btn-group-justified'>
                    {/* Button to toggle media library */}
                    <span onClick={toggleMediaLibrary} className="btn action h-100">
                        <i className="bi bi-collection-fill"></i>
                    </span>
                    {/* Button to handle file upload */}
                    <span onClick={() => handleButtonClick(inputFileRef)} className="btn action h-100">
                        <i className="bi bi-images"></i>
                    </span>
                    <input
                        type="file"
                        accept="image/*, video/*" // Accept both image and video files
                        id={inputFileRef}
                        multiple // Allow multiple file selection
                        ref={inputFileRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                    {/* Button to toggle embed input */}
                    <span className="btn  action rounded-0 h-100" type="button" onClick={toggleEmbedInput}>
                        <i className="bi bi-link"></i>
                    </span>
                    {/* Other buttons */}
                    <span className="btn  action rounded-0 h-100" type="button">
                        <i className="bi bi-cloud-arrow-up"></i>
                    </span>
                    { savePost && (
                        <span className="btn  action rounded-0 h-100 d-flex flex-row flex-nowrap justify-content-center" type="button">
                            <i className="bi bi-floppy-fill me-2"></i>
                            Save
                        </span>
                    )}
                </div>
            )}
            <span
                type="submit"
                name="submit"
                className={`flex-fill col-3 d-flex flex-row flex-nowrap justify-content-center btn ${submitError ? 'btn-danger border-danger' : (submitSuccess ? 'btn-success border-success' : 'btn-primary action')}`}
                onClick={(e) => handleSubmit(e)}
                disabled={isSubmitting}
            >
                {isSubmitting ? (
                    <>
                        <div className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></div>
                        Sending...
                    </>
                ) : (
                    <>
                        {submitError ? (
                            <>
                                Retry<i className='small bi bi-info-circle-fill ms-1'></i>
                            </>
                        ) : (
                            submitSuccess ? (
                                <>
                                    <i className="bi bi-send-check-fill me-1"></i>Sent
                                </>
                            ) : (
                                <>
                                    <i className="bi bi-send-fill me-1"></i>{ label ? label : `Send`}
                                </>
                            )
                        )}
                    </>
                )}
            </span>
        </div>
        </>
    );
};

export const PreviewPost = ({ currentUser, alternateUsers, preferences, postType, ImageURLs, VideoURLs, mediaInfo, quill, userLocale, formData, embedUrl, tab }) => {
    const [preview, setPreview] = useState(null);
    
    useEffect(() => {
      const processPost = async () => {
        //console.log("processPost, formData: ", formData);
        let htmlContent = '';
        let plainText = '';
        let markdownContent = '';
        let delta = {};
        let outputType = 'post';
  
        if (quill && (postType === 'formatted' || postType === 'blog')) {
          delta = quill.getContents();
          console.log("[modalCompose.js] delta: ",delta);
          const converter = new QuillDeltaToHtmlConverter(delta.ops, {});
          htmlContent = converter.convert();
          const turndown = new Turndown();
          markdownContent = turndown.turndown(htmlContent);
          plainText = he.decode(htmlContent)
            .replace(/<p\s*\/?>/gi, '\n\n') // Insert two new lines after opening <p> tag
            .replace(/<h\d\s*\/?>/gi, '\n\n') // Insert two new lines after opening <h1>, <h2>, etc. tags
            .replace(/<br\s*\/?>/gi, '\n') // Replace <br> with a single new line
            .replace(/<blockquote\s*\/?>/gi, '\n\n') // Insert two new lines after opening <blockquote> tag
            .replace(/<\/?[^>]+>/g, '') // Remove all other HTML tags
            .replace(/^\s*[\n\r]/, '') // Remove leading new lines
            .replace(/[\n\r]\s*$/, ''); // Remove trailing new lines
        }
  
        const currentTimeMillis = Date.now();
        const currentTimeNanos = currentTimeMillis * 1e6;
        const timeMinus5SecondsNanos = currentTimeNanos - 5 * 1e9;

        const post = {
          PosterPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
          ProfileEntryResponse: currentUser.ProfileEntryResponse,
          ImageURLs: ImageURLs,
          VideoURLs: VideoURLs,
          PostExtraData: {
            Language: userLocale,
          },
          LikedByReader: null,
          RepostedByReader: null,
          "PostHashHex": null,
            "ParentStakeID": "",
            "TimestampNanos": timeMinus5SecondsNanos,
            "IsHidden": false,
            "ConfirmationBlockHeight": null,
            "InMempool": false,
            "Comments": null,
            "LikeCount": 0,
            "DiamondCount": 0,
            "PostEntryReaderState": {
                "LikedByReader": false,
                "DiamondLevelBestowed": 0,
                "RepostedByReader": false,
                "RepostPostHashHex": ""
            },
            "IsPinned": false,
            "CommentCount": 0,
            "RepostCount": 0,
            "QuoteRepostCount": 0,
            "ParentPosts": [],
            "IsNFT": false,
            "IsFrozen": false,
            "NumNFTCopies": 0,
            "NumNFTCopiesForSale": 0,
            "NumNFTCopiesBurned": 0,
            "HasUnlockable": false,
            "NFTRoyaltyToCreatorBasisPoints": 0,
            "NFTRoyaltyToCoinBasisPoints": 0,
            "AdditionalDESORoyaltiesMap": {},
            "AdditionalCoinRoyaltiesMap": {},
            "DiamondsFromSender": 0,
            "HotnessScore": 0,
            "PostMultiplier": 0,
            "RecloutCount": 0,
            "QuoteRecloutCount": 0,
        };

        if(formData.postTitle) {
            post.PostExtraData.Title = formData.postTitle;
        }

        if (delta && Object.keys(delta).length > 0) {
            post.PostExtraData.BlogDeltaRtfFormat = JSON.stringify(delta);
        }

        if(formData && formData.RepostedPostEntryResponse) {
            post.RepostedPostEntryResponse = formData.RepostedPostEntryResponse;
        }
        if( formData && formData.body !== '') {
            post.Body = formData.body ? formData.body.trim() : null;
            outputType = 'post';
        } else {
            outputType = 'post';
        }
  
        if (embedUrl) {
          post.PostExtraData.EmbedVideoURL = embedUrl;
        }
        if(mediaInfo) {
          post.PostExtraData.mediaInfo = mediaInfo;
        }

        console.log("[modalCompose.js] preview post", post);
        //console.log("mediaInfo", mediaInfo);
        const preview = await formatPost(post, currentUser, 0, outputType, 'list', null, preferences, false, true, alternateUsers, false);
        setPreview(preview);
      };
  
      if(formData) { processPost(); }
    }, [postType, quill, currentUser, ImageURLs, VideoURLs, mediaInfo, userLocale, formData, embedUrl]);
  
    return <>{preview}</>;
  };

/*******
 * Shared Functions
 */

export const handleChange = (e, formData, setFormData) => {
    //console.log("[compose.js] handleChange() : ",e,FormData);
    if(!e) {
        return;
    } else {
        const { name, value } = e.target;
        //console.log("[compose.js] handleChange() name, value ",name,value);
        setFormData({ ...formData, [name]: value });
    }
};

export const handleFileChange = async (event, setQueuedFiles, ImageURLs, setImageURLs, VideoURLs, setVideoURLs, mediaInfo, setMediaInfo) => {
    event.preventDefault();

    if (!event.target.files && !event.dataTransfer.files) { return; }
    let files;

    if(event.target.files)  { files = event.target.files; }
    else if (event.dataTransfer.files) { files = event.dataTransfer.files; }

    console.log("[compose.js] handleFileChange > File Upload: ", files);

    try {
        // Iterate through files and add them to the queue
        for (const file of files) {
            //console.log("[compose.js] handleFileChange > detected file: ", file);
            if (file.type.startsWith('image/') || file.type.startsWith('video/')) {
                setQueuedFiles(prevQueuedFiles => [
                    ...prevQueuedFiles,
                    { file: file, status: { uploading: true, completed: false, processing: false, error: null, file: file } }
                ]);
                //console.log("[compose.js] handleFileChange > ... added to queue: ", file);
            } else {
                // Unsupported file type
                console.error('[compose.js] handleFileChange > Unsupported file type:', file.type);
            }
        }
    } catch (error) {
        console.error('[compose.js] handleFileChange > Error handling file change:', error);
        // Update status for all queued files in case of error
        setQueuedFiles(prevQueuedFiles => prevQueuedFiles.map(qFile => {
            return { ...qFile, status: { uploading: false, completed: false, processing: false, error: error.message } };
        }));
    }

    //console.log("[compose.js] handleFileChange > Finished handling new media: ", ImageURLs, VideoURLs);
};

export const extractMetadata = (file) => {
    return new Promise((resolve, reject) => {
        Exif.getData(file, function() {
            const allTags = Exif.getAllTags(this);
            const metadata = {};
            //console.log("[extractMetadata] all properties: ", allTags);

            // Check for and log the exifdata and iptcdata properties
            const exifData = this.exifdata || {};
            const iptcData = this.iptcdata || {};

            //console.log("[extractMetadata] exifData: ", exifData);
            //console.log("[extractMetadata] iptcData: ", iptcData);

            // EXIF first

            metadata['filename'] = file.name;
            metadata['Title'] = typeof exifData.ImageDescription === 'string' ? exifData.ImageDescription.trim() : exifData.ImageDescription;
            metadata['Artist'] = typeof exifData.Artist === 'string' ? exifData.Artist.trim() : exifData.Artist;
            metadata['Copyright'] = typeof exifData.Copyright === 'string' ? exifData.Copyright.trim() : exifData.Copyright;

            // Camera Info
            metadata['ApertureValue'] = typeof exifData.ApertureValue === 'string' ? exifData.ApertureValue.trim() : exifData.ApertureValue;
            metadata['MaxApertureValue'] = typeof exifData.MaxApertureValue === 'string' ? exifData.MaxApertureValue.trim() : exifData.MaxApertureValue;
            metadata['FNumber'] = typeof exifData.FNumber === 'string' ? exifData.FNumber.trim() : exifData.FNumber;
            metadata['ExposureTime'] = typeof exifData.ExposureTime === 'string' ? exifData.ExposureTime.trim() : exifData.ExposureTime;
            metadata['FocalLength'] = typeof exifData.FocalLength === 'string' ? exifData.FocalLength.trim() : exifData.FocalLength;
            metadata['ISOSpeedRatings'] = typeof exifData.ISOSpeedRatings === 'string' ? exifData.ISOSpeedRatings.trim() : exifData.ISOSpeedRatings;
            metadata['Make'] = typeof exifData.Make === 'string' ? exifData.Make.trim() : exifData.Make;
            metadata['Model'] = typeof exifData.Model === 'string' ? exifData.Model.trim() : exifData.Model;
            if (typeof metadata['Make'] === 'string' && typeof metadata['Model'] === 'string') {
                if (metadata['Model'].startsWith(metadata['Make'])) {
                    metadata['Model'] = metadata['Model'].substring(metadata['Make'].length).trim();
                }
            }
        
            metadata['DateTime'] = typeof exifData.DateTime === 'string' ? exifData.DateTime.trim() : exifData.DateTime;
            
            // Original dimensions not necessarily useful
            // save an aspect instead? e.g. portrait, landscape or ratio 1x1, 16x9, 4x3 etc.
            metadata['Orientation'] = typeof exifData.Orientation === 'string' ? exifData.Orientation.trim() : exifData.Orientation;
            metadata['PixelXDimension'] = typeof exifData.PixelXDimension === 'string' ? exifData.PixelXDimension.trim() : exifData.PixelXDimension;
            metadata['PixelYDimension'] = typeof exifData.PixelYDimension === 'string' ? exifData.PixelYDimension.trim() : exifData.PixelYDimension;

            
            // IPTC data
            // captions, author, copyright (if set) should override EXIF values
            if (iptcData.caption) {
                metadata['Title'] = iptcData.caption;
            }
            if (iptcData.copyright) {
                metadata['Copyright'] = iptcData.copyright;
            }
            if (iptcData.keywords) {
                metadata['Keywords'] = iptcData.keywords;
            }

            // Location
            // user preferences possibly useful here - whether or not to include location

            //console.log("[extractMetadata] processed return: ", metadata);
            resolve(metadata);
        });
    });
};

export const moveAttachmentUp = (index, setURLs) => {
    setURLs(prevURLs => {
        if (index === 0) return prevURLs; // already at the top
        const newURLs = [...prevURLs];
        [newURLs[index - 1], newURLs[index]] = [newURLs[index], newURLs[index - 1]];
        return newURLs;
    });
};
export const moveAttachmentDown = (index, setURLs) => {
    setURLs(prevURLs => {
        if (index === prevURLs.length - 1) return prevURLs; // already at the bottom
        const newURLs = [...prevURLs];
        [newURLs[index], newURLs[index + 1]] = [newURLs[index + 1], newURLs[index]];
        return newURLs;
    });
};

export const handleDismissError = (index, setQueuedFiles) => {
    setQueuedFiles(prevQueuedFiles => prevQueuedFiles.filter((_, i) => i !== index));
};


export const handleUpload = async (queuedFile, setQueuedFiles, uploadImage, uploadVideo, processVideo, setImageURLs, currentUser, quill, setVideoURLs, mediaInfo, setMediaInfo) => {
    const { file } = queuedFile;
    // Check if the file is already being processed
    if (queuedFile.status.processing === true) {
        //console.log(`[compose.js] handleUpload > File ${file.name} is already being processed.`);
        return; // Skip processing
    }
    setQueuedFiles(prevQueuedFiles => prevQueuedFiles.map(qFile => {
        if (qFile.file === file) {
            return { ...qFile, status: { uploading: false, completed: false, processing: true, error: null } };
        }
        return qFile;
    }));

    try {
        // Handle image files
        if (file.type.startsWith('image/')) {
            //console.log("[compose.js] handleUpload > File Upload: IS IMAGE");
            //console.log("[handleUpload] handleUpload > queuedFile: ", queuedFile);

            // New size detection
            if(file.size > 10 * 1e6) {
                const fileSizeInMB = (file.size / 1e6).toFixed(2); // Convert to MB and format to 2 decimal places
                //console.log("[compose.js] handleUpload > File too large: ", fileSizeInMB + " MB");
                setQueuedFiles(prevQueuedFiles => prevQueuedFiles.map(qFile => {
                    if (qFile.file === file) {
                        return { ...qFile, status: { uploading: false, completed: true, processing: false, error: `Image Too Large\n${fileSizeInMB} MB (10 MB limit)` } };
                    }
                    return qFile;
                }));
                return;
            }

            // Original

            setQueuedFiles(prevQueuedFiles => prevQueuedFiles.map(qFile => {
                if (qFile.file === file) {
                    return { ...qFile, status: { uploading: true, completed: false, processing: true, error: null } };
                }
                return qFile;
            }));
            const imageUrl = await uploadImage({
                UserPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
                file: file,
            }, { nodeURI: 'https://node.deso.org' });

            console.log("[handleUpload] upload return: ", imageUrl);
            // Update image URLs and remove from queue
            if (imageUrl && imageUrl.ImageURL) {
                
                // Determine the media info - move to a separate function
                //console.log("[metaData] before extractMetadata, file: ", file);
                const metadata = await extractMetadata(file);
                //console.log("[metaData] AFTER extractMetadata, metadata: ", metadata);
                
                // Update mediaInfo with the metadata
                setMediaInfo(prevMediaInfo => ({
                    ...prevMediaInfo,
                    [imageUrl.ImageURL]: {
                        ...metadata,
                    }
                }));
                setQueuedFiles(prevQueuedFiles => prevQueuedFiles.filter(qFile => qFile.file !== file));
                setImageURLs(prevImageURLs => [...prevImageURLs, imageUrl ? imageUrl.ImageURL : null]);
            }
        }
        // Handle video files
        else if (file.type.startsWith('video/')) {
            //console.log("[compose.js] handleUpload > File Upload: IS VIDEO");
            let error;
            // Check limits
            if(file.size > 250 * 1024 * 1024) {
                const fileSizeInMB = (file.size / 1e6).toFixed(2); // Convert to MB and format to 2 decimal places
                //console.log("[compose.js] handleUpload > File too large: ", fileSizeInMB + " MB");
                setQueuedFiles(prevQueuedFiles => prevQueuedFiles.map(qFile => {
                    if (qFile.file === file) {
                        return { ...qFile, status: { uploading: false, completed: true, processing: false, error: `Video Too Large\n${fileSizeInMB} MB (250 MB limit)` } };
                    }
                    return qFile;
                }));
                return;
            }
        
            // Check content type
            const validContentTypes = ["video/quicktime", "video/mp4", "video/x-ms-wmv"];
            if (!validContentTypes.includes(file.type)) {
                console.error(`UploadVideo: ${file.type} content type not supported`);
                setQueuedFiles(prevQueuedFiles => prevQueuedFiles.map(qFile => {
                    if (qFile.file === file) {
                        return { ...qFile, status: { uploading: false, completed: true, processing: false, error: `Format ${file.type} not supported` } };
                    }
                    return qFile;
                }));
                return;
            }

            if (file.duration > 150) {
                error = "UploadVideo: File must be shorter than 2 minutes 30 seconds";
                setQueuedFiles(prevQueuedFiles => prevQueuedFiles.map(qFile => {
                    if (qFile.file === file) {
                        return { ...qFile, status: { uploading: false, completed: true, processing: false, error: `Video Too Long\n${file.duration}s (150s limit)` } };
                    }
                    return qFile;
                }));
                return
            }

            setQueuedFiles(prevQueuedFiles => prevQueuedFiles.map(qFile => {
                if (qFile.file === file) {
                    return { ...qFile, status: { uploading: true, completed: false, processing: true, error: null } };
                }
                return qFile;
            }));

            const videoUrl = await uploadVideo({
                UserPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
                file: file,
            });

            // Update status for video file
            setQueuedFiles(prevQueuedFiles => prevQueuedFiles.map(qFile => {
                if (qFile.file === file) {
                    return { ...qFile, status: { uploading: false, completed: false, processing: true, error: null } };
                }
                return qFile;
            }));

            // Process video asynchronously
            await processVideo(videoUrl, queuedFile, setQueuedFiles, setVideoURLs, quill);
        }
    } catch (error) {
        console.error('[compose.js] handleUpload > Error handling file upload:', error);
        // Update status for the queued file in case of error
        setQueuedFiles(prevQueuedFiles => prevQueuedFiles.map(qFile => {
            if (qFile.file === file) {
                return { ...qFile, status: { uploading: false, completed: false, error: error.message } };
            }
            return qFile;
        }));
    }
};

    
export const processVideo = async (videoUrl, queuedFile, setQueuedFiles, setVideoURLs, quill) => {
    try {
        //console.log("[compose.js] processVideo > uploadVideo response:", videoUrl);
        //setVideoUploading(false);
        //setVideoProcessing(true);

        let videoProcessed = false;
        //console.log("[compose.js] processVideo > videoId:", videoUrl.asset.playbackId);
        while (!videoProcessed) {
            const tokenPattern = /token=([^&]+)/;
            const match = videoUrl.tusEndpoint.match(tokenPattern);
            const token = match ? match[1] : null;

            const checkVideoRequest = { videoId: videoUrl.asset.id };

            const videoStatus = await getVideoStatus(checkVideoRequest);
            //console.log("[compose.js] processVideo > video status:", checkVideoRequest, videoStatus);
            if (videoStatus && videoStatus?.status?.phase && videoStatus.status.phase === 'ready') {
                // Video processing is completed
                setVideoURLs(prevVideoURLs => [...(prevVideoURLs || []), videoUrl ? `https://lvpr.tv/?v=` + videoUrl.asset.playbackId : null]);
                //setVideoProcessing(false);
                videoProcessed = true;

                // Update queued file status
                setQueuedFiles(prevQueuedFiles => prevQueuedFiles.filter(qFile => qFile.file !== queuedFile.file));
            } else {
                // Video is still processing
                // Update queued file status
                setQueuedFiles(prevQueuedFiles => prevQueuedFiles.map(qFile => {
                    if (qFile.file === queuedFile.file) {
                        return { ...qFile, status: { ...qFile.status, processing: true } };
                    }
                    return qFile;
                }));

                // Wait for 2 seconds before checking again
                await new Promise(resolve => setTimeout(resolve, 750));
            }
        }
    } catch (error) {
        console.error('[compose.js] processVideo > Error processing video:', error);
        // Update queued file status if there's an error during processing
        setQueuedFiles(prevQueuedFiles => prevQueuedFiles.map(qFile => {
            if (qFile.file === queuedFile.file) {
                return { ...qFile, status: { ...qFile.status, processing: false, error: error.message } };
            }
            return qFile;
        }));
    }
};


export const handleButtonClick = (inputId) => {
    const inputFile = document.getElementById(inputId);
    if (inputFile) {
        inputFile.click();
    } else {
        console.error(`Input element with ID ${inputId} not found.`);
    }
};

export const handleDeleteImage = (index, setImageURLs) => {
    setImageURLs((prevImageURLs) => prevImageURLs.filter((_, i) => i !== index));
};
export const handleDeleteVideo = (index, setVideoURLs) => {
    setVideoURLs((prevVideoURLs) => prevVideoURLs.filter((_, i) => i !== index));
};
export const handleInsertImage = (ImageURL, event, quill) => {
    event.preventDefault();
    const range = quill.getSelection();
    if (range) {
        // Insert the uploaded image at the current cursor position
        quill.insertEmbed(range.index, 'image', ImageURL);
    } else {
        // If no range is found, insert at the end
        quill.insertEmbed(quill.getLength() - 1, 'image', ImageURL);
    }
}
export const handleInsertVideo = (VideoURL, event, quill) => {
    event.preventDefault();
    const range = quill.getSelection();
    if (range) {
        // Insert the video at the current cursor position
        quill.insertEmbed(range.index, 'video', VideoURL);
    } else {
        // If no range is found, insert at the end
        quill.insertEmbed(quill.getLength() - 1, 'video', VideoURL);
    }
}
// Embeds
export const toggleEmbedInput = (showEmbedInput, setShowEmbedInput, setEmbedUrl) => {
    setShowEmbedInput(prevState => !prevState);
    if(!showEmbedInput) { setEmbedUrl(null); }
};
export const handleEmbedInputChange = (event, setEmbedUrl) => {
    let value = event.target.value;

    if (value.startsWith('https://open.spotify.com/')) {
        // Extract the type (track, playlist, album, etc.) and the ID
        const urlMatch = value.match(/\/(track|playlist|album|episode|show)\/([a-zA-Z0-9]+)/);
        if (urlMatch && urlMatch[1] && urlMatch[2]) {
            const type = urlMatch[1];
            const id = urlMatch[2];
            value = `https://open.spotify.com/embed/${type}/${id}`;
        }
    }

    setEmbedUrl(value);
};
export const handleDeleteEmbed = (setEmbedUrl) => {
    setEmbedUrl(null);
};
export const handleInsertEmbed = (embedUrl, event, quill) => {
    event.preventDefault();
    const range = quill.getSelection();
    if (range && range.index !== null) {
        // Insert the iframe embed at the current cursor position
        quill.insertEmbed(range.index + 1, 'video', embedUrl); // Use a custom format like 'custom-iframe' to represent iframes
        quill.setSelection(range.index + 2); // Set the cursor after the inserted iframe
    } else {
        // If no range is found, insert at the end
        quill.insertEmbed(quill.getLength(), 'video', embedUrl); // Use a custom format like 'custom-iframe' to represent iframes
        quill.setSelection(quill.getLength() + 1); // Set the cursor at the end of the inserted iframe
    }
}


export const toggleMediaLibrary = (setShowMediaLibrary) => {
    setShowMediaLibrary(prevShowMediaLibrary => !prevShowMediaLibrary);
};    
export const addMediaToPost = (url, type, setImageURLs, setVideoURLs) => {
    if(type==="image") {
        setImageURLs(prevImageURLs => [...(prevImageURLs || []), url ]);
    } else if (type==="video") {
        setVideoURLs(prevVideoURLs => [...(prevVideoURLs || []), url ]);
    }
}
export const removeMediaFromPost = (url, type, setImageURLs, setVideoURLs) => {
    if (type === "image") {
        setImageURLs(prevImageURLs => prevImageURLs.filter(imageUrl => imageUrl !== url));
    } else if (type === "video") {
        setVideoURLs(prevVideoURLs => prevVideoURLs.filter(videoUrl => videoUrl !== url));
    }
};