import { Helmet } from 'react-helmet';
import { configure } from "deso-protocol";
import { DeSoIdentityContext } from "react-deso-protocol";
import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getProfileAPI, ProfileCard, ProfileLight, ProfileHeader } from "../utils/profiles";
import { deso_api, deso_graphql } from "../utils/graphql";
import formatPost, { getPosts, remapCommentTree } from "../utils/posts";
import { useUserPreferences } from "../contexts/UserPreferences";
import ExchangeRatesContext from "../contexts/ExchangeRatesContext";
import { Row, Col } from "react-bootstrap";
import { Loader } from "../utils/helpers";
import { getProfileFromCache } from '../services/profileCache';

export const Post = ( nftView = false ) => {
    const { preferences } = useUserPreferences();
    const { currentUser, alternateUsers } = useContext(DeSoIdentityContext);
    const { accessGroups, setAccessGroups } = useContext(ExchangeRatesContext);
    const { postHash, username, slug } = useParams();
    const [post, setPost] = useState(null);
    const [profile, setProfile] = useState(null);
    const [ metadata, setMetadata] = useState(null);
    const [loading, setLoading] = useState(true);
    const [formattedPost, setFormattedPost] = useState(null); // State to hold formatted post
    const [postType, setPostType] = useState("post"); // State to hold the post type
    const [loadingPostHash, setLoadingPostHash] = useState(null);
    const [FeaturedImageURL, setFeaturedImageURL] = useState(null);
    const [nftDetails, setNftDetails] = useState(null);
    const [scrollToPostHashHex, setScrollToPostHashHex] = useState(null);
    const [noMoreScrolling, setNoMoreScrolling] = useState(null);

    useEffect(() => {
        setLoading(true);
        setLoadingPostHash(postHash);
        const fetchPost = async () => {
            try {
                if (postHash) {
                    // Fetch post by postHash
                    const request = {
                        PostHashHex: postHash,
                        FetchParents: true,
                    };
                    if (currentUser) {
                        request.ReaderPublicKeyBase58Check = currentUser.PublicKeyBase58Check;
                    }

                    //console.log("[posts.jsx] preferences:", preferences);
                    const data = await deso_api('get-single-post', request, preferences.nodeURI);
                    if(data) {
                        if(data.PostFound.ParentStakeID) {
                            // is a reply
                        }
                        setPost(data);
                        setPostType("comment");
                    } else {

                    }
                } else if (username && slug) {
                    // Fetch post by slug using GraphQL
                    //console.log("[posts.jsx] Blog...",username,slug);
                    const variables = {
                        filter: {
                            and: [
                                {"extraData": {
                                    "contains": { "BlogTitleSlug": slug, }
                                }}
                            ]
                        },
                        first: 1 // Assuming you want only one post
                    };
                    const posts = await getPosts(variables, currentUser, preferences);
                    //console.log("[posts.jsx] blog result:",posts);
                    if (posts && posts.nodes && posts.nodes.length > 0) {
                        //console.log("[posts.jsx] Found matching blog",posts.nodes[0]);
                        setPost(posts.nodes[0]); // Assuming you're taking the first post
                        setPostType("blog");
                    } else {
                        // Handle case where post is not found
                    }
                }
            } catch (error) {
                // Handle error
            } finally {
                if(formattedPost && profile) { setLoading(false); }
            }
        };
        fetchPost();
        setNoMoreScrolling(null);
    }, [postHash]);

    useEffect(() => {
        setLoading(true);
        //console.log("[posts.jsx} Output post: ",post);
        const processFormattedPost = async () => {
            if (post) {
                if(post.PostFound) {
                    let type = "post";
                    let bookmark = null;
                    let thread = null;

                    let finalpost = post;
                    if(post["PostFound"].ParentStakeID && post["PostFound"].ParentStakeID!=='') {
                        type = "post";
                        // remap comment tree to layout and navigate to comment
                        setScrollToPostHashHex(post.PostFound.PostHashHex);
                        finalpost = remapCommentTree(post);
                        thread = true;
                    }
                    else if(post["PostFound"].PostExtraData && post["PostFound"].PostExtraData.BlogTitleSlug) { 
                        type = "blog";
                    }
                    setPostType(type);
                    // Assuming formatPost is an asynchronous function or returns a Promise
                    //console.log("[posts.jsx] HERE, thread = ",thread);

                    const formatted = await formatPost(finalpost["PostFound"], currentUser, 0, type, 'threaded', accessGroups, preferences, thread, null, alternateUsers, true);
                    setFormattedPost(formatted);
                    const fullProfile = await getProfileFromCache(finalpost.PostFound.PosterPublicKeyBase58Check);
                    setProfile(fullProfile);
                } else if (post.PostHashHex) {
                    let type = "post";
                    if(post.PostExtraData && post.PostExtraData.BlogTitleSlug) { 
                        type = "blog";
                    }
                    setPostType(type);
                    // Assuming formatPost is an asynchronous function or returns a Promise
                    const formatted = await formatPost(post, currentUser, 0, type, 'threaded', accessGroups, preferences, true, null, alternateUsers, true);
                    //const formatted = await formatPost(post, currentUser, 0, type, 'list', accessGroups, acc);
                    setFormattedPost(formatted);
                    const fullProfile = await getProfileFromCache(post.PosterPublicKeyBase58Check);
                    setProfile(fullProfile);
                }
                //console.log("[posts.jsx] NFT check...", post);
                if(nftView && post && post.PostFound.IsNFT) {
                    // Lookup NFT Information
                    const nftDetails = await getNftDetails(post.PostFound.PostHashHex);
                    setNftDetails(nftDetails);
                }
            }
        };

        if(post) {
            if(post && !post.PostFound) {
                post.PostFound = post;
            }
            const metadata = generateMetadata(post);
            setMetadata(metadata);
            processFormattedPost();
        }
    }, [post]); // Re-run this effect when post or currentUser changes

    useEffect(() => {
        setLoading(true); // Set loading to true whenever postHash changes
        const fetchProfile = async () => {
            try {
                if (post) {
                    const data = await getProfileFromCache(post.PosterPublicKeyBase58Check);
                    if(data) {
                        setProfile(data);
                    }
                }
            } catch (error) {
                // Handle error
            } finally {
                if(formattedPost && profile) { setLoading(false); }
            }
        };
        fetchProfile();
    }, [formattedPost]);

    useEffect(() => {
        //console.log("[posts.jsx] Final output: ",post);
        if(post && post.PostFound.PostExtraData && post.PostFound.PostExtraData.CoverImage && post.PostFound.PostExtraData.CoverImage !== '') {
            //console.log("[posts.jsx] has CoverImage.");
            setFeaturedImageURL(post.PostFound.PostExtraData.CoverImage);
        }
        if(profile && formattedPost) { setLoading(false); } 
    }, [formattedPost,profile]);

    const postOutput = (
        <>
          { nftView === true && post ? (
            <>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <h2>NFT Details</h2>
                    </div>
                    <div className='col-12 col-md-6'>
                        {formattedPost}
                    </div>
                    <div className='col-12 col-md-6'>
                        Summary
                        <dl>
                            <dt>
                                NumNFTCopiesForSale
                            </dt>
                            <dd>
                                {post.PostFound.NumNFTCopiesForSale}
                            </dd>

                            <dt>NumNFTCopies</dt>
                            <dd>{post.PostFound.NumNFTCopies}</dd>

                            <dt>NumNFTCopiesBurned</dt>
                            <dd>{post.PostFound.NumNFTCopiesBurned}</dd>

                            <dt>NFTRoyaltyToCoinBasisPoints</dt>
                            <dd>{post.PostFound.NFTRoyaltyToCoinBasisPoints}</dd>

                            <dt>NFTRoyaltyToCreatorBasisPoints</dt>
                            <dd>{post.PostFound.NFTRoyaltyToCreatorBasisPoints}</dd>

                            <dt>HasUnlockable</dt>
                            <dd>{post.PostFound.HasUnlockable}</dd>

                            </dl>

                            <dl>
                            {post.PostFound.PostExtraData &&
                                Object.keys(post.PostFound.PostExtraData).map((key) => {
                                let value = post.PostFound.PostExtraData[key];

                                
                                if (key === "properties") {
                                    const propertyData = JSON.parse(`{"values": ${atob(value)}}`);
                                    return (
                                    <div key={key} className="col-12">
                                        <dt>{key}</dt>
                                        <dd>
                                        {propertyData.values.map((prop, index) => {
                                            if (Array.isArray(prop)) {
                                            return (
                                                <div key={index} className="row my-0">
                                                <dt className="col-sm-5 text-end text-truncate my-0 fw-normal">
                                                    <em>{prop[0]}</em>
                                                </dt>
                                                <dd className="col-sm-7 my-0 text-truncate">{prop[1]}</dd>
                                                </div>
                                            );
                                            } else {
                                            return (
                                                <div key={index} className="row my-0">
                                                <dd className="col-sm-12 my-0 text-truncate">{prop}</dd>
                                                </div>
                                            );
                                            }
                                        })}
                                        </dd>
                                    </div>
                                    );
                                } else {
                                    if (value !== "") {
                                    return (
                                        <div key={key} className="col-12">
                                        <dt>{key}</dt>
                                        <dd className={`text-truncate ${key === "name" ? "font-weight-bold" : ""}`}>
                                            {value}
                                        </dd>
                                        </div>
                                    );
                                    }
                                    return null;
                                }
                            })}
                        </dl>
                    </div>
                </div>
            </div>
            </>
          ) : (
            <>
            {postType === "post" ? (
                <div class="container">
                  <div class="row">
                    <div className="col-12 col-md-9 col-lg-7 col-xxl-6 mx-auto">
                      {formattedPost}
                    </div>
                  </div>
                </div>
              ) : (
                formattedPost
              )}
            </>
          ) }
        </>
      );

    useEffect(() => {
        if (!loading && scrollToPostHashHex && postOutput && profile) {
            scrollToElement();
        }
    }, [loading, scrollToPostHashHex, postOutput]);
    
    const scrollToElement = () => {
        if (!loading && scrollToPostHashHex && postOutput && !noMoreScrolling) {
            setTimeout(() => {
                const targetElement = document.querySelector(`#post_${scrollToPostHashHex}`);
                if (targetElement) {
                    const desiredPosition = targetElement.offsetTop - 10 * parseFloat(getComputedStyle(document.documentElement).fontSize);
                    //console.log("[posts.jsx] ScrollTo - Element found, scrolling into view", `#post_${scrollToPostHashHex}`);
                    window.scrollTo({ top: desiredPosition, behavior: 'smooth' });
                    setNoMoreScrolling(true);
                } else {
                    //console.log("[posts.jsx] ScrollTo - Element not found for", `#post_${scrollToPostHashHex}`);
                }
            }, 250);
        }
    };
    

    return (
        <>
            {post && profile ? (
                <>
                    <Helmet>
                        {metadata}
                    </Helmet>


                    {/* Your existing code */}
                    <ProfileHeader profile={profile} imageSrc={FeaturedImageURL} postOutput={postOutput} />
                </>
            )  : (
                    <div className='container'>
                        {!loading ? (
                            <div className="row px-3 d-flex flex-column vh-100 justify-content-evenly align-items-around">
                                <div className='mt-5 pt-5'>
                                    <h1><i className="bi bi-cone-striped"></i> Sorry, no {postType} found</h1>
                                    <p>The <em>PostHashHex</em> reference supplied returned no content from the blockchain.</p>
                                    <p className='text-truncate'>Value supplied:<br/>{postHash}</p>
                                </div>
                                <p className='mb-5 pb-5'>Please see the <Link to={`/home`}>home feed</Link>, <Link to={`/discover`}>discover</Link> or <Link to={`/search`}>search</Link> to find more content.</p>
                            </div>
                        ) : (
                            <div className="row px-3 d-flex flex-column vh-100 justify-content-evenly align-items-around">
                                <div className="my-5 py-5">
                                    <Loader/>
                                </div>
                            </div>
                        )}
                    </div>
            )}
        </>
    );
};

export const getNftDetails = async (PostHashHex) => {
    //console.log("[posts.jsx] NFT Details for post:", PostHashHex);

    try {
        const response = await deso_graphql({
            query: `
                query Transactions($filter: TransactionFilter, $orderBy: [TransactionsOrderBy!], $last: Int) {
                    transactions(filter: $filter, orderBy: $orderBy, last: $last) {
                        nodes {
                            txIndexMetadata
                            timestamp
                            transactionType {
                                type
                                name
                            }
                        }
                    }
                }
            `,
            variables: {
                filter: {
                    and: [
                        {
                            txnType: {
                                in: [
                                    15, 16, 17, 18, 19, 20, 21
                                ]
                            },
                            txIndexMetadata: {
                                contains: {
                                    NFTPostHashHex: PostHashHex
                                },
                                containsKey: "SerialNumber"
                            },
                        }
                    ]
                },
                orderBy: ["TIMESTAMP_DESC"],
                last: 15
            },
            operationName: "Transactions"
        });

        const data = await response;

        //console.log("[posts.jsx] NFT Details returned", data);

        // Extract relevant information from the response and format the output
        const formattedDetails = data.transactions.nodes.map(node => ({
            txIndexMetadata: node.txIndexMetadata,
            timestamp: node.timestamp,
            transactionType: node.transactionType.name,
        }));

        return formattedDetails;
    } catch (error) {
        //console.error('[posts.jsx] NFT Details Error fetching NFT details:', error.message);
        // You can handle errors here, e.g., return a default value or throw an error
        return null;
    }
};


const generateMetadata = (post) => {
    const metadata = [];
    //console.log("generateMetadata for ",post);
    const timestampNanos = post.PostFound.TimestampNanos;
    const timestampSeconds = Math.floor(timestampNanos / 1e9); // Convert nanoseconds to seconds
    const isoDateTime = new Date(timestampSeconds * 1000).toISOString();
    
    // Title
    let title;
    let description;

    if(post.PostFound.Title) {
        title = `${post.PostFound.ProfileEntryResponse.Username}: ${post.PostFound.Title}`
    } else { 
        title = `${post.PostFound.ProfileEntryResponse.Username}s post:`; 
    }

    if(post.PostFound.Body) {
        description = `${post.PostFound.Body.substring(0, 27)}...`;
    }

    metadata.push(<meta key="og:title" property="og:title" content={title} />);
    metadata.push(<meta key="og:description" property="og:description" content={description} />);

    // Author
    metadata.push(<meta key="article:author" property="article:author" content={post.PostFound.ProfileEntryResponse.Username} />);

    // Published and Modified Time
    metadata.push(<meta key="article:published_time" property="article:published_time" content={isoDateTime} />);
    metadata.push(<meta key="article:modified_time" property="article:modified_time" content={isoDateTime} />);

    // Type
    metadata.push(<meta key="og:type" property="og:type" content="article" />);

    // URL
    metadata.push(<meta key="og:url" property="og:url" content={window.location.href.toString()} />);

    // Twitter Card
    metadata.push(<meta key="twitter:card" name="twitter:card" content="summary_large_image" />);
    //metadata.push(<meta key="twitter:site" name="twitter:site" content="@YourTwitterHandle" />);
    metadata.push(<meta key="twitter:title" name="twitter:title" content={title} />);
    metadata.push(<meta key="twitter:description" name="twitter:description" content={description} />);

    // Images
    if (post.PostFound.ImageURLs && post.PostFound.ImageURLs.length > 0) {
        post.PostFound.ImageURLs.forEach((imageUrl, index) => {
            if (imageUrl && imageUrl !== '') {
                metadata.push(<meta property="og:image" content={imageUrl} />);
                metadata.push(<meta property="og:image:secure_url" content={imageUrl} />);
                metadata.push(<meta property="og:image:alt" content="Image Alt Text" />);
                metadata.push(<meta name="twitter:image" content={imageUrl} />);
                metadata.push(<meta name="twitter:image:alt" content="Image Alt Text" />);
            }
        });
    } else {
        // Include a profile banner or avatar instead
        if(post.PostFound && post.PostFound.ProfileEntryResponse && post.PostFound.ProfileEntryResponse.ExtraData && post.PostFound.ProfileEntryResponse.ExtraData.FeaturedImageURL) {
            // FeaturedImageURL
            const imageUrl = post.PostFound.ProfileEntryResponse.ExtraData.FeaturedImageURL;
            metadata.push(<meta property="og:image" content={imageUrl} />);
            metadata.push(<meta property="og:image:secure_url" content={imageUrl} />);
            metadata.push(<meta property="og:image:alt" content="Image Alt Text" />);
            metadata.push(<meta name="twitter:image" content={imageUrl} />);
            metadata.push(<meta name="twitter:image:alt" content="Image Alt Text" />);
        } else if(post.PostFound && post.PostFound.ProfileEntryResponse && post.PostFound.ProfileEntryResponse.ExtraData && post.PostFound.ProfileEntryResponse.ExtraData.LargeProfilePicURL) {
            // LargeProfilePicURL
            const imageUrl = post.PostFound.ProfileEntryResponse.ExtraData.LargeProfilePicURL;
            metadata.push(<meta property="og:image" content={imageUrl} />);
            metadata.push(<meta property="og:image:secure_url" content={imageUrl} />);
            metadata.push(<meta property="og:image:alt" content="Image Alt Text" />);
            metadata.push(<meta name="twitter:image" content={imageUrl} />);
            metadata.push(<meta name="twitter:image:alt" content="Image Alt Text" />);
        } else {
            // Standard Avatar
            const imageUrl = `https://node.deso.org/api/v0/get-single-profile-picture/${post.PostFound.ProfileEntryResponse.PublicKeyBase58Check}`;
            metadata.push(<meta property="og:image" content={imageUrl} />);
            metadata.push(<meta property="og:image:secure_url" content={imageUrl} />);
            metadata.push(<meta property="og:image:alt" content="Image Alt Text" />);
            metadata.push(<meta name="twitter:image" content={imageUrl} />);
            metadata.push(<meta name="twitter:image:alt" content="Image Alt Text" />);
        }
    }

    // Videos
    if (post.PostFound.VideoURLs && post.PostFound.VideoURLs.length > 0) {
        post.PostFound.VideoURLs.forEach((videoUrl, index) => {
            if (videoUrl && videoUrl !== '') {
                metadata.push(<meta property="og:video" content={videoUrl} />);
                metadata.push(<meta property="og:video:secure_url" content={videoUrl} />);
                metadata.push(<meta name="twitter:player" content={videoUrl} />);
            }
        });
    }

    return metadata;
};


