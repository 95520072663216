// index.js
import React, { useContext, useEffect, useState, lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { DeSoIdentityProvider, DeSoIdentityContext } from "react-deso-protocol";
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './style.css';
import { Spinner } from "react-bootstrap";
import { Avatar } from "./utils/layouts.js";
import { getUserAssociations, identity } from "deso-protocol";
import { BetaExpressInterest, BetaLogin } from "./components/beta.jsx";
import { ErrorProvider } from "./contexts/ErrorHandling.js";
//import App from './components/app.jsx'; // Import App directly

const checkAllowedUser = (currentUser, betaUsers) => {
  const isBetaUser = betaUsers.includes(currentUser?.PublicKeyBase58Check);
  const isSystemUser = currentUser?.PublicKeyBase58Check === process.env.REACT_APP_SYSTEM_KEY;

  return isBetaUser || isSystemUser;
};

const fetchBetaUsers = async () => {
  // Get the list from environment variables and split into an array
  const existingBetaUsersString = process.env.REACT_APP_BETAUSERS || '';

  // Make sure the string is being split correctly by commas
  const existingBetaUsers = existingBetaUsersString
    .split(',')
    .map(user => user.trim()) // Trim each public key to remove any leading/trailing spaces
    .filter(user => user.length > 0); // Filter out any empty values
  
  //console.log("Parsed BETAUSERS:", existingBetaUsers);

  // Fetch beta users from the API
  const response = await getUserAssociations({
    TransactorPublicKeyBase58Check: process.env.REACT_APP_SYSTEM_KEY,
    AssociationType: "BETA_USER"
  });
  //console.log("API BETAUSERS:", response);
  // Check for API response and extract beta users
  let apiBetaUsers = [];
  if (response?.Associations) {
    apiBetaUsers = response.Associations.map(
      (association) => association.TargetUserPublicKeyBase58Check
    );
  }

  // Merge existing users with API users and remove duplicates
  const combinedBetaUsers = [...new Set([...existingBetaUsers, ...apiBetaUsers])];

  //console.log("Combined BETAUSERS:", combinedBetaUsers);
  return combinedBetaUsers;
};


const AppLoader = () => {
  const { currentUser, alternateUsers, isLoading } = useContext(DeSoIdentityContext);
  const [isUserAllowed, setIsUserAllowed] = useState(null);
  const [betaUsers, setBetaUsers] = useState([]);

  useEffect(() => {
    const checkBetaUsers = async () => {
      const users = await fetchBetaUsers();
      setBetaUsers(users);
    };

    checkBetaUsers();
  }, []);

  useEffect(() => {
    if (!isLoading && currentUser) {
      setIsUserAllowed(checkAllowedUser(currentUser, betaUsers));
    } else if (!isLoading) {
      setIsUserAllowed(false); // User is not logged in
    }
  }, [currentUser, isLoading, betaUsers]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isUserAllowed) {
    return <UnauthorizedScreen currentUser={currentUser} alternateUsers={alternateUsers} />;
  }

  if (currentUser && isUserAllowed) {
    const App = lazy(() => import('./components/app.jsx'));
    //return ( <>Test </>);
    return (
      <Suspense>
          <App />
      </Suspense>
    );
  }
};

const LoadingScreen = () => (
  <div key={`loadingScreen`} className="d-flex flex-column vh-100 justify-content-center">
    <div className="container align-items-center text-center">
      <div className="row">
        <div className="col-12">
          <div className="d-flex flex-row flex-nowrap align-items-center justify-content-center">
            <div>
              <img src="\assets\BeyondSocial_100px.png" className="me-3"/>
            </div>
            <div className="lh-sm fw-light">
              <div className="fs-1 m-0 p-0">
                <span className="fw-bold">Beyond</span><span className="fw-light fs-i text-muted"><em>Social</em></span>
              </div>
              <div className="fs-5 m-0 p-0">Take <span className="fw-bold">your</span> content further</div>
            </div>
          </div>
        </div>
        <div className="col-12 text-center mt-3">
          <Spinner animation="border" />
        </div>
      </div>
    </div>
  </div>
);

const UnauthorizedScreen = ({ currentUser, alternateUsers }) => {
  return (
    <div key={`betaLogin`} className="d-flex flex-column vh-100 justify-content-center">
      <div className="container align-items-center text-center">
        <div className="row mb-5">
          <div className="col-12">
            <div className="d-flex flex-row flex-nowrap align-items-center justify-content-center">
              <div>
                <img src="\assets\BeyondSocial_100px.png" className="me-3"/>
              </div>
              <div className="lh-sm fw-light">
                <div className="fs-1 m-0 p-0">
                  <span className="fw-bold">Beyond</span><span className="fw-light fs-i text-muted"><em>Social</em></span>
                </div>
                <div className="fs-5 m-0 p-0">Take <span className="fw-bold">your</span> content further</div>
              </div>
            </div>
          </div>
        </div>
        {!currentUser ? (
          <div className="row">
            <div className="col-12 text-center">
              If you are part of the beta programme, please login below:
            </div>
            <BetaLogin />
          </div>
        ) : (
            <div className="row">
              <div className="col-12 my-5 fs-4">
                <h3><i className="bi bi-person-fill-lock me-4"></i>Closed Beta Testing</h3>
              </div>
              <div className="col-12 fs-5 mb-3 d-flex flex-row justify-content-center align-items-center">
                <span className="me-3">Welcome</span>
                <Avatar publicKey={currentUser.PublicKeyBase58Check} type="avatar" link={false} />
                <Avatar publicKey={currentUser.PublicKeyBase58Check} type="username" link={false} />
              </div>
              <div className="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3 mb-5">
                <p className="fs-6 fw-bold">Sorry, you are not currently on the beta whitelist.</p>
              </div>
              <div className="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3 mb-5">
                <BetaExpressInterest />
              </div>
              <div className="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3 mb-5">
                <button
                  className="btn btn-danger mb-3"
                  onClick={() => identity.logout()}
                >
                  <i className="bi bi-power"></i> Logout
                </button>
                {alternateUsers && (
                  <>
                    <div className="me-3 text-muted mb-3">Switch Users:</div>
                    <div className="d-flex flex-row align-items-center justify-content-center">
                    {alternateUsers.length > 0 && alternateUsers.map((user, index) => (
                      <div key={index} className="action" onClick={() => identity.setActiveUser(user.PublicKeyBase58Check)}>
                        <Avatar publicKey={user.PublicKeyBase58Check} type='avatar' link={false} /> 
                        <Avatar publicKey={user.PublicKeyBase58Check} type='username' link={false} />
                      </div>
                    ))}
                    </div>
                  </>
                )}
              </div>
            </div>
        )}
      </div>
    </div>
  );
};

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
  <DeSoIdentityProvider>
    
      <AppLoader />
    
  </DeSoIdentityProvider>
);
