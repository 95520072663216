import React, { useState, useEffect, useContext, useRef } from 'react';
import { UserInput, ProfileCard, getProfileAPI } from '../utils/profiles'; 
import { nodes } from '../utils/helpers';
import { Accordion, Button, Card } from 'react-bootstrap';
import { DeSoIdentityContext } from "react-deso-protocol";
import { AccessGroupsContext } from '../contexts/AccessGroups';
import { useUserPreferences } from '../contexts/UserPreferences';
import { createUserAssociation, getUserAssociations } from 'deso-protocol';
import { useError } from '../contexts/ErrorHandling';
import { deso_graphql } from '../utils/graphql';
import { localFees } from '../utils/transactions';
import { Avatar } from '../utils/layouts';
import { Loader } from 'react-bootstrap-typeahead';

export const ContentForm = ({ view, setView, showSearch, setShowSearch, onSubmit, setFormData, formData, feedType, setFeedType, profile, customisation, additionalOptions }) => {
  const { currentUser, alternateUsers } = useContext(DeSoIdentityContext);
  const { accessGroups } = useContext(AccessGroupsContext);
  const { preferences } = useUserPreferences();
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [userInput, setUserInput] = useState(null);
  const [inputMention, setInputMention] = useState(null);
  const [inputParentPoster, setInputParentPoster] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [searchTab, setSearchTab] = useState('filters');
  const [filterAccordion, setFilterAccordion] = useState(null);
  const [viewport, setViewport] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [isSticky, setIsSticky] = useState(false);
  const contentFormRef = useRef(null);
  const placeholderRef = useRef(null);

  useEffect(() => {
    let hasIntersected = false;
    let elementTopPosition = null;
  
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Calculate the top position of the element when it first intersects
        if (entry.isIntersecting) {
          if (!hasIntersected) {
            hasIntersected = true;
            elementTopPosition = entry.boundingClientRect.top + window.pageYOffset;
          }
          // Deactivate sticky when scrolling back up
          if (window.pageYOffset <= elementTopPosition - 55) {
            setIsSticky(false);
          }
        }
  
        // Activate sticky only when scrolling down past the element
        if (hasIntersected && window.pageYOffset > elementTopPosition - 70) {
          setIsSticky(!entry.isIntersecting);
        }
      },
      {
        threshold: 0,
        rootMargin: '-70px 0px 0px 0px',
      }
    );
  
    if (placeholderRef.current) {
      observer.observe(placeholderRef.current);
    }
  
    return () => {
      if (placeholderRef.current) {
        observer.unobserve(placeholderRef.current);
      }
    };
  }, []);
  
  
   

  useEffect(() => {
    if(showOptions) { setSearchTab('filters'); }
  }, [showOptions]);

  useEffect(() => {
    if(!formData.feedType) {
      setFormData({
        ...formData,
        feedType: feedType, // Set the poster value to the selected value
      });
    } else if(formData.feedType !== feedType) {
      setFeedType(formData.feedType);
    }
  }, [formData]);

  useEffect(() => {
    setFormData({
      ...formData,
      feedType: feedType, // Set the poster value to the selected value
    });
  }, [feedType]);

  // Update viewport dimensions on window resize
  useEffect(() => {
    const handleResize = () => {
      setViewport({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleSelect = (selected) => {
    // Update the value of the "poster" field in the formData state
    console.log("handleSelect:",selected);
    /*
    setFormData({
      ...formData,
      poster: selected ? selected.value : '', // Set the poster value to the selected value
    });*/
    if(selected && selected.target && selected.target.PublicKeyBase58Check) {
      addValueToArrayKey('posterPublicKey', selected.target.PublicKeyBase58Check);  
      setUserInput('');
    }
  };

  const clearInput = (key) => {
    const inputElement = document.getElementById(`input${key}`);
    if (inputElement) {
      inputElement.value = ''; // Clear the input value
    }
  };
  
  const addValueToArrayKey = (key, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: [...(prevFormData[key] || []), value],
    }));
  
    clearInput(key); // Clear the input with matching id
  };
  
  const removeValueFromArrayKey = (key, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: (prevFormData[key] || []).filter((item) => item !== value),
    }));
  
    clearInput(key); // Clear the input with matching id
  };
  
  
  const handleChange = (e) => {
    console.log("[Search] handleChange e => ",e);
    if(!e.target) { return; }
    const { name, value, type, checked, dataset } = e.target;
    console.log("handleChange: ",name, value, type, checked, dataset)

    // Check if the target is a tab element
    const isTab = dataset && dataset.target === 'searchOrderBy';

    // Handle node selection checkboxes separately
    if (name.startsWith('node[') && type === 'checkbox') {
        const nodeId = name.match(/\[(.*?)\]/)[1]; // Extract the node ID from the name attribute
        const updatedNodes = checked
            ? [...formData.node, nodeId] // If checked, add the node ID to the array
            : formData.node.filter((node) => node !== nodeId); // If unchecked, remove the node ID from the array

        setFormData({
            ...formData,
            node: updatedNodes,
        });
    } else if (name==='parentPoster') {
        if(value !== '') { 
          setFormData({
            ...formData,
            parentPoster: value,
          });
          setInputParentPoster(null);
        } else {
          setFormData({
            ...formData,
            parentPoster: null,
          });
        }
    } else if (name==='embedVideoUrl') {
      if(value !== null) { 
        setFormData({
          ...formData,
          embedVideoUrl: value,
        });
      } else {
        setFormData({
          ...formData,
          embedVideoUrl: null,
        });
      }
    } else if (name==='hasUnlockable') {
      if(checked === true) { 
        setFormData({
          ...formData,
          hasUnlockable: true,
        });
      } else {
        setFormData({
          ...formData,
          hasUnlockable: null,
        });
      }
    } else if (name==='mentions') {
      if (value !== '' && e.avatar) { 
        setFormData(prevFormData => ({
          ...prevFormData,
          mentions: [...prevFormData.mentions, { [value]: e.avatar }],
        }));
        setInputMention('');
      }
      console.log("[Search] mentioned:",value);
    } else if (name === 'deleteMention') {
      // Filter out the entry with the matching mention value
      const updatedMentions = formData.mentions.filter(mentionObj => {
        const mention = Object.keys(mentionObj)[0]; // Get the mention value of the current entry
        return mention !== value; // Keep entries where the mention value does not match
      });
    
      // Update formData with the filtered mentions
      setFormData(prevFormData => ({
        ...prevFormData,
        mentions: updatedMentions,
      }));
    
      console.log("[Search] mentioned:", value);
    } else if (name==='numNftCopies') {
      if(checked === true) { 
        setFormData({
          ...formData,
          numNftCopies: 1,
        });
      } else {
        setFormData({
          ...formData,
          numNftCopies: null,
        });
      }
    } else if (name.endsWith('Exist') || name.endsWith('Exists')) {
      if(checked) { 
        setFormData({
          ...formData,
          [name]: true,
        });
      } else {
        setFormData({
          ...formData,
          [name]: null,
        });
      }
      console.log("[Search] Updated formData:",formData);
    } else if (name==='filterNumNftCopiesForSale') {
      if(checked === true) { 
        setFormData({
          ...formData,
          filterNumNftCopiesForSale: true,
        });
      } else {
        setFormData({
          ...formData,
          filterNumNftCopiesForSale: null,
        });
      }
    } else if (isTab) {
        // If it's a tab click, update the orderBy value directly
        console.log("[contentForm] Order set to:", dataset.value);
        setFormData({
            ...formData,
            orderBy: dataset.value,
        });
    } else if (type === 'checkbox') {
      // For switches (form-switch class)
      if (dataset.type==='toggle') {
        console.log("handleChange set to:", (checked ? null : false));
          setFormData({
              ...formData,
              [name]: checked ? null : false, // Set to null if checked, false if unchecked
          });
      } else {
          // For regular checkboxes
          console.log("handleChange set to:", (checked ? true : null));
          setFormData({
              ...formData,
              [name]: checked ? true : null, // Set to true if checked, null if unchecked
          });
      }
  } else {
        if (type === 'radio') {
            // For radio inputs, directly set the value to true, false, or null
            setFormData({
              ...formData,
              [name]: value === 'null' ? null : value === 'true' ? true : false,
            });
        } else {
            // For other inputs, handle as usual
            const fieldValue = type === 'checkbox' ? checked : value;
            setFormData({
                ...formData,
                [name]: fieldValue,
            });
        }
    }
  };


  function clearDate(inputId) {
    document.getElementById(inputId).value = '';
  }
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    console.log("[contentForm] form data:", formData);
    onSubmit(formData); // Call onSubmit function with form data as argument
    //setSearchTriggered(true); // Set searchTriggered to true after form submission
  };

  console.log("[ContentForm.jsx] FeedType:",feedType);

  return (
    <>
    <div ref={placeholderRef} className={`contentBarPlaceholder ${isSticky ? 'contentBarPosition' : ''}`}></div>
    <div className={`contentBarSpacer ${isSticky ? `active${showSearch && (feedType !== 'home' && feedType !== 'stats') ? ` search` : ``}` : ``}`}></div>
    <div 
      id="contentForm"
      ref={contentFormRef}
      className={`contentBar px-2 ${isSticky ? 'fixed-top pastnav bg-body bg-opacity-50' : ''}`}
    >
        <form className="contentForm text-center" id="contentFormFilter" onSubmit={handleSubmit}>
          <div className=''>
            <div className='d-flex flex-row flex-nowrap justify-content-center align-items-center'>
                {profile && profile.Username ? (
                  <img style={{ maxWidth: "2em !important" }} src={`https://node.deso.org/api/v0/get-single-profile-picture/${profile.PublicKeyBase58Check}`} className={`deso_avatar align-self-center me-3`} onClick={() => setShowSearch(!showSearch)} />
                ) : (
                  <i className={`bi align-self-center pe-3 ${showSearch ? `bi-search` : `bi-search text-muted`}`} onClick={() => setShowSearch(!showSearch)}></i>  
                )}
              <div className='d-flex flex-row flex-nowrap overflow-auto'>
                <input type="hidden" name="contentType" id="contentType" value={formData.orderBy} onChange={handleChange} />
                <ul id="searchOrderSelection" className="nav nav-pills nav-fill flex-nowrap">
                  {profile && profile.Username && (
                    <li className="nav-item contentType">
                    <a
                        className={`nav-link ${feedType === 'home' ? 'active fw-bold border-0 border-start border-top border-end ' : 'text-muted'}`}
                        aria-current="page"
                        href="#"
                        onClick={() => setFeedType('home')}
                    >
                        Home
                    </a>
                  </li>
                  )}
                  {profile && customisation && customisation.ExtraData && customisation.ExtraData.tabs && Object.keys(customisation.ExtraData.tabs).length > 0 ? (
                    <>
                      {Object.entries(customisation.ExtraData.tabs).map(([key, value]) => {
                        // Check if the tab is active
                        if (value.active !== false) {
                          const label = key.charAt(0).toUpperCase() + key.slice(1);
                          return (
                            <li key={key} className="nav-item contentType">
                              <span
                                className={`nav-link text-nowrap ${feedType === key ? 'active fw-bold border-0 border-start border-top border-end' : 'text-muted'}`}
                                aria-current="page"
                                onClick={() => setFeedType(key)}
                              >
                                {value.title || label}
                              </span>
                            </li>
                          );
                        }
                        return null;
                      })}
                    </>
                  ) : (
                  <>
                  <li className="nav-item contentType">
                    <span
                        className={`nav-link ${feedType === 'all' ? 'active fw-bold border-0 border-start border-top border-end ' : 'text-muted'}`}
                        aria-current="page"
                        onClick={() => setFeedType('all')}
                    >
                        All
                    </span>
                  </li>
                  <li className="nav-item contentType">
                    <span
                        className={`nav-link ${feedType === 'posts' ? 'active fw-bold border-0 border-start border-top border-end ' : 'text-muted'}`}
                        aria-current="page"
                        onClick={() => setFeedType('posts')}
                    >
                        Posts
                    </span>
                  </li>
                  <li className="nav-item contentType">
                    <span
                        className={`nav-link ${feedType === 'blog' ? 'active fw-bold border-0 border-start border-top border-end ' : 'text-muted'}`}
                        aria-current="page"
                        onClick={() => setFeedType('blog')}
                    >
                        Blog
                    </span>
                  </li>
                  <li className="nav-item contentType">
                    <span
                        className={`nav-link ${feedType === 'discussions' ? 'active fw-bold border-0 border-start border-top border-end ' : 'text-muted'}`}
                        aria-current="page"
                        onClick={() => setFeedType('discussions')}
                    >
                        Discussions
                    </span>
                  </li>
                  <li className="nav-item contentType">
                    <span
                        className={`nav-link ${feedType === 'images' ? 'active fw-bold border-0 border-start border-top border-end ' : 'text-muted'}`}
                        aria-current="page"
                        onClick={() => setFeedType('images')}
                    >
                        Images
                    </span>
                  </li>
                  <li className="nav-item contentType">
                    <span
                        className={`nav-link ${feedType === 'video' ? 'active fw-bold border-0 border-start border-top border-end ' : 'text-muted'}`}
                        aria-current="page"
                        onClick={() => setFeedType('video')}
                    >
                        Video
                    </span>
                  </li>

                  <li className="nav-item contentType">
                      <span
                          className={`nav-link ${feedType === 'embed' ? 'active fw-bold border-0 border-start border-top border-end ' : 'text-muted'}`}
                          aria-current="page"
                          onClick={() => setFeedType('embed')}
                      >
                          Embeds
                      </span>
                  </li>
                  </>
                  )}
                  {profile && profile.Username && (
                    <li className="nav-item contentType">
                      <span
                          className={`nav-link ${feedType === 'stats' ? 'active fw-bold border-0 border-start border-top border-end ' : 'text-muted'}`}
                          aria-current="page"
                          onClick={() => setFeedType('stats')}
                      >
                          Stats
                      </span>
                  </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className={`mt-2 container ${showSearch && (feedType !== 'home' && feedType !== 'stats') ? `` : `collapse`}`}>
            <div className='row'>
              <div className={`input-group p-0 rounded-3 mx-md-3 mx-xl-5 px-md-3 px-xl-5`}>
                <input type="text" className="form-control bg-background border-secondary" id="contentFormValue" name="search" aria-label="Search value" placeholder={`${ profile ? `Search ${profile.Username}'s content` : `What are you looking for?` }`} value={formData.search} onChange={handleChange}/>
                {!profile && (
                  <span className={`btn ${showOptions ? `btn-outline-info` : `btn-outline-secondary text-muted`}`} name="toggles" data-bs-toggle="collapse" data-bs-target="#searchOptions">
                    <i className={`bi align-self-center bi-toggles`} onClick={() => setShowOptions(!showOptions)}></i>
                    <span className='ps-2 d-none d-md-inline'>Filters</span>
                  </span>
                )}
                <button className="btn btn-outline-secondary fw-semibold" name="submit" type="submit"><i className="bi bi-search-heart-fill "></i> Search</button>
              </div>
            </div>
            <div id="searchOptions" className="row mt-3 collapse overflow-none" style={{ maxHeight: "60vh"}}>
              <div className='row px-3 my-3'>
                  <ul className="nav nav-underline justify-content-between">
                    <li className="nav-item">
                      <a className="nav-link fw-bold text-body disabled" href="#"><i className="bi bi-toggles me-2"></i>Options</a>
                    </li>
                    <li className="nav-item">
                    <a className={`nav-link ${ searchTab === 'filters' ? `active` : `text-muted`}`} href="#" onClick={() => setSearchTab('filters')}>Filters</a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${ searchTab === 'customFeeds' ? `active` : `text-muted`}`} href="#" onClick={() => setSearchTab('customFeeds')}>Custom Feeds</a>
                    </li>
                  </ul>
              </div>
              <div id="searchFilters" className={`mt-3 ${searchTab === 'filters' ? `show` : `collapse`}`} style={{}}>
                <div className="row d-flex align-items-stretch">
                  <div className={`col-12 col-md-6 align-items-stretch`}>
                    <div className=' d-flex flex-column h-100'> 
                  

                      <div className={`accordion`} id="searchFilterAccordianLeft">
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#searchFilterAuthor" aria-controls="#searchFilterAuthor">
                              <i className="bi bi-person-circle pe-3"></i> Author
                            </button>
                          </h2>
                          
                          <div id="searchFilterAuthor" className="accordian-body accordion-collapse collapse p-3">
                          {formData && formData.posterPublicKey && formData.posterPublicKey.length > 0 && (
                            <div className="d-flex align-content-start flex-wrap small">
                              {formData.posterPublicKey.map((publicKey) => (
                                <div
                                  className="d-flex flex-shrink-1 bg-secondary bg-gradient-10 rounded-pill m-1 px-1"
                                  style={{ padding: "0.05em" }}
                                  key={publicKey}  // Use publicKey as the key
                                >
                                  <div className="d-flex flex-shrink-1 align-items-center text-truncate">
                                    <Avatar publicKey={publicKey} type="avatar" />
                                    <Avatar publicKey={publicKey} type="username" />
                                  </div>
                                  <button
                                    className="btn flex-shrink-1 align-items-center p-0"
                                    name="deletePoster"
                                    value={publicKey}
                                    onClick={() => removeValueFromArrayKey('posterPublicKey', publicKey)}
                                  >
                                    <i className="bi bi-x-circle-fill"></i>
                                  </button>
                                </div>
                              ))}
                            </div>
                          )}

                            <div className="row ps-2 mb-3 align-items-center">
                              <div className='col-1 text-center small text-muted'><i className='bi bi-info-circle-fill'></i></div>
                              <div className='col-11 small text-muted'>Results from a specific username or public key</div>
                            </div>
                            <div className="input-group border-0 mb-2">
                              <span className="input-group-text rounded-0 rounded-start col-5"><i className="bi bi-person-fill pe-3"></i><label htmlFor="poster">User</label></span>
                              <div className='col-7 user-input form-control'><UserInput idName='posterPublicKey' searchTerm={userInput} setSearchTerm={setUserInput} onSelect={handleSelect} name="poster" classes=""  handleChange={handleChange} includeSubmit={false} placeholder="enter a username" /></div>
                            </div>
                            <div className="input-group">
                              <span className="input-group-text col-5"><i className="bi bi-key-fill pe-3"></i><label htmlFor="publicKey">Public key</label></span>
                              <input type="text" className="col-7 form-control" id="publicKey" name="publicKey" placeholder="enter a public key" value={formData.publicKey} onChange={handleChange} />
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#searchFilterDate" aria-controls="#searchFilterDate">
                              <i className="bi bi-calendar3 pe-3"></i> Date
                            </button>
                          </h2>
                          
                          <div id="searchFilterDate" className="accordian-body accordion-collapse collapse p-3">
                            <div className="row ps-2 mb-3 align-items-center">
                              <div className='col-1 text-center small text-muted'><i className='bi bi-info-circle-fill'></i></div>
                              <div className='col-11 small text-muted'>Search after, before or between specific dates</div>
                            </div>
                            <div className="input-group mb-2">
                              <span className="input-group-text col-5"><i className="bi bi-calendar3 me-2"></i>After</span>
                              <input type="date" className="form-control datepicker" id="startDate" name="startDate" aria-label="Date from" value={formData.startDate} onChange={handleChange} />
                              <button className="btn btn-outline-secondary" type="button" onClick={() => clearDate('startDate')}><i className="bi bi-x"></i></button>
                            </div>
                            <div className="input-group">
                              <span className="input-group-text col-5"><i className="bi bi-calendar3 me-2"></i> Before</span>
                              <input type="date" className="form-control datepicker" id="endDate" name="endDate" aria-label="Date to" value={formData.endDate} onChange={handleChange} />
                              <button className="btn btn-outline-secondary" type="button" onClick={() => clearDate('endDate')}><i className="bi bi-x"></i></button>
                            </div>
                          </div>
                        </div>
                        
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#searchFilterPostType" aria-controls="#searchFilterPostType">
                              <i className="bi bi-card-heading pe-3"></i> Post Properties
                            </button>
                          </h2>
                          <div id="searchFilterPostType" className="accordian-body accordion-collapse collapse">
                            <div className="accordion-body">
                              <div className="row ps-2 mb-3 align-items-center">
                                <div className='col-1 text-center small text-muted'><i className='bi bi-info-circle-fill'></i></div>
                                <div className='col-11 small text-muted'>Exclude, include or only return matching conditions</div>
                              </div>

                              <div className='row mb-2 align-items-center'>
                                <div className='col-4 fw-bold'>
                                  <i className='bi bi-pin-fill me-2'></i>
                                  <label className="form-check-label" htmlFor="pinnedSwitch">Pinned</label>
                                </div>
                                <div className='col-8'>
                                  <ul className="nav nav-underline nav-fill">
                                    <li className="nav-item">
                                      <a 
                                        className={`nav-link ${formData.isPinned === false ? `active text-danger` : `text-muted`}`}
                                        name="isPinned"
                                        data-type="toggle"
                                        value={false}
                                        onClick={() => handleChange({ target: { name: "isPinned", value: false }})}
                                        >exclude</a>
                                    </li>
                                    <li className="nav-item">
                                    <a 
                                        className={`nav-link ${formData.isPinned === null ? `active` : `text-muted`}`}
                                        name="isPinned"
                                        data-type="toggle"
                                        value={null}
                                        onClick={() => handleChange({ target: { name: "isPinned", value: null }})}
                                        >include</a>
                                    </li>
                                    <li className="nav-item">
                                      <a 
                                        className={`nav-link ${formData.isPinned === true ? `active text-success` : `text-muted`}`}
                                        name="isPinned"
                                        data-type="toggle"
                                        value={true}
                                        onClick={() => handleChange({ target: { name: "isPinned", value: true }})}
                                        >only</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>                          

                              <div className='row mb-2 align-items-center'>
                                <div className='col-4 fw-bold'>
                                  <i className='bi bi-snow me-2'></i>
                                  <label className="form-check-label" htmlFor="frozenSwitch">Frozen</label>
                                </div>

                                <div className='col-8'>
                                  <ul className="nav nav-underline nav-fill">
                                    <li className="nav-item">
                                      <a 
                                        className={`nav-link ${formData.isFrozen === false ? `active text-danger` : `text-muted`}`}
                                        name="isFrozen"
                                        data-type="toggle"
                                        value={false}
                                        onClick={() => handleChange({ target: { name: "isFrozen", value: false }})}
                                        >exclude</a>
                                    </li>
                                    <li className="nav-item">
                                    <a 
                                        className={`nav-link ${formData.isFrozen === null ? `active` : `text-muted`}`}
                                        name="isFrozen"
                                        data-type="toggle"
                                        value={null}
                                        onClick={() => handleChange({ target: { name: "isFrozen", value: null }})}
                                        >include</a>
                                    </li>
                                    <li className="nav-item">
                                    <a 
                                        className={`nav-link ${formData.isFrozen === true ? `active text-success` : `text-muted`}`}
                                        name="isFrozen"
                                        data-type="toggle"
                                        value={true}
                                        onClick={() => handleChange({ target: { name: "isFrozen", value: true }})}
                                        >only</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div className='row mb-2 align-items-center'>
                                <div className='col-4 fw-bold'>
                                  <i className='bi bi-chat-left-text-fill me-2'></i>
                                  <label className="form-check-label" htmlFor="repliesSwitch">Replies</label>
                                </div>

                                <div className='col-8'>
                                  <ul className="nav nav-underline nav-fill">
                                    <li className="nav-item">
                                      <a 
                                        className={`nav-link ${formData.parentPostHash === false ? `active text-danger` : `text-muted`}`}
                                        name="parentPostHash"
                                        data-type="toggle"
                                        value={false}
                                        onClick={() => handleChange({ target: { name: "parentPostHash", value: false }})}
                                        >exclude</a>
                                    </li>
                                    <li className="nav-item">
                                    <a 
                                        className={`nav-link ${formData.parentPostHash === null ? `active` : `text-muted`}`}
                                        name="parentPostHash"
                                        data-type="toggle"
                                        value={null}
                                        onClick={() => handleChange({ target: { name: "parentPostHash", value: null }})}
                                        >include</a>
                                    </li>
                                    <li className="nav-item">
                                    <a 
                                        className={`nav-link ${formData.parentPostHash === true ? `active text-success` : `text-muted`}`}
                                        name="parentPostHash"
                                        data-type="toggle"
                                        value={true}
                                        onClick={() => handleChange({ target: { name: "parentPostHash", value: true }})}
                                        >only</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>


                              <div className='row mb-2 align-items-center'>
                                <div className='col-4 fw-bold'>
                                  <i className='bi bi-quote me-2'></i>
                                  <label className="form-check-label" htmlFor="quoteRepostsSwitch">Quotes</label>
                                </div>

                                <div className='col-8'>
                                  <ul className="nav nav-underline nav-fill">
                                    <li className="nav-item">
                                      <a 
                                        className={`nav-link ${formData.isQuotedRepost === false ? `active text-danger` : `text-muted`}`}
                                        name="isQuotedRepost"
                                        data-type="toggle"
                                        value={false}
                                        onClick={() => handleChange({ target: { name: "isQuotedRepost", value: false }})}
                                        >exclude</a>
                                    </li>
                                    <li className="nav-item">
                                    <a 
                                        className={`nav-link ${formData.isQuotedRepost === null ? `active` : `text-muted`}`}
                                        name="isQuotedRepost"
                                        data-type="toggle"
                                        value={null}
                                        onClick={() => handleChange({ target: { name: "isQuotedRepost", value: null }})}
                                        >include</a>
                                    </li>
                                    <li className="nav-item">
                                    <a 
                                        className={`nav-link ${formData.isQuotedRepost === true ? `active text-success` : `text-muted`}`}
                                        name="isQuotedRepost"
                                        data-type="toggle"
                                        value={true}
                                        onClick={() => handleChange({ target: { name: "isQuotedRepost", value: true }})}
                                        >only</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>


                              <div className='row mb-2 align-items-center'>
                                <div className='col-4 fw-bold'>
                                  <i className='bi bi-image-fill me-2'></i>
                                  <label className="form-check-label" htmlFor="nftsSwitch">NFT</label>
                                </div>

                                <div className='col-8'>
                                  <ul className="nav nav-underline nav-fill">
                                    <li className="nav-item">
                                      <a 
                                        className={`nav-link ${formData.isNft === false ? `active text-danger` : `text-muted`}`}
                                        name="isNft"
                                        data-type="toggle"
                                        value={false}
                                        onClick={() => handleChange({ target: { name: "isNft", value: false }})}
                                        >exclude</a>
                                    </li>
                                    <li className="nav-item">
                                    <a 
                                        className={`nav-link ${formData.isNft === null ? `active` : `text-muted`}`}
                                        name="isNft"
                                        data-type="toggle"
                                        value={null}
                                        onClick={() => handleChange({ target: { name: "isNft", value: null }})}
                                        >include</a>
                                    </li>
                                    <li className="nav-item">
                                    <a 
                                        className={`nav-link ${formData.isNft === true ? `active text-success` : `text-muted`}`}
                                        name="isNft"
                                        data-type="toggle"
                                        value={true}
                                        onClick={() => handleChange({ target: { name: "isNft", value: true }})}
                                        >only</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>


                              <div className='row align-items-center'>
                                <div className='col-4 fw-bold'>
                                  <i className='bi bi-eye-slash-fill me-2'></i>
                                  <label className="form-check-label" htmlFor="hiddenPostsSwitch">Hidden</label>
                                </div>
                                <div className='col-8'>
                                  <ul className="nav nav-underline nav-fill">
                                    <li className="nav-item">
                                      <a 
                                        className={`nav-link ${formData.isHidden === false ? `active text-danger` : `text-muted`}`}
                                        name="isHidden"
                                        data-type="toggle"
                                        value={false}
                                        onClick={() => handleChange({ target: { name: "isHidden", value: false }})}
                                        >exclude</a>
                                    </li>
                                    <li className="nav-item">
                                    <a 
                                        className={`nav-link ${formData.isHidden === null ? `active` : `text-muted`}`}
                                        name="isHidden"
                                        data-type="toggle"
                                        value={null}
                                        onClick={() => handleChange({ target: { name: "isHidden", value: null }})}
                                        >include</a>
                                    </li>
                                    <li className="nav-item">
                                    <a 
                                        className={`nav-link ${formData.isHidden === true ? `active text-success` : `text-muted`}`}
                                        name="isHidden"
                                        data-type="toggle"
                                        value={true}
                                        onClick={() => handleChange({ target: { name: "isHidden", value: true }})}
                                        >only</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#searchFilterMentions" aria-controls="#searchFilterMentions">
                              <i className="bi bi-people-fill pe-3"></i> Affected Users
                            </button>
                          </h2>
                          
                          <div id="searchFilterMentions" className="accordian-body accordion-collapse collapse p-3">
                            <div className="row ps-2 mb-3 align-items-center">
                              <div className='col-1 text-center small text-muted'><i className='bi bi-info-circle-fill'></i></div>
                              <div className='col-11 small text-muted'>Add users that must be included in results</div>
                            </div>
                            <div className="input-group mb-2">
                              <span className="input-group-text rounded-0 rounded-start col-5"><i className="bi bi-reply pe-3"></i><label htmlFor="poster">Replies to</label></span>
                              <div className='col-7 user-input form-control'><UserInput idName="searchRepliesTo" classes="" onSelect={handleChange} includeSubmit={false} name="parentPoster" placeholder="enter a username" /></div>
                            </div>
                            <div className="input-group mb-2">
                              <span className="input-group-text rounded-0 rounded-start col-5"><i className="bi bi-at pe-3"></i><label htmlFor="poster">Tagging</label></span>
                              <div className='col-7 user-input form-control'><UserInput idName="searchTagging" searchTerm={inputMention} setSearchTerm={setInputMention} classes="" onSelect={handleChange} includeSubmit={false} name="mentions" placeholder="enter a username" /></div>
                            </div>
                            {formData.mentions && formData.mentions.length > 0 && (
                              <div className="row align-items-center">
                                {formData.mentions.map((mentionObj, index) => {
                                  const mention = Object.keys(mentionObj)[0]; // Get the mention value
                                  const avatar = mentionObj[mention]; // Get the avatar

                                  return (
                                    <div key={index} className="col-6 d-flex align-items-center my-1 p-0">
                                      <div className='flex-grow-1 d-flex bg-body-secondary rounded-pill mx-2'>
                                        <div className='flex-fill text-truncate'>
                                          <img src={avatar} alt={`${mention}'s Avatar`} className="me-2" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                                          <span>@{mention}</span>
                                        </div>
                                        <button className='btn' name="deleteMention" value={mention} onClick={() => handleChange({ target: { name: "deleteMention", value: mention }})}><i className="bi bi-x-circle-fill ms-auto"></i></button>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#searchFilterAttachments" aria-controls="#searchFilterAttachments">
                              <i className="bi bi-images pe-3"></i> NFT Search
                            </button>
                          </h2>
                          <div id="searchFilterAttachments" className="accordian-body accordion-collapse collapse">
                            <div className="accordion-body">
                              <div className="row ps-2 mb-3 align-items-center">
                                <div className='col-1 text-center small text-muted'><i className='bi bi-info-circle-fill'></i></div>
                                <div className='col-11 small text-muted'>Search for NFTs and specific properties</div>
                              </div>
                              <div className='row mb-3'>
                                <div className='col-4'>
                                  <i className='bi bi-image-fill me-2'></i>
                                  <label className="form-check-label" htmlFor="nftsSwitch">NFTs</label>
                                </div>
                                <div className='col-2'>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="isNft"
                                            id="nftsSwitch"
                                            data-type="toggle"
                                            checked={(formData.isNft === null || formData.isNft === true )}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='col-6 px-0'>
                                    {formData.isNft !== false && (
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="isNft"
                                                id="nftsOnlyCheckbox"
                                                checked={formData.isNft === true}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="nftsOnlyCheckbox">Only NFTs</label>
                                        </div>
                                    )}
                                </div>
                              </div>
                              <div className='row mb-3'>
                                <div className='col-12'>
                                    <div className="form-check form-switch">
                                        <i className='bi bi-lock-fill me-2'></i>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="hasUnlockable"
                                            id="hasUnlockable"
                                            data-type="toggle"
                                            checked={(formData.hasUnlockable === true )}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="hasUnlockable">Only with <strong>unlockable content</strong></label>
                                    </div>
                                </div>
                              </div>
                              <div className='row mb-3'>
                                <div className='col-12'>
                                    <div className="form-check form-switch">
                                        <i className='bi bi-star-fill me-2'></i>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="numNftCopies"
                                            id="numNftCopies"
                                            data-type="toggle"
                                            checked={(formData.numNftCopies === 1 )}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="numNftCopies">Only <strong>exclusives</strong> (1 of 1)</label>
                                    </div>
                                </div>
                              </div>

                              <div className='row'>
                                <div className='col-12'>
                                    <div className="form-check form-switch">
                                        <i className='bi bi-bag-fill me-2'></i>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="filterNumNftCopiesForSale"
                                            id="filterNumNftCopiesForSale"
                                            data-type="toggle"
                                            checked={(formData.filterNumNftCopiesForSale === true )}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="filterNumNftCopiesForSale">Only <strong>available for sale</strong></label>
                                    </div>
                                </div>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 align-items-stretch">
                    <div className='d-flex flex-column h-100'>                 
                      <div className="accordion" id="searchFilterAccordianRight">
                        {/*
                        <div className="accordion-item border-0 border-start border-bottom border-end">
                          <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#searchFilterPostProperties" aria-controls="#searchFilterPostProperties">
                              <i className="bi bi-card-heading pe-3"></i> Post Properties (Test 2)
                            </button>
                          </h2>
                          <div id="searchFilterPostProperties" className="accordian-body accordion-collapse collapse">
                            <div className="accordion-body">
                              <div className="row ps-2 mb-3 align-items-center">
                                <div className='col-1 text-center small text-muted'><i className='bi bi-info-circle-fill'></i></div>
                                <div className='col-11 small text-muted'>Exclude, include or only return results that match these conditions</div>
                              </div>

                              <div className='row mb-3'>
                                <div className='col-4'>
                                  <i className='bi bi-pin-fill me-2'></i>
                                  <label className="form-check-label" htmlFor="pinnedSwitch">Pinned</label>
                                </div>
                                <div className='col-2'>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input toggle"
                                            type="checkbox"
                                            id="pinnedSwitch"
                                            name="isPinned"
                                            data-type="toggle"
                                            checked={ (formData.isPinned === null || formData.isPinned === true ) }
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='col-6 px-0'>
                                    {formData.isPinned != false && (
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="isPinned"
                                                id="pinnedOnlyCheckbox"
                                                checked={formData.isPinned === true}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="pinnedOnlyCheckbox">Only pinned</label>
                                        </div>
                                    )}
                                </div>
                              </div>

                              <div className='row mb-3'>
                                <div className='col-4'>
                                  <i className='bi bi-snow me-2'></i>
                                  <label className="form-check-label" htmlFor="frozenSwitch">Frozen</label>
                                </div>
                                <div className='col-2'>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input toggle"
                                            type="checkbox"
                                            id="frozenSwitch"
                                            name="isFrozen"
                                            data-type="toggle"
                                            checked={ (formData.isFrozen === null || formData.isFrozen === true ) }
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='col-6 px-0'>
                                    {formData.isFrozen != false && (
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="isFrozen"
                                                id="frozenOnlyCheckbox"
                                                checked={formData.isFrozen === true}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="frozenOnlyCheckbox">Only frozen</label>
                                        </div>
                                    )}
                                </div>
                              </div>

                              <div className='row mb-3'>
                                <div className='col-4'>
                                  <i className='bi bi-chat-left-text-fill me-2'></i>
                                  <label className="form-check-label" htmlFor="repliesSwitch">Replies</label>
                                </div>
                                <div className='col-2'>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="parentPostHash"
                                            id="repliesSwitch"
                                            data-type="toggle"
                                            checked={(formData.parentPostHash === null || formData.parentPostHash === true )}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='col-6 px-0'>
                                    {formData.parentPostHash !== false && (
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="parentPostHash"
                                                id="repliesOnlyCheckbox"
                                                checked={formData.parentPostHash === true}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="repliesOnlyCheckbox">Only replies</label>
                                        </div>
                                    )}
                                </div>
                              </div>


                              <div className='row mb-3'>
                                <div className='col-4'>
                                  <i className='bi bi-quote me-2'></i>
                                  <label className="form-check-label" htmlFor="quoteRepostsSwitch">Quotes</label>
                                </div>
                                <div className='col-2'>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="isQuotedRepost"
                                            id="quoteRepostsSwitch"
                                            data-type="toggle"
                                            checked={(formData.isQuotedRepost === null || formData.isQuotedRepost === true )}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='col-6 px-0'>
                                    {formData.isQuotedRepost !== false && (
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="isQuotedRepost"
                                                id="quoteRepostsOnlyCheckbox"
                                                checked={formData.isQuotedRepost === true}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="quoteRepostsOnlyCheckbox">Only quoted</label>
                                        </div>
                                    )}
                                </div>
                              </div>


                              <div className='row mb-3'>
                                <div className='col-4'>
                                  <i className='bi bi-image-fill me-2'></i>
                                  <label className="form-check-label" htmlFor="nftsSwitch">NFTs</label>
                                </div>
                                <div className='col-2'>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="isNft"
                                            id="nftsSwitch"
                                            data-type="toggle"
                                            checked={(formData.isNft === null || formData.isNft === true )}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='col-6 px-0'>
                                    {formData.isNft !== false && (
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="isNft"
                                                id="nftsOnlyCheckbox"
                                                checked={formData.isNft === true}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="nftsOnlyCheckbox">Only NFTs</label>
                                        </div>
                                    )}
                                </div>
                              </div>


                              <div className='row mb-3'>
                                <div className='col-4'>
                                  <i className='bi bi-eye-slash-fill me-2'></i>
                                  <label className="form-check-label" htmlFor="hiddenPostsSwitch">Hidden</label>
                                </div>
                                <div className='col-2'>
                                    <div className="form-check form-switch">
                                      <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="isHidden"
                                          id="hiddenPostsSwitch"
                                          data-type="toggle"
                                          checked={(formData.isHidden === null || formData.isHidden === true ) }
                                          onChange={handleChange}
                                      />
                                    </div>
                                </div>
                                <div className='col-6 px-0'>
                                    {formData.isHidden !== false && (
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="isHidden"
                                                id="hiddenPostsOnlyCheckbox"
                                                checked={formData.isHidden === true}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="hiddenPostsOnlyCheckbox">Only hidden</label>
                                        </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      */}
                      
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#searchFilterPostAttachments" aria-controls="#searchFilterPostAttachments">
                              <i className="bi bi-collection-play-fill pe-3"></i> Media and Attachments
                            </button>
                          </h2>
                          <div id="searchFilterPostAttachments" className="accordian-body accordion-collapse collapse">
                            <div className="accordion-body">
                              <div className="row ps-2 mb-3 align-items-center">
                                <div className='col-1 text-center small text-muted'><i className='bi bi-info-circle-fill'></i></div>
                                <div className='col-11 small text-muted'>Exclude, include or only return results that match these conditions</div>
                              </div>

                              <div className='row mb-2 align-items-center'>
                                <div className='col-4'>
                                    <i className='bi bi-image-fill me-2'></i>
                                    <label className="form-check-label" htmlFor="imageUrlsSwitch">Images</label>
                                </div>
                                <div className='col-8'>
                                    <ul className="nav nav-underline nav-fill">
                                        <li className="nav-item">
                                            <a 
                                                className={`nav-link ${formData.imageUrls === false ? `active text-danger` : `text-muted`}`}
                                                name="imageUrls"
                                                data-type="toggle"
                                                value={false}
                                                onClick={() => handleChange({ target: { name: "imageUrls", value: false }})}
                                            >exclude</a>
                                        </li>
                                        <li className="nav-item">
                                            <a 
                                                className={`nav-link ${formData.imageUrls === null ? `active` : `text-muted`}`}
                                                name="imageUrls"
                                                data-type="toggle"
                                                value={null}
                                                onClick={() => handleChange({ target: { name: "imageUrls", value: null }})}
                                            >include</a>
                                        </li>
                                        <li className="nav-item">
                                            <a 
                                                className={`nav-link ${formData.imageUrls === true ? `active text-success` : `text-muted`}`}
                                                name="imageUrls"
                                                data-type="toggle"
                                                value={true}
                                                onClick={() => handleChange({ target: { name: "imageUrls", value: true }})}
                                            >only</a>
                                        </li>
                                    </ul>
                                </div>
                              </div>

                              <div className='row mb-2 align-items-center'>
                                <div className='col-4'>
                                    <i className='bi bi-camera-video-fill me-2'></i>
                                    <label className="form-check-label" htmlFor="videoUrlsSwitch">Videos</label>
                                </div>
                                <div className='col-8'>
                                    <ul className="nav nav-underline nav-fill">
                                        <li className="nav-item">
                                            <a 
                                                className={`nav-link ${formData.videoUrls === false ? `active text-danger` : `text-muted`}`}
                                                name="videoUrls"
                                                data-type="toggle"
                                                value={false}
                                                onClick={() => handleChange({ target: { name: "videoUrls", value: false }})}
                                            >exclude</a>
                                        </li>
                                        <li className="nav-item">
                                            <a 
                                                className={`nav-link ${formData.videoUrls === null ? `active` : `text-muted`}`}
                                                name="videoUrls"
                                                data-type="toggle"
                                                value={null}
                                                onClick={() => handleChange({ target: { name: "videoUrls", value: null }})}
                                            >include</a>
                                        </li>
                                        <li className="nav-item">
                                            <a 
                                                className={`nav-link ${formData.videoUrls === true ? `active text-success` : `text-muted`}`}
                                                name="videoUrls"
                                                data-type="toggle"
                                                value={true}
                                                onClick={() => handleChange({ target: { name: "videoUrls", value: true }})}
                                            >only</a>
                                        </li>
                                    </ul>
                                </div>
                              </div>

                              <div className='row mb-2 align-items-center'>
                                <div className='col-4'>
                                    <i className='bi bi-play-btn-fill me-2'></i>
                                    <label className="form-check-label" htmlFor="embedVideoUrlSwitch">Embeds</label>
                                </div>
                                <div className='col-8'>
                                    <ul className="nav nav-underline nav-fill">
                                        <li className="nav-item">
                                            <a 
                                                className={`nav-link ${formData.embedVideoUrl === false ? `active text-danger` : `text-muted`}`}
                                                name="embedVideoUrl"
                                                data-type="toggle"
                                                value={false}
                                                onClick={() => handleChange({ target: { name: "embedVideoUrl", value: false }})}
                                            >exclude</a>
                                        </li>
                                        <li className="nav-item">
                                            <a 
                                                className={`nav-link ${formData.embedVideoUrl === null ? `active` : `text-muted`}`}
                                                name="embedVideoUrl"
                                                data-type="toggle"
                                                value={null}
                                                onClick={() => handleChange({ target: { name: "embedVideoUrl", value: null }})}
                                            >include</a>
                                        </li>
                                        <li className="nav-item">
                                            <a 
                                                className={`nav-link ${formData.embedVideoUrl === true ? `active text-success` : `text-muted`}`}
                                                name="embedVideoUrl"
                                                data-type="toggle"
                                                value={true}
                                                onClick={() => handleChange({ target: { name: "embedVideoUrl", value: true }})}
                                            >only</a>
                                        </li>
                                    </ul>
                                </div>
                              </div>


                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#searchFilterEngagement" aria-controls="#searchFilterEngagement">
                              <i className="bi bi-heart-fill pe-3"></i> Engagement
                            </button>
                          </h2>
                          <div id="searchFilterEngagement" className="accordian-body accordion-collapse collapse">
                            <div className="accordion-body">
                              <div className="row ps-2 mb-3 align-items-center">
                                <div className='col-1 text-center small text-muted'><i className='bi bi-info-circle-fill'></i></div>
                                <div className='col-11 small text-muted'>Exclude, include or only return results that match these conditions</div>
                              </div>

                              <div className='row mb-3'>
                                <div className='col-4'>
                                  <i className='bi bi-gem me-2'></i>
                                  <label className="form-check-label" htmlFor="repliesExistSwitch">Has replies</label>
                                </div>
                                <div className='col-2'>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="repliesExist"
                                            id="repliesExistSwitch"
                                            data-type="toggle"
                                            value={formData.repliesExist === null ? true : null }
                                            checked={formData.repliesExist === true }
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                              </div>

                              <div className='row mb-3'>
                                <div className='col-4'>
                                  <i className='bi bi-gem me-2'></i>
                                  <label className="form-check-label" htmlFor="repostedPostExistsSwitch">Has reposts</label>
                                </div>
                                <div className='col-2'>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="repostedPostExists"
                                            id="repostedPostExistsSwitch"
                                            data-type="toggle"
                                            value={formData.repostedPostExists === null ? true : null }
                                            checked={formData.repostedPostExists === true }
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                              </div>

                              <div className='row mb-3'>
                                <div className='col-4'>
                                  <i className='bi bi-gem me-2'></i>
                                  <label className="form-check-label" htmlFor="diamondsExistSwitch">Received</label>
                                </div>
                                <div className='col-2'>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="diamondsExist"
                                            id="diamondsExistSwitch"
                                            data-type="toggle"
                                            value={formData.diamondsExist !== null ? null : 1 }
                                            checked={formData.diamondsExist !== null}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='col-6 px-0 text-center small diamond_level'>
                                  {formData.diamondsExist !== null && (
                                    <>
                                    {[...Array(9)].map((_, index) => (
                                      <span
                                        key={index}
                                        className={`me-2`}
                                        onClick={() => handleChange({ target: { name: 'diamondsExist', value: index + 1 } })}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        <i className={`bi bi-gem diamond-icon ${formData.diamondsExist > index ? `text-info` : `text-muted`}`}></i>
                                      </span>
                                    ))}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#searchFilterLanguage" aria-controls="#searchFilterLanguage">
                              <i className="bi bi-translate pe-3"></i> Language
                            </button>
                          </h2>
                          <div id="searchFilterLanguage" className="accordian-body accordion-collapse collapse p-3">
                            <button type="button" className="btn btn-primary-outline dropdown-toggle text-start" data-bs-toggle="dropdown">
                              <img style={{ width: '25px' }} src="https://desocialworld.com/assets/flags/us.png" /> English
                            </button>
                            <ul className="dropdown-menu background">
                              <li><a className="dropdown-item" href="#">Spanish</a></li>
                            </ul>
                          </div>
                        </div>
                        
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#searchFilterNode" aria-controls="#searchFilterNode">
                                  <i className="bi bi-boxes pe-3"></i>Node
                              </button>
                          </h2>
                          <div id="searchFilterNode" className="accordian-body accordion-collapse collapse p-3">
                          <div className="row ps-2 mb-3 align-items-center">
                            <div className='col-1 text-center small text-muted'><i className='bi bi-info-circle-fill'></i></div>
                            <div className='col-11 small text-muted'>Limit to content posted on specific node(s)</div>
                          </div>
                              {nodes.map(node => (
                                  <div className="form-check text-start" key={node.Node}>
                                      <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id={`node_${node.Node}`}
                                          value={node.Node}
                                          name={`node[${node.Node}]`}
                                          checked={formData?.node?.includes(node.Node.toString())}
                                          onChange={handleChange}
                                      />
                                      <label className="form-check-label" htmlFor={`node_${node.Node}`}>
                                          <img className="deso_avatar me-2" style={{ maxWidth: '1.5em' }} src={`https://node.deso.org/api/v0/get-single-profile-picture/${node.PublicKeyBase58Check}`} alt={node.Name} />
                                          {node.Name}
                                      </label>
                                  </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <CustomFeeds view={view} setView={setView} searchTab={searchTab} feedType={feedType} setFeedType={setFeedType} formData={formData} setFormData={setFormData} currentUser={currentUser} alternateUsers={alternateUsers} accessGroups={accessGroups} preferences={preferences} />
            </div>
          </div>
          <div className='container'>
            <div className='row'>
              <div className={`col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xxl-6 offset-xxl-3 ${(feedType === 'home' || feedType === 'stats') && 'collapse' }`}>
              
                <input type="hidden" name="orderBy" id="searchOrderBy" value={formData.orderBy} onChange={handleChange} />
                <div className='w-100 d-flex flex-row flex-nowrap align-items-baseline px-0'>
                  <div className='text-start fw-light me-3'>View</div>
                  <div>
                    <ul id="searchOrderSelection" className="nav nav-underline">
                      <li className="nav-item">
                          <span
                              className={`nav-link  ${view === 'list' ? 'active fw-bold' : 'text-muted'}`}
                              href="#"
                              onClick={() => setView('list')}
                          >
                              <i className='bi bi-view-list'></i><span className='d-none d-md-inline ms-1'>list</span>
                          </span>
                      </li>
                      {/*
                      <li className="nav-item">
                          <span
                              className={`nav-link  ${view === 'threaded' ? 'active fw-bold' : 'text-muted'}`}
                              href="#"
                              onClick={() => setView('threaded')}
                          >
                              <i className='bi bi-list-nested'></i><span className='d-none d-md-inline ms-1'>threaded</span>
                          </span>
                      </li>
                      */}
                      <li className="nav-item">
                          <span
                              className={`nav-link  ${view === 'media' ? 'active fw-bold' : 'text-muted'}`}
                              href="#"
                              onClick={() => setView('media')}
                          >
                              <i className='bi bi-grid-fill'></i><span className='d-none d-md-inline ms-1'>media</span>
                          </span>
                      </li>
                      <li className="nav-item">
                          <span
                              className={`nav-link  ${view === 'grid' ? 'active fw-bold' : 'text-muted'}`}
                              href="#"
                              onClick={() => setView('grid')}
                          >
                              <i className='bi bi-grid-3x3-gap-fill'></i><span className='d-none d-md-inline ms-1'>grid</span>
                          </span>
                      </li>
                      <li className="nav-item">
                          <span
                              className={`nav-link  ${view === 'slideshow' ? 'active fw-bold' : 'text-muted'}`}
                              href="#"
                              onClick={() => setView('slideshow')}
                          >
                              <i className='bi bi-collection-play-fill'></i><span className='d-none d-md-inline ms-1'>slideshow</span>
                          </span>
                      </li>
                    </ul>
                  </div>
                  <div className='flex-fill text-end fw-light me-3'>Sort</div>
                  <div>
                    <ul id="searchOrderSelection" className="nav nav-underline">
                      <li className="nav-item">
                          <a
                              className={`nav-link  ${formData.orderBy === 'TIMESTAMP_DESC' ? 'active fw-bold' : 'text-muted'}`}
                              aria-current="page"
                              data-target="searchOrderBy"
                              data-value="TIMESTAMP_DESC"
                              onClick={handleChange}
                          >
                              Newest
                          </a>
                      </li>
                      <li className="nav-item">
                          <a
                              className={`nav-link  ${formData.orderBy === 'TIMESTAMP_ASC' ? 'active fw-bold' : 'text-muted'}`}
                              href="#"
                              data-target="searchOrderBy"
                              data-value="TIMESTAMP_ASC"
                              onClick={handleChange}
                          >
                              Oldest
                          </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {additionalOptions}
          </div>
        </form>
      
    </div>
    </>
  );
};

const CustomFeeds = ({
  view,
  setView,
  searchTab,
  feedType,
  setFeedType,
  formData,
  setFormData,
  currentUser,
  alternateUsers,
  accessGroups,
  preferences
}) => {
  const [customiseView, setCustomiseView] = useState(null);
  const handleClose = () => setCustomiseView(null);
  return (
    <div id="searchCustomFeeds" className={`mt-3 ${searchTab === 'customFeeds' ? `show` : `collapse`}`}>
      <div className="row d-flex align-items-stretch">
        <h5>Custom Feeds</h5>
        <p>You can save your search, add it as a feed to your content bar as well as those of other users.</p>
        <p>What would you like to do?</p>
        <ul id="searchOrderSelection" className="px-3 mb-3 nav nav-underline nav-justify justify-content-evenly">
          <li className="nav-item">
            <span
              className={`nav-link ${customiseView === 'save' ? 'active fw-bold' : 'text-muted'}`}
              href="#"
              onClick={() => setCustomiseView('save')}
            >
              <i className='bi bi-floppy-fill'></i><span className='ps-2'>save this feed</span>
            </span>
          </li>
          <li className="nav-item">
            <span
              className={`nav-link ${customiseView === 'browse' ? 'active fw-bold' : 'text-muted'}`}
              href="#"
              onClick={() => setCustomiseView('browse')}
            >
              <i className='bi bi-folder-fill'></i><span className='ps-2'>browse feeds</span>
            </span>
          </li>
        </ul>
      </div>
      <div
        className={`modal fade ${customiseView ? 'show d-block' : ''}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: customiseView ? 'block' : 'none' }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{customiseView === 'save' ? 'Save Feed' : 'Browse Feeds'}</h5>
              <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
            </div>
            <div className="modal-body">
              {customiseView === 'save' ? (
                <SaveFeed
                  view={view}
                  setView={setView}
                  formData={formData}
                  setFormData={setFormData}
                  currentUser={currentUser}
                  alternateUsers={alternateUsers}
                  accessGroups={accessGroups}
                  preferences={preferences}
                />
              ) : (
                <BrowseFeeds
                  feedType={feedType}
                  setFeedType={setFeedType}
                  formData={formData}
                  setFormData={setFormData}
                  currentUser={currentUser}
                  alternateUsers={alternateUsers}
                  accessGroups={accessGroups}
                  preferences={preferences}
                />
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleClose}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomFeeds;


const SaveFeed = ({ view, setView, formData, setFormData, currentUser, alternateUsers, accessGroups, preferences }) => {
  const [isLoading, setLoading] = useState(false);
  const [isSaved, setSaved] = useState(false);
  const { handleError } = useError();

  useEffect(() => {
    console.log('contentForm.jsx > saveFeed > ', formData, currentUser);
    if (isSaved) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        associationId: null
      }));
    }
    setSaved(false); // Mark as unsaved if formData changes
  }, [formData]);

  const setFeedDetails = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const saveCustomFeed = async (e) => {
    e.preventDefault();
    console.log('saveCustomFeed triggered');
    setLoading(true);
    try {
      // Creating customFeed associations
      const jsonEncodedFormData = JSON.stringify(formData);
      const associationPayload = {
        TransactorPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
        TargetUserPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
        AssociationType: 'test1264012',
        AssociationValue: formData.feedName,
        ExtraData: { formData: jsonEncodedFormData }
      };
      console.log('Association Payload:', JSON.stringify(associationPayload, null, 2)); // Debugging payload
      const data = await createUserAssociation(associationPayload);
      console.log("contentForm.jsx > SaveFeed > createUserAssociation response:", data);
  
      // Assume data has an associationID when successful
      setFormData((prevFormData) => ({
        ...prevFormData,
        associationId: data.submittedTransactionResponse.TransactionIDBase58Check
      }));
      setSaved(true);
    } catch (error) {
      handleError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <p>Choose a feed name - as it would appear in the content bar, and a description for your custom feed.</p>
      <div className='row mb-2'>
        <div className='col-5'>
          <input 
            type="text" 
            className='form-control bg-primary py-3 text-center nav-link active' 
            placeholder='Feed name' 
            value={formData.feedName || ''} 
            onChange={(e) => setFeedDetails('feedName', e.target.value)}
            disabled={isSaved}
          />
        </div>
        <div className='col-7'>
          <textarea 
            className="form-control" 
            rows={2} 
            style={{ lineHeight: "1.3" }} 
            placeholder="Description" 
            value={formData.description || ''} 
            onChange={(e) => setFeedDetails('description', e.target.value)}
            disabled={isSaved}
          />
        </div>
      </div>
      
      <div className='mb-3'>
        <h6>Default View</h6>
        <div className='btn-group w-100'>
          {[
            { type: 'list', icon: 'bi-view-stacked' },
            { type: 'threaded', icon: 'bi-list-nested' },
            { type: 'media', icon: 'bi-grid-fill' },
            { type: 'grid', icon: 'bi-grid-3x3-gap-fill' },
          ].map(({ type, icon }) => (
            <div key={type} className={`col-4 mb-2 btn text-center p-2 ${view === type ? `btn-info` : `btn-outline-secondary`}`} onClick={() => setView(type)} >
                <i className={`fs-4 bi ${icon}`}></i>
                <br/><span>{type}</span>
            </div>
          ))}
        </div>
      </div>

      <button 
        className='btn btn-primary w-100 mt-2'
        disabled={isSaved || isLoading}
        onClick={saveCustomFeed}
      >
        {isLoading ? 'Saving...' : 'Save Feed'}
      </button>
      
      {isSaved && (
        <div className='alert alert-success mt-2'>
          Custom Feed Saved
        </div>
      )}
    </>
  );
};

const BrowseFeeds = ({ formData, setFormData, currentUser, alternateUsers, accessGroups, preferences }) => {
  const [feedSource, setFeedSource] = useState('currentUser');
  const [lastSeenAssociationID, setLastSeenAssociationID] = useState(null);
  const [customFeeds, setCustomFeeds] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const { handleError } = useError();
  const variables = {
    "condition": {
      "associationType": 'test1264012',
    },
    "filter": { and: [] },
    "first": 20
  }

  useEffect(() => {
    if(currentUser) {
      setFeedSource('currentUser');
    } else {
      setFeedSource('latestFeeds');
    }

    if(!customFeeds) { 
      getCustomFeeds();
    }
  }, []);

  useEffect(() => {
    setCustomFeeds(null);
    getCustomFeeds();
  }, [feedSource])

  useEffect(() => {
    console.log('contentForm.jsx > customFeeds > customFeed:',customFeeds, currentUser);
  }, [customFeeds]);

  const loadFeed = (feed) => {
    try {
      console.log('contentForm.jsx > BrowseFeeds > loadFeed: ',feed);
      const extraData = JSON.parse(feed.extraData.formData);
      console.log('contentForm.jsx > BrowseFeeds > loadFeed: ',feed,extraData);
      setFormData({
        ...extraData,
        associationId: feed.associationId
      });
    } catch (error) {
      handleError('Failed to load feed data');
      console.error('Error decoding feed.ExtraData:', error);
    }
  };

  const getCustomFeeds = async (value) => {
      setLoading(true);
      variables.filter.and = [];
      switch(feedSource) {
        case "currentUser":
          if(currentUser && currentUser.PublicKeyBase58Check) {
            variables.filter.and.push({ transactor: { 
              publicKey: {
                equalTo: currentUser.PublicKeyBase58Check
              }
            }
            });
          }
          break;
        case "following":
          if(currentUser && currentUser.PublicKeyBase58Check) {
          variables.filter.and.push({transactor: {
              "publicKey": {
                "in": currentUser.PublicKeysBase58CheckFollowedByUser,
              }
            }});
          }
          break;
        case "latestFeeds":
          variables.orderBy = "BLOCK_HEIGHT_DESC";
        case "search":
          variables.filter.and.push({"associationValue": {
              "includesInsensitive": null
            }
          });
      }
      try {
          const data = await deso_graphql({
              query: `query UserAssociations($condition: UserAssociationCondition, $filter: UserAssociationFilter, $first: Int) {
                      userAssociations(condition: $condition, filter: $filter, first: $first) {
                        totalCount
                        pageInfo {
                          hasNextPage
                          startCursor
                          endCursor
                          hasPreviousPage
                        }
                        nodes {
                          associationId
                          associationType
                          associationValue
                          extraData
                          targetUserPkid
                          transactorPkid
                        }
                      }
                    }`,
              variables: variables,
              operationName: "UserAssociations"
          });
          const pageInfo = data.data.userAssociations.pageInfo;
          pageInfo.totalCount = data.data.userAssociations.totalCount;
          console.log("contentForm.jsx > getCustomFeeds > data:", data);
          setCustomFeeds(data.data.userAssociations.nodes);
          setPageData(pageInfo);
          //setLoading(false);
      } catch (error) {
          handleError(error.message);
      } finally {
          setLoading(false);
      }
  };

  return (
    <>
    <ul className="nav nav-underline justify-content-between">
      <li className="nav-item">
        <a className={`nav-link ${ feedSource === 'currentUser' ? `active` : ``}`} href="#" onClick={() => setFeedSource('currentUser')}>Your feeds</a>
      </li>
      <li className="nav-item">
        <a className={`nav-link ${ feedSource === 'latestFeeds' ? `active` : ``}`} href="#" onClick={() => setFeedSource('latestFeeds')}>Newest</a>
      </li>
      <li className="nav-item">
        <a className={`nav-link ${ feedSource === 'following' ? `active` : ``}`} href="#" onClick={() => setFeedSource('following')}>Following</a>
      </li>
    </ul>
    <div className='row'>
      <div className='col-8'>Name</div>
      <div className='col-2 text-center'>Feed</div>
      <div className='col-2 text-center'>Load</div>
    </div>
    {customFeeds && customFeeds.length > 0 && (
      <>
        {customFeeds.map((feed, index) => {
          // Parsing extraData should be done outside the JSX return
          var borderClass = 'border-muted border-bottom';
          if((index+1)>=customFeeds.length) { borderClass = ''; }

          let extraData = {};
          try {
            // Ensure `feed.extraData` is parsed correctly
            extraData = JSON.parse(feed.extraData.formData);
            console.log('Extra Data:',extraData);
          } catch (error) {
            console.error("Failed to parse extraData:", error);
          }

          return (
            <div key={index} className={`row align-items-center py-2 ${borderClass}`}>
              <div className='col-8 lh-1'>
                <span className='fw-bold fs-5 me-2'>{feed.associationValue}</span>
                <span className='small text-muted'>{extraData.description}</span>
                <div className='text-end'><span className='small text-muted'>by <Avatar publicKey={feed.transactorPkid} /></span></div>
              </div>
              <div className='col-2 text-center'>
                <i className={`bi ${feed.associationId === formData.associationId ? 'bi-check-circle-fill text-success' : 'bi-circle'}`}></i>
              </div>
              <div className='col-2 text-center' onClick={() => loadFeed(feed)}>
                <i className={`bi ${feed.associationId === formData.associationId ? 'bi-check-circle-fill text-success' : 'bi-circle'}`}></i>
              </div>
            </div>
          );
        })}
      </>
    )}

    {pageData && pageData.totalCount === 0 && (
      <div className='text-center text-muted my-2'>
        You have not saved any custom feeds
      </div>
    )}
    {isLoading && (
      <div className='text-center py-5'><Loader /></div>
    )}
    </>
  );
};