import { getSingleProfile } from "deso-protocol";
import { deso_graphql } from "../utils/graphql";
import { EventEmitter } from 'eventemitter3';

const profileCache = {};
const REFRESH_INTERVAL = 5 * 60 * 1000; // 5 minutes
const MAX_RETRIES = 3; // Maximum number of retries for failed requests
const profileEmitter = new EventEmitter();
export { profileEmitter };

export const getProfileFromCache = async (publicKey, profile) => {
  const cachedProfile = profileCache[publicKey];
  const now = new Date().getTime();

  if (cachedProfile && (now - cachedProfile.lastChecked < REFRESH_INTERVAL)) {
    console.log("[ProfileCache] Current Cache:",profileCache);
    console.log("[ProfileCache] returning profile:",cachedProfile);
    return cachedProfile;
  } else {
    const fetchedProfile = await fetchProfileWithRetry(publicKey, profile);
    if (fetchedProfile) {
      profileCache[fetchedProfile.PublicKeyBase58Check] = fetchedProfile;
    }
    return fetchedProfile;
  }
};

const fetchProfileWithRetry = async (publicKey, suppliedProfile, retries = 0) => {
  try {
    let profile = suppliedProfile;

    if (!profile) {
      if (publicKey && publicKey.startsWith('BC1YL')) {
        // Profile is not supplied, fetch it using public key
        const request = {
          PublicKeyBase58Check: publicKey,
          NoErrorOnMissing: true
        };
        const data = await getSingleProfile(request);
        profile = data.Profile;
      } else {
        // Profile is not supplied, fetch it using username
        const request = {
          Username: publicKey,
          NoErrorOnMissing: true
        };
        const data = await getSingleProfile(request);
        profile = data.Profile;
      }
    }

    if (profile && profile.PublicKeyBase58Check) {
      // Immediately return the profile without waiting for the latest transaction
      const profileCopy = { ...profile, lastChecked: new Date().getTime() };
      // Fetch latest transaction asynchronously
      //fetchLatestTransactionAndUpdateProfile(profileCopy);
      return profileCopy;
    } else {
      throw new Error("Profile data is invalid");
    }
  } catch (error) {
    if (retries < MAX_RETRIES) {
      console.log(`Retrying fetch profile ${publicKey} (${retries + 1}/${MAX_RETRIES})...`);
      return fetchProfileWithRetry(publicKey, suppliedProfile, retries + 1); // Pass suppliedProfile on retry
    } else {
      console.log('No profile for anonymous key:', publicKey, error);
      return null;
    }
  }
};

// Function to fetch the latest transaction and update the profile asynchronously
const fetchLatestTransactionAndUpdateProfile = async (profile) => {
  try {
    const latestTransaction = await fetchLatestTransactionByPublicKey(profile.PublicKeyBase58Check);
    profile.lastTransaction = latestTransaction;
    console.log('profileUpdated', profile);
    profileCache[profile.PublicKeyBase58Check] = profile;

    // Emit an event to notify that the profile has been updated
    profileEmitter.emit('profileUpdated', profile);
  } catch (error) {
    console.error('Error fetching latest transaction:', error);
  }
};

export const addProfilesToCache = (profiles) => {
  Object.keys(profiles).forEach(publicKey => {
    profileCache[publicKey] = profiles[publicKey];
  });
};

export const addProfileToCache = (publicKey, profile) => {
  profileCache[publicKey] = profile;
};

export const onProfileUpdated = (listener) => {
  profileEmitter.on('profileUpdated', listener);
};

export async function fetchLatestTransactionByPublicKey(publicKey) {
  try {
    const request = {
      query: `
        query latestTransaction($first: Int, $filter: TransactionFilter, $orderBy: [TransactionsOrderBy!]) {
          transactions(first: $first, filter: $filter, orderBy: $orderBy) {
            nodes {
              timestamp
            }
          }
        }`,
      variables: {
        first: 1,
        filter: {
          publicKey: {
            equalTo: publicKey,
          }
        },
        orderBy: "TIMESTAMP_DESC"
      },
      operationName: "latestTransaction"
    };
    const response = await deso_graphql(request);
    return response?.data?.transactions?.nodes[0]?.timestamp || null;
  } catch (error) {
    console.error('Error fetching latest transaction:', error);
    return null;
  }
}

/*
export const getProfileFromCache = async (publicKey, profile) => {
  const cachedProfile = profileCache[publicKey];
  const now = new Date().getTime();

  if (cachedProfile && (now - cachedProfile.lastChecked < REFRESH_INTERVAL)) {
    return cachedProfile;
  } else {
    const profile = await fetchProfileWithRetry(publicKey, profile);
    if (profile) {
      profileCache[profile.PublicKeyBase58Check] = profile;
    }
    return profile;
  }
};
const fetchProfileWithRetry = async (publicKey, profile, retries = 0) => {
  try {
    let profile = null;
    if (publicKey && publicKey.startsWith('BC1YL')) {
      //profile = await getProfileAPI(publicKey, null);
      const request = {
        PublicKeyBase58Check: publicKey,
        //Username: null,
        NoErrorOnMissing: true
      }
      const data = await getSingleProfile(request);
      profile = data.Profile;
    } else {
      //profile = await getProfileAPI(null, publicKey);
      const request = {
        //PublicKeyBase58Check: null,
        Username: publicKey,
        NoErrorOnMissing: true
      }
      const data = await getSingleProfile(request);
      profile = data.Profile;
    }
    
    if (profile && profile.PublicKeyBase58Check) {
      const latestTransaction = await fetchLatestTransactionByPublicKey(profile.PublicKeyBase58Check);
      profile.lastTransaction = latestTransaction;
      profile.lastChecked = new Date().getTime(); // Store as timestamp
      return profile;
    } else {
      throw new Error("Profile data is invalid");
    }
  } catch (error) {
    if (retries < MAX_RETRIES) {
      console.log(`Retrying fetch profile ${publicKey} (${retries + 1}/${MAX_RETRIES})...`);
      return fetchProfileWithRetry(publicKey, retries + 1);
    } else {
      console.log('No profile for anonymous key:', publicKey, error);
      return null;
    }
  } finally {
    //console.log("ProfileCache:", profileCache);
  }
};

const fetchProfileWithRetry = async (publicKey, suppliedProfile, retries = 0) => {
  try {
    let profile = suppliedProfile;

    if (!profile) {
      if (publicKey && publicKey.startsWith('BC1YL')) {
        // Profile is not supplied, fetch it using public key
        const request = {
          PublicKeyBase58Check: publicKey,
          NoErrorOnMissing: true
        };
        const data = await getSingleProfile(request);
        profile = data.Profile;
      } else {
        // Profile is not supplied, fetch it using username
        const request = {
          Username: publicKey,
          NoErrorOnMissing: true
        };
        const data = await getSingleProfile(request);
        profile = data.Profile;
      }
    }

    if (profile && profile.PublicKeyBase58Check) {
      const latestTransaction = await fetchLatestTransactionByPublicKey(profile.PublicKeyBase58Check);
      profile.lastTransaction = latestTransaction;
      profile.lastChecked = new Date().getTime(); // Store as timestamp
      return profile;
    } else {
      throw new Error("Profile data is invalid");
    }
  } catch (error) {
    if (retries < MAX_RETRIES) {
      console.log(`Retrying fetch profile ${publicKey} (${retries + 1}/${MAX_RETRIES})...`);
      return fetchProfileWithRetry(publicKey, suppliedProfile, retries + 1); // Pass suppliedProfile on retry
    } else {
      console.log('No profile for anonymous key:', publicKey, error);
      return null;
    }
  }
};

export const getProfileFromCache = async (publicKey, profile) => {
  const cachedProfile = profileCache[publicKey];
  const now = new Date().getTime();

  if (cachedProfile && (now - cachedProfile.lastChecked < REFRESH_INTERVAL)) {
    return cachedProfile;
  } else {
    const fetchedProfile = await fetchProfileWithRetry(publicKey, profile);
    if (fetchedProfile) {
      profileCache[fetchedProfile.PublicKeyBase58Check] = fetchedProfile;
    }
    return fetchedProfile;
  }
};


export const addProfilesToCache = (profiles) => {
  Object.keys(profiles).forEach(publicKey => {
    profileCache[publicKey] = profiles[publicKey];
  });
};

export const addProfileToCache = (publicKey, profile) => {
  profileCache[publicKey] = profile;
};

export async function fetchLatestTransactionByPublicKey(publicKey) {
  try {
    const request = {
      query: `
        query latestTransaction($first: Int, $filter: TransactionFilter, $orderBy: [TransactionsOrderBy!]) {
          transactions(first: $first, filter: $filter, orderBy: $orderBy) {
            nodes {
              timestamp
            }
          }
        }`,
      variables: {
        first: 1,
        filter: {
          publicKey: {
            equalTo: publicKey,
          }
        },
        orderBy: "TIMESTAMP_DESC"
      },
      operationName: "latestTransaction"
    };
    const response = await deso_graphql(request);
    return response?.data?.transactions?.nodes[0]?.timestamp || null;
  } catch (error) {
    console.error('Error fetching latest transaction:', error);
    return null;
  }
}
  */
