import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Card, Carousel, Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Loader } from "./helpers";
import { ProfileLight, getProfile, getProfileAPI } from './profiles';
import { deso_graphql } from "./graphql";
import { getProfileFromCache, addProfileToCache, onProfileUpdated, profileEmitter } from '../services/profileCache';
import { User } from "../routes/user";

export const LayoutCarouselCards = ({ title, data }) => {
  const [viewport, setViewport] = useState('xs');
  const [profiles, setProfiles] = useState([]);

  //console.log("<LayoutCarouselCards data: ", title, data);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 576) setViewport('xs');
      else if (width < 768) setViewport('sm');
      else if (width < 992) setViewport('md');
      else if (width < 1200) setViewport('lg');
      else setViewport('xl');
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchProfiles = async () => {
      if (data) {
        const profilesData = await Promise.all(
          data.map(async (item) => {
            const profile = await getProfileFromCache(item.PublicKeyBase58Check);
            return { ...profile, lastSeen: item.lastSeen };
          })
        );
      
        //console.log("[layouts.js] <LayoutCarouselCards Type, Profiles:", title, profilesData);
        
        const filteredProfiles = profilesData.filter(
          (profile) =>
            profile &&
            profile.ExtraData &&
            profile.ExtraData.FeaturedImageURL &&
            !(profile.Username && profile.Username.toLowerCase().includes("rss"))
        );
      
        setProfiles(filteredProfiles);
      }
    };

    fetchProfiles();
  }, [data]);

  const getAvatarClass = (lastSeen) => {
    const lastTransactionDate = new Date(lastSeen);
    const currentTimeUTC = new Date(Date.now() + new Date().getTimezoneOffset() * 60000);
    const onlinePeriod = new Date(currentTimeUTC - 20 * 60 * 1000);
    const idlePeriod = new Date(currentTimeUTC - 15 * 24 * 60 * 1000);
    const inactivePeriod = new Date(currentTimeUTC - 45 * 24 * 60 * 1000);

    if (lastTransactionDate > onlinePeriod) {
      return 'online';
    } else if (lastTransactionDate < idlePeriod) {
      return 'muted';
    } else if (lastTransactionDate < inactivePeriod) {
      return 'inactive';
    }
    return '';
  };

  const itemsPerSlide = {
    xs: 2,
    sm: 2,
    md: 3,
    lg: 2,
    xl: 3
  };
  const maxSlides = 10; // Maximum number of slides to display
  const totalSlides = Math.ceil(profiles.length / itemsPerSlide[viewport]); // Total number of possible slides based on profile count
  const slidesToDisplay = Math.min(totalSlides, maxSlides); // Limit the number of slides to maxSlides

  return (
    <div className="col-12 col-lg-6 text-center mb-5">
      <h4>{title}</h4>
      {profiles.length > 0 ? (
        <Carousel
          controls={false}
          indicators={false}
          nextIcon={<span className="carousel-control-next-icon" />}
          prevIcon={<span className="carousel-control-prev-icon" />}
        >
          {Array.from({ length: slidesToDisplay }).map((_, slideIndex) => {
            // Calculate the start index and slice the profiles array for the current slide
            const startIndex = slideIndex * itemsPerSlide[viewport];
            const profilesToDisplay = profiles.slice(startIndex, startIndex + itemsPerSlide[viewport]);

            return (
              <Carousel.Item key={slideIndex}>
                <Row className="justify-content-center">
                  {profilesToDisplay.map((profile) => {
                    const avatarClass = getAvatarClass(profile.lastSeen);
                    return (
                      <Col key={profile.PublicKeyBase58Check} xs={12 / itemsPerSlide[viewport]}>
                        <Link to={`/u/${profile.Username}`} className="text-decoration-none">
                          <Card className="h-100 position-relative">
                            <div className="ratio ratio-2x1">
                              {profile.ExtraData.FeaturedImageURL && (
                                <Card.Img
                                  variant="top"
                                  src={profile.ExtraData.FeaturedImageURL}
                                  alt={`${profile.Username}'s avatar`}
                                  className={`position-absolute top-0 start-0 w-100 h-100`}
                                />
                              )}
                              <div className="bg-body bg-opacity-50 position-absolute top-50 start-0 w-100 h-50">
                                <Card.Body className="position-absolute top-50 start-50 translate-middle mt-3">
                                  <Card.Title className="fs-6 text-truncate">
                                    {profile.Username ? profile.Username : profile.PublicKeyBase58Check}
                                  </Card.Title>
                                </Card.Body>
                              </div>
                            </div>
                            <div className="position-absolute top-50 start-50 translate-middle text-center">
                              <Card.Img
                                src={`https://node.deso.org/api/v0/get-single-profile-picture/${profile.PublicKeyBase58Check}`}
                                alt={`${profile.Username}'s avatar`}
                                className={`bg-body deso_avatar fs-1 text-center position-relative ${avatarClass}`}
                              />
                            </div>
                          </Card>
                        </Link>
                      </Col>
                    );
                  })}
                </Row>
              </Carousel.Item>
            );
          })}
        </Carousel>
      ) : (
        <Row>
          <Col xs={12 / itemsPerSlide[viewport]} sm={12 / itemsPerSlide[viewport]} md={12 / itemsPerSlide[viewport]} lg={12 / itemsPerSlide[viewport]} xl={12 / itemsPerSlide[viewport]}>
            <div className="h-100 position-relative card placeholder-glow">
                <div className="ratio ratio-2x1 placeholder bg-body">
                </div>
                <div className="bg-secondary bg-opacity-10 position-absolute top-50 start-0 w-100 h-50">
                  <Card.Body className="position-absolute top-50 start-50 translate-middle mt-3 w-100 placeholder-glow">
                    <Card.Title className="placeholder fs-6 placeholder-xs placeholder-wave placeholder-glow col-7"></Card.Title>
                  </Card.Body>
                </div>
                <div className="position-absolute top-50 start-50 translate-middle text-center">
                    <div className="placeholder bg-secondary avatar-placeholder placeholder-wave deso_avatar fs-1 text-center position-relative"></div>
                </div>
            </div>
          </Col>
          <Col xs={12 / itemsPerSlide[viewport]} sm={12 / itemsPerSlide[viewport]} md={12 / itemsPerSlide[viewport]} lg={12 / itemsPerSlide[viewport]} xl={12 / itemsPerSlide[viewport]}>
            <div className="h-100 position-relative card placeholder-glow">
                <div className="ratio ratio-2x1 placeholder bg-body">
                </div>
                <div className="bg-secondary bg-opacity-10 position-absolute top-50 start-0 w-100 h-50">
                  <Card.Body className="position-absolute top-50 start-50 translate-middle mt-3 w-100 placeholder-glow">
                    <Card.Title className="placeholder fs-6 placeholder-xs placeholder-wave placeholder-glow col-7"></Card.Title>
                  </Card.Body>
                </div>
                <div className="position-absolute top-50 start-50 translate-middle text-center">
                    <div className="placeholder bg-secondary avatar-placeholder placeholder-wave deso_avatar fs-1 text-center position-relative"></div>
                </div>
            </div>
          </Col>
          <Col xs={12 / itemsPerSlide[viewport]} sm={12 / itemsPerSlide[viewport]} md={12 / itemsPerSlide[viewport]} lg={12 / itemsPerSlide[viewport]} xl={12 / itemsPerSlide[viewport]} className='d-none d-md-block d-lg-none d-xl-block'>
            <div className="h-100 position-relative card placeholder-glow">
                <div className="ratio ratio-2x1 placeholder bg-body">
                </div>
                <div className="bg-secondary bg-opacity-10 position-absolute top-50 start-0 w-100 h-50">
                  <Card.Body className="position-absolute top-50 start-50 translate-middle mt-3 w-100 placeholder-glow">
                    <Card.Title className="placeholder fs-6 placeholder-xs placeholder-wave placeholder-glow col-7"></Card.Title>
                  </Card.Body>
                </div>
                <div className="position-absolute top-50 start-50 translate-middle text-center">
                    <div className="placeholder bg-secondary avatar-placeholder placeholder-wave deso_avatar fs-1 text-center position-relative"></div>
                </div>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

/*
export const LayoutCarouselCards = ({ title, publicKeys }) => {
  const [viewport, setViewport] = useState('xs');
  const [profiles, setProfiles] = useState([]);

  console.log("<LayoutCarouselCards data: ", title, publicKeys);
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 576) setViewport('xs');
      else if (width < 768) setViewport('sm');
      else if (width < 992) setViewport('md');
      else if (width < 1200) setViewport('lg');
      else setViewport('xl');
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchProfiles = async () => {
      //console.log("[LayoutCarouselCards] "+title+" PublicKeys ",publicKeys);
      const profilesData = await Promise.all(publicKeys.map(key => getProfileFromCache(key)));
      const filteredProfiles = profilesData.filter(profile => 
        profile && profile.ExtraData && profile.ExtraData.FeaturedImageURL
      );
      //console.log("[LayoutCarouselCards] "+title+" Filtered ",filteredProfiles);
      setProfiles(filteredProfiles);
    };
    if(publicKeys) { fetchProfiles(); }
  }, [publicKeys]);

  const itemsPerSlide = {
    xs: 2,
    sm: 2,
    md: 3,
    lg: 2,
    xl: 3
  };

  const trimAndFormatDescription = (description) => {
    if (!description) {
      return '';
    }

    const descriptionParts = description.split(/\n\n/);
    let tasterParts = [];
    if (descriptionParts.length > 0) {
      tasterParts = descriptionParts[0].split(/\n/).slice(0, 2);
    }
    const taster = tasterParts.join('\n');
    return taster;
  };

  //console.log("profile",profiles[0]);
  return (
    <div className="col-12 col-lg-6 py-4 text-center">
      <h4>{title}</h4>
      {profiles.length > 0 ? (
        <Carousel
          controls={false}
          indicators={false}
          nextIcon={<span className="carousel-control-next-icon" />}
          prevIcon={<span className="carousel-control-prev-icon" />}
        >
          {profiles.map((profile, index) => (
            index % itemsPerSlide[viewport] === 0 ? (
              <Carousel.Item key={index}>
                <Row className="justify-content-center">
                {profiles
                  .slice(index, index + itemsPerSlide[viewport])
                  .map(profile => {
                    let avatarClass = ''; // Initialize avatarClass
                    if (profile && profile.lastTransaction) {
                      const lastTransactionDate = new Date(profile.lastTransaction);
                      const currentTimeUTC = new Date(Date.now() + new Date().getTimezoneOffset() * 60000);
                      const minutesSinceLastTransaction = Math.floor((currentTimeUTC - lastTransactionDate) / (1000 * 60));
                      const onlinePeriod = new Date(currentTimeUTC - 20 * 60 * 1000);
                      const idlePeriod = new Date(currentTimeUTC - 15 * 24 * 60 * 60 * 1000);
                      const inactivePeriod = new Date(currentTimeUTC - 45 * 24 * 60 * 60 * 1000);

                      if (lastTransactionDate > onlinePeriod) {
                        avatarClass = 'online';
                      } else if (lastTransactionDate < idlePeriod) {
                        avatarClass = 'muted';
                      } else if (lastTransactionDate < inactivePeriod) {
                        avatarClass = 'inactive';
                      }
                    }

                    //console.log("[LayoutCarouselCard] profile: ",profile);
                    return (
                    <Col key={profile.PublicKeyBase58Check} xs={12 / itemsPerSlide[viewport]} sm={12 / itemsPerSlide[viewport]} md={12 / itemsPerSlide[viewport]} lg={12 / itemsPerSlide[viewport]} xl={12 / itemsPerSlide[viewport]}>
                      <Link to={`/u/${profile.Username}`} className="text-decoration-none">
                        <Card className="h-100 position-relative">
                          <div className="ratio ratio-16x9">
                            {profile.ExtraData.FeaturedImageURL && (
                              <Card.Img
                                variant="top"
                                src={profile.ExtraData.FeaturedImageURL}
                                alt={`${profile.Username}'s avatar`}
                                className={`position-absolute top-0 start-0 w-100 h-100`}
                              />
                            )}
                            <div className="position-absolute top-0 start-0 text-start p-1">
                              
                            </div>
                            <div className="bg-body bg-opacity-50 position-absolute top-50 start-0 w-100 h-50">
                              <Card.Body className="position-absolute top-50 start-50 translate-middle mt-3">
                                <Card.Title className="fs-6 text-truncate">{profile && profile.Username ? profile.Username : profile.PublicKeyBase58Check}</Card.Title>
                              </Card.Body>
                            </div>
                          </div>
                          <div className="position-absolute top-50 start-50 translate-middle text-center">
                            {profile.ExtraData.FeaturedImageURL && (
                              <Card.Img
                                src={`https://node.deso.org/api/v0/get-single-profile-picture/${profile.PublicKeyBase58Check}`}
                                alt={`${profile.Username}'s avatar`}
                                className={`bg-body deso_avatar fs-1 text-center position-relative ${avatarClass}`}
                              />
                            )}
                          </div>
                        </Card>
                      </Link>
                    </Col>);
                  })}
                </Row>
              </Carousel.Item>
            ) : null
          ))}
        </Carousel>
      ) : (
        <Row>
          <Col xs={12 / itemsPerSlide[viewport]} sm={12 / itemsPerSlide[viewport]} md={12 / itemsPerSlide[viewport]} lg={12 / itemsPerSlide[viewport]} xl={12 / itemsPerSlide[viewport]}>
            <div className="h-100 position-relative card placeholder-glow">
                <div className="ratio ratio-16x9 placeholder bg-body">
                </div>
                <div className="bg-secondary bg-opacity-10 position-absolute top-50 start-0 w-100 h-50">
                  <Card.Body className="position-absolute top-50 start-50 translate-middle mt-3 w-100 placeholder-glow">
                    <Card.Title className="placeholder fs-6 placeholder-xs placeholder-wave placeholder-glow col-7"></Card.Title>
                  </Card.Body>
                </div>
                <div className="position-absolute top-50 start-50 translate-middle text-center">
                    <div className="placeholder bg-secondary avatar-placeholder placeholder-wave deso_avatar fs-1 text-center position-relative"></div>
                </div>
            </div>
          </Col>
          <Col xs={12 / itemsPerSlide[viewport]} sm={12 / itemsPerSlide[viewport]} md={12 / itemsPerSlide[viewport]} lg={12 / itemsPerSlide[viewport]} xl={12 / itemsPerSlide[viewport]}>
            <div className="h-100 position-relative card placeholder-glow">
                <div className="ratio ratio-16x9 placeholder bg-body">
                </div>
                <div className="bg-secondary bg-opacity-10 position-absolute top-50 start-0 w-100 h-50">
                  <Card.Body className="position-absolute top-50 start-50 translate-middle mt-3 w-100 placeholder-glow">
                    <Card.Title className="placeholder fs-6 placeholder-xs placeholder-wave placeholder-glow col-7"></Card.Title>
                  </Card.Body>
                </div>
                <div className="position-absolute top-50 start-50 translate-middle text-center">
                    <div className="placeholder bg-secondary avatar-placeholder placeholder-wave deso_avatar fs-1 text-center position-relative"></div>
                </div>
            </div>
          </Col>
          <Col xs={12 / itemsPerSlide[viewport]} sm={12 / itemsPerSlide[viewport]} md={12 / itemsPerSlide[viewport]} lg={12 / itemsPerSlide[viewport]} xl={12 / itemsPerSlide[viewport]} className='d-none d-md-block d-lg-none d-xl-block'>
            <div className="h-100 position-relative card placeholder-glow">
                <div className="ratio ratio-16x9 placeholder bg-body">
                </div>
                <div className="bg-secondary bg-opacity-10 position-absolute top-50 start-0 w-100 h-50">
                  <Card.Body className="position-absolute top-50 start-50 translate-middle mt-3 w-100 placeholder-glow">
                    <Card.Title className="placeholder fs-6 placeholder-xs placeholder-wave placeholder-glow col-7"></Card.Title>
                  </Card.Body>
                </div>
                <div className="position-absolute top-50 start-50 translate-middle text-center">
                    <div className="placeholder bg-secondary avatar-placeholder placeholder-wave deso_avatar fs-1 text-center position-relative"></div>
                </div>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};
*/


export const Avatar = ({ type = "username", suppliedProfile, publicKey, size, link = true, marker = null }) => {
  const [profile, setProfile] = useState(null);
  const [avatarClass, setAvatarClass] = useState('');

  useEffect(() => {
    async function fetchProfile() {
      const cachedProfile = await getProfileFromCache(publicKey, suppliedProfile);
      if (cachedProfile) {
        cachedProfile.lastChecked = new Date(Date.now()).toUTCString();
        setProfile(cachedProfile);
      }
    }

    fetchProfile();

    const handleProfileUpdate = (updatedProfile) => {
      if (updatedProfile.PublicKeyBase58Check === publicKey) {
        setProfile(updatedProfile);
      }
    };

    onProfileUpdated(handleProfileUpdate);

    return () => {
      profileEmitter.off('profileUpdated', handleProfileUpdate); // Clean up the listener
    };
  }, [publicKey, suppliedProfile]);

  useEffect(() => {
    if (profile && profile.lastTransaction) {
      const lastTransactionDate = new Date(profile.lastTransaction);
      const currentTimeUTC = new Date(Date.now() + new Date().getTimezoneOffset() * 60000);
      const minutesSinceLastTransaction = Math.floor((currentTimeUTC - lastTransactionDate) / (1000 * 60));
      const onlinePeriod = new Date(currentTimeUTC - 20 * 60 * 1000);
      const idlePeriod = new Date(currentTimeUTC - 15 * 24 * 60 * 60 * 1000);
      const inactivePeriod = new Date(currentTimeUTC - 45 * 24 * 60 * 60 * 1000);

      if (lastTransactionDate > onlinePeriod) {
        setAvatarClass('online');
      } else if (lastTransactionDate < idlePeriod) {
        setAvatarClass('muted');
      } else if (lastTransactionDate < inactivePeriod) {
        setAvatarClass('inactive');
      }
    }
  }, [profile]);

  let profileName;
  if (type === "name" && profile) {
    if (profile.ExtraData && profile.ExtraData.DisplayName && profile.ExtraData.DisplayName !== '') {
      profileName = profile.ExtraData.DisplayName.trim();
    } else {
      profileName = profile.Username;
    }
  }

  let profilePictureUrl;
  if (!profile || profile.isAnon) {
    profilePictureUrl = '/assets/person-circle.svg';
  } else if (profile.ExtraData && profile.ExtraData.NFTProfilePictureUrl) {
    profilePictureUrl = profile.ExtraData.NFTProfilePictureUrl;
  } else if (profile.ExtraData && profile.ExtraData.LargeProfilePicURL) {
    profilePictureUrl = profile.ExtraData.LargeProfilePicURL;
  } else {
    profilePictureUrl = `https://node.deso.org/api/v0/get-single-profile-picture/${profile.PublicKeyBase58Check}`;
  }

  const fallbackImageUrl = '/assets/person-circle.svg';

  let largeClass;
  if(size && size > 3) { largeClass = ' large_avatar'; }

  size = size * 15;

  let output;
  if (profile) {
    output = (
      <>
        {marker ?? ( marker )}
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip style={{ zIndex: "10000"}}>
            <ProfileLight profile={profile} mode="light" />
          </Tooltip>}
        >
          <>
            {type === "avatar" && (
                <img
                  src={profilePictureUrl}
                  alt={`${profile.Username}'s avatar`}
                  className={`me-1 deso_avatar ${largeClass} ${avatarClass}`}
                  style={{ maxWidth: `${size}px !important`, maxHeight: `${size}px !important`, aspectRatio: "1", objectFit: "cover" }}
                  onError={(e) => { e.target.src = fallbackImageUrl; }}
                />
            )}
          </>
        </OverlayTrigger>
        {type === "username" && (
          <UsernameComponent profile={profile} handle={true}/>
        )}
        {type === "fullname" && (
          <UsernameComponent className='text-truncate' profile={profile} handle={false}/>
        )}
        {type === "name" && profileName && (
          <span>{profileName}</span>
        )}
      </>
    );
  } else {
    return (
      <>
        {type === "avatar" && (
          <span className="avatar_holder pe-1"><img src={fallbackImageUrl} className="img-fluid deso_avatar bg-warning"/></span>
        )}
        {type === "username" && (
          <span className="fw-bold pe-1 text-truncate">Anonymous Key</span>
        )}
      </>
    );
  }

  if (link) {
    return (
      <Link to={`/u/${profile.Username}`} className="text-truncate text-body text-decoration-none">
        {output}
      </Link>
    );
  } else {
    return output;
  }
};

/*
export const Avatar = ({ type = "username", suppliedProfile, publicKey, size, link = true }) => {
  const [profile, setProfile] = useState(null);
  const [avatarClass, setAvatarClass] = useState('');

  useEffect(() => {
    async function fetchProfile() {
      const cachedProfile = await getProfileFromCache(publicKey, suppliedProfile);
      if (cachedProfile) {
        cachedProfile.lastChecked = new Date(Date.now()).toUTCString();
        setProfile(cachedProfile);
      }
    }
    fetchProfile();
  }, [publicKey]);
  
  useEffect(() => {
    if (profile && profile.lastTransaction) {
      const lastTransactionDate = new Date(profile.lastTransaction);
      const currentTimeUTC = new Date(Date.now() + new Date().getTimezoneOffset() * 60000);
      const minutesSinceLastTransaction = Math.floor((currentTimeUTC - lastTransactionDate) / (1000 * 60));
      const onlinePeriod = new Date(currentTimeUTC - 20 * 60 * 1000);
      const idlePeriod = new Date(currentTimeUTC - 15 * 24 * 60 * 60 * 1000);
      const inactivePeriod = new Date(currentTimeUTC - 45 * 24 * 60 * 60 * 1000);

      if (lastTransactionDate > onlinePeriod) {
        setAvatarClass('online');
      } else if (lastTransactionDate < idlePeriod) {
        setAvatarClass('muted');
      } else if (lastTransactionDate < inactivePeriod) {
        setAvatarClass('inactive');
      }
    }
  }, [profile]);

  let profileName;
  if (type === "name" && profile) {
    if (profile.ExtraData && profile.ExtraData.DisplayName && profile.ExtraData.DisplayName !== '') {
      profileName = profile.ExtraData.DisplayName.trim();
    } else {
      profileName = profile.Username;
    }
  }

  let profilePictureUrl;
  if (!profile || profile.isAnon) {
    profilePictureUrl = '/assets/person-circle.svg';
  } else if (profile.ExtraData && profile.ExtraData.NFTProfilePictureUrl) {
    profilePictureUrl = profile.ExtraData.NFTProfilePictureUrl;
  } else {
    profilePictureUrl = `https://node.deso.org/api/v0/get-single-profile-picture/${profile.PublicKeyBase58Check}`;
  }

  const fallbackImageUrl = '/assets/person-circle.svg';

  let largeClass;
  if(size && size > 3) { largeClass = ' large_avatar'; }

  let output;
  if (profile) {
    output = (
      <span className="m-0 pe-1">
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip style={{ zIndex: "10000"}}>
            <ProfileLight profile={profile} mode="light" />
          </Tooltip>}
        >
          <span className="avatar_holder">
            {type === "avatar" && (
              <img
                src={profilePictureUrl}
                alt={`${profile.Username}'s avatar`}
                className={`deso_avatar ${largeClass} ${avatarClass}`}
                style={{ maxWidth: `${size}rem !important`, maxHeight: `${size}rem !important`, aspectRatio: "1", objectFit: "cover" }}
                onError={(e) => { e.target.src = fallbackImageUrl; }}
              />
            )}
          </span>
        </OverlayTrigger>
        {type === "username" && (
          <UsernameComponent profile={profile} handle={true}/>
        )}
        {type === "fullname" && (
          <UsernameComponent className='text-truncate' profile={profile} handle={false}/>
        )}
        {type === "name" && profileName && (
          <span>{profileName}</span>
        )}
      </span>
    );
  } else {
    return null;
  }

  if (link) {
    return (
      <Link to={`/u/${profile.Username}`} className="text-truncate text-body text-decoration-none">
        {output}
      </Link>
    );
  } else {
    return output;
  }
};

*/
export const UsernameComponent = ({ profile, handle = true, className = null }) => {
  const containerRef = useRef(null);

  useEffect(() => {
      const updateMaxWidth = () => {
          if (containerRef.current) {
              const viewportWidth = window.innerWidth;
              let maxUsernameWidth = viewportWidth * 0.5; // Adjust as needed
              if(viewportWidth>992) {
                  maxUsernameWidth = viewportWidth * 0.35; // Adjust as needed
              }
              const textTruncateElement = containerRef.current.querySelector('.text-truncate');
              if (textTruncateElement) {
                  textTruncateElement.style.maxWidth = `${maxUsernameWidth}px`;
              }
          }
      };

      updateMaxWidth();

      // Update maxWidth when the window is resized
      window.addEventListener('resize', updateMaxWidth);
      
      // Cleanup event listener
      return () => {
          window.removeEventListener('resize', updateMaxWidth);
      };
  }, [profile]);

  if (!handle && profile.ExtraData && profile.ExtraData.DisplayName && profile.ExtraData.DisplayName !== '') {
      return (
        <>
          <span className="fw-bold pe-1 text-truncate">{profile.ExtraData.DisplayName.trim()}</span>
          <span className="small text-muted">@{profile.Username}</span>
        </>
      );
  } else {
      return (
          <span ref={containerRef} className="fw-bold pe-1 text-truncate">
              @{profile.Username}
          </span>
      );
  }
};